export default {
  props: {
    value: {
      default() {
        return 'N';
      },
    },
    placeholder: String,
    trueLabel: {
      default() {
        return 'Y';
      },
    },
    falseLabel: {
      default() {
        return 'N';
      },
    },
    customStyle: {
      default() {
        return {
          'margin-left': '10px',
        };
      },
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
  render() {
    return <el-checkbox
      style={this.customStyle}
      v-model={this.model}
      true-label={this.trueLabel}
      false-label={this.falseLabel}
    >{this.placeholder}
    </el-checkbox>;
  },
};
