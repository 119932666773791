import { render, staticRenderFns } from "./tablePageListBase.vue?vue&type=template&id=b8d54b80&scoped=true&"
import script from "./tablePageListBase.vue?vue&type=script&lang=js&"
export * from "./tablePageListBase.vue?vue&type=script&lang=js&"
import style0 from "./tablePageListBase.vue?vue&type=style&index=0&id=b8d54b80&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8d54b80",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b8d54b80')) {
      api.createRecord('b8d54b80', component.options)
    } else {
      api.reload('b8d54b80', component.options)
    }
    module.hot.accept("./tablePageListBase.vue?vue&type=template&id=b8d54b80&scoped=true&", function () {
      api.rerender('b8d54b80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/kms_new/components/table_page_list_base/tablePageListBase.vue"
export default component.exports