/**
 * 布局菜单配置
 * */

import request from '../../utils/request';
import Storage from '../../utils/storage';

function getMenusName() {
  const storage = window.localStorage;
  let menuList = JSON.parse(storage.getItem('menuList'));
  if (typeof menuList !== 'object' || menuList === null) {
    menuList = [];
  }
  return menuList;
}

export default {
  namespaced: true,
  state: {
    menusName: getMenusName(),
    openMenus: [],
    selectMenu: '',
    currentMenu: {},
    menus: [],
    tabNameFromMenuArr: [],
  },
  mutations: {
    setTabNameFromMenuArr(state, tabNameFromMenuArr) {
      state.tabNameFromMenuArr = tabNameFromMenuArr;
    },
    getmenusNav(state, menuList) {
      state.menusName = menuList;
    },
    // getopenMenus (state, openList) {
    //   state.openMenus = openList
    // }
    setopenMenus(state, openList) {
      state.openMenus = openList;
    },
    setselectMenu(state, selectMenu) {
      state.selectMenu = selectMenu;
    },
    setCurrentMenu(state, currentMenu) {
      state.currentMenu = currentMenu;
    },
  },
  actions: {
    getMenusNavList({ commit }) {
      return new Promise((resolve, reject) => request
        .post('/mdm/mdmfunction/system_tree')
        .then((res) => {
          const list = res.result || [];
          commit('getmenusNav', list);
          commit('setselectMenu', list[0].functionCode);

          const tabNameArr = [];

          function treeToOne(tree = []) {
            tree.forEach((a) => {
              if (a.functionName && a.functionUrl) {
                const isHave = tabNameArr.find((a2) => a2.functionUrl === a.functionUrl);
                if (!isHave) {
                  tabNameArr.push({
                    functionName: a.functionName,
                    functionUrl: a.functionUrl,
                  });
                }
              }
              if (a.children && a.children.length > 0) {
                treeToOne(a.children);
              }
            });
          }
          treeToOne(list);
          commit('setTabNameFromMenuArr', tabNameArr);
          Storage.l.set('menuArray', JSON.stringify(tabNameArr.map((item) => item.functionUrl.split('?')[0])));
          resolve(list);
        })
        .catch((err) => {
          reject(err);
        }));
    },
  },
};
