import BasePage from '@/found/components/page_base';

export default {
  name: 'form_table',
  extends: BasePage,
  data() {
    return {
      editConfig: { trigger: 'click', mode: 'cell', showStatus: true },
      layout: {
        noTop: true,
        noCell: true,
      },
    };
  },
  props: ['dataSource', 'renderSlot', 'columns', 'hideSelectable', 'showSummary', 'summaryField', 'renderOpSlot'],
  methods: {
    beforeGetList() {
      this.beforeList = (this.listCode || this.listTemplateCode || this.$route.query.functionCode || this.$route.query.function_code) && (this.code || this.propsParentCode || this.parentCode);
      return this.beforeList;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计：';
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => Number.isNaN(value))) {
          if (this.summaryField && this.summaryField.some((field) => field === column.property)) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!Number.isNaN(value)) {
                return prev + curr;
              }
              return prev;
            }, 0);
            sums[index] = `${sums[index]}`;
          }
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
  },
  render() {
    return <el-table
      style={'width:100%'}
      header-row-style={{ color: '#606266', 'font-weight': 700, 'line-height': '24px' }}
      header-cell-style={{ background: '#f8f8f9', height: '36px', padding: '6px 0' }}
      border
      summary-method={this.getSummaries}
      show-summary={this.showSummary}
      on-selection-change={this.handleSelectionChange}
      data={this.tableData}
    >
      {!this.hideSelectable && <el-table-column
        type={'selection'}
        selectable={this.selectable}
        width={this.showSummary ? 65 : 55}
      />}
      {(this.columns || this.configs.columns).map((col) => (col.visible && col.title ? <el-table-column
        label={col.title}
        prop={col.field}
        align={col.align}
        width={col.width}
        scopedSlots={{
          default: (props) => {
            if (this.renderSlot && typeof this.renderSlot === 'function') { return this.renderSlot(props); }
            return props.row[col.field];
          },
        }}
      /> : null))}
      {
        this.renderOpSlot ? <el-table-column
        label={'操作'}
        align={'center'}
        prop={'action'}
        scopedSlots={{
          default: (props) => (typeof this.renderOpSlot === 'function' ? this.renderOpSlot(props) : <el-button onClick={() => this.deleteRow(props.$index)}></el-button>),
        }}
      /> : null
      }
    </el-table>;
  },
};
