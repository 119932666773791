import { validateCode } from '@/found/utils';

export default {
  validateCode: {
    bind(el, binding) {
      console.log(el, binding);
      validateCode(binding.value);
    },
  },
};
