/*
 * @Author: sungang
 * @Date: 2020-11-24 10:16:36
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-25 09:46:12
 * @Description:
 */
export default [
  {
    path: '/kms_center',
    name: 'kms',
    meta: {
      title: 'kms',
    },
    redirect: '/kms_center/sold_to_party',
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'sold_to_party',
        name: 'kms_sold_to_party',
        component: () => import(/* webpackChunkName: "kms_center/sold_to_party" */ '../views/sold_to_party'),
        meta: { title: '售达方' },
      },
      {
        path: 'customer_account',
        name: 'customer_account',
        component: () => import(/* webpackChunkName: "kms_center/customer_account" */ '../views/customer_account'),
        meta: { title: '客户账号管理' },
      },
      {
        path: 'on_product',
        name: 'on_product',
        component: () => import(/* webpackChunkName: "kms_center/on_product" */ '../views/on_product'),
        meta: { title: '上架产品' },
      },
      {
        path: 'grasping_rules',
        name: 'grasping_rules',
        component: () => import(/* webpackChunkName: "kms_center/grasping_rules" */ '../views/grasping_rules'),
        meta: { title: '单据抓取规则' },
      },
    ],
  },
];
