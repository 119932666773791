import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import approve from './modules/approve';
import menus from './modules/menus';
import theme from './modules/theme';

Vue.use(Vuex);

const store = {
  state: {
    // userInfo: null,
    menuList: null,
    buttonApiIsLoading: false,
  },
  mutations: {
    SetMenu(state, data) {
      state.menuList = data;
    },
    SetButtonIsLoading(state, data) {
      state.buttonApiIsLoading = data;
    },
  },
  actions: { },
  modules: {
    app,
    menus,
    approve,
    theme,
  },
};

/**
 * 处理子模块store作为module进行插入
 */
// console.info('------------------Store子模块开始扫描------------------');
const storeSubContext = require.context('../views', true, /store\/index\.js$/);
storeSubContext.keys()
  .forEach((module) => {
    const storeModule = storeSubContext(module);
    // console.info('模块名称:', storeModule.default.namespace);
    // console.info('模块对象:', storeModule.default);

    store.modules = {
      ...store.modules,
      [storeModule.default.namespace]: storeModule.default,
    };
  });
// console.info('------------------Store子模块注入完毕------------------');
export const FoundStore = store;

export default FoundStore;
// 禁止new Vuex,导致vuex重复警告
// export default new Vuex.Store(store);
