<template>
  <vxe-table-colgroup
    v-if="tableColumn.children && tableColumn.children.length"
    :title="tableColumn.title"
    align="center"
  >
    <FormTableColumn
      v-for="(item, index) in tableColumn.children"
      :tableColumn="item"
      :key="index"
    />
  </vxe-table-colgroup>
  <vxe-table-column v-bind="tableColumn" v-else></vxe-table-column>
</template>

<script>
export default {
  name: 'FormTableColumn',
  props: {
    tableColumn: {
      type: Object,
      default: () => ({
        children: [],
        title: '标题',
      }),
    },
  },
  created() {},
};
</script>
