import Vue from 'vue';
import formCreate from '@form-create/element-ui';
import selectCustomer from '../components/select_customer';
import selectAct from '../components/select_act';
import selectBudgetFee from '../components/select_budget_fee';
import CheckInfoModal from '../components/check_info_modal';
import ThemePicker from '../components/layout/theme-picker';

import {
  FormHeader, FormButton, FormTable, FormEdit, FormSelectTree, FormUpload,
  FormViewText, FormSelect, FormCheckbox, FormPassword, FormSelectTreeNew,
} from '../components/form/components';
import UpdateTable from '../components/upload_modal/upload_table.vue';
import processLog from '../components/process_log/process_log.vue';

Vue.component('customProcessLog', processLog);
formCreate.component('customProcessLog', processLog);
formCreate.component('selectCustomer', selectCustomer);
formCreate.component('selectBudgetFee', selectBudgetFee);
formCreate.component('selectAct', selectAct);
formCreate.component('formHeader', FormHeader);
formCreate.component('FormButton', FormButton);
formCreate.component('FormTable', FormTable);
formCreate.component('FormEdit', FormEdit);
formCreate.component('FormSelectTree', FormSelectTree);
formCreate.component('FormUpload', FormUpload);
formCreate.component('FormViewText', FormViewText);
formCreate.component('FormSelect', FormSelect);
formCreate.component('FormUpdateTable', UpdateTable);
formCreate.component('FormCheckbox', FormCheckbox);
formCreate.component('FormPassword', FormPassword);
formCreate.component('FormSelectTreeNew', FormSelectTreeNew);
formCreate.component('CheckInfoModal', CheckInfoModal);
formCreate.component('ThemePicker', ThemePicker);
Vue.use(formCreate, { });
