export default function debounce(func, wait) {
  let timeout;
  // eslint-disable-next-line
  return function (...rest) {
    const context = this;
    const args = rest;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}
