/*
 * @Autor: yzr
 * @Date: 2021-07-19 11:13:01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-26 10:08:56
 * @Description:
 */
export default [
  {
    path: '/mall_web',
    name: 'mall_web',
    meta: {
      title: '积分商城',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: '/mall_web/integral_mall_management',
        name: 'integral_mall_management',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'hierarchical_management',
            name: 'hierarchical_management',
            component: () => import(
              '../views/integral_mall_management/hierarchical_management/index.js'
            ),
            meta: { title: '积分商品层级管理' },
          },
          {
            path: 'integral_goods_management',
            name: 'integral_goods_management',
            component: () => import(
              '../views/integral_mall_management/integral_goods_management/index.js'
            ),
            meta: { title: '积分商品管理' },
          },
          {
            path: 'dealers_integral',
            name: 'dealers_integral',
            component: () => import(
              '../views/integral_mall_management/dealers_integral/index.js'
            ),
            meta: { title: '经销商积分兑换单管理' },
          },
          {
            path: 'terminal_integral',
            name: 'terminal_integral',
            component: () => import(
              '../views/integral_mall_management/terminal_integral/index.js'
            ),
            meta: { title: '终端门店积分兑换单管理' },
          },
          {
            path: 'consumption_integral',
            name: 'consumption_integral',
            component: () => import(
              '../views/integral_mall_management/consumption_integral/index.js'
            ),
            meta: { title: '消费积分兑换单管理' },
          },
          {
            path: 'integral_goods_off_shelves',
            name: 'integral_goods_off_shelves',
            component: () => import(
              '../views/integral_mall_management/integral_goods_off_shelves/index.js'
            ),
            meta: { title: '积分商品上下架管理' },
          },
        ],
        meta: { title: '积分商城管理' },
      },
    ],
  },
];
