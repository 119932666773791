import request from '@/found/utils/request';
import _ from 'xe-utils';
import BasePage from '@/found/components/page_base';

window._ = _;
export default {
  name: 'form_select',
  data() {
    return {
      visible: false,
      dictUrl: '/mdm/mdmdictdata/list',
      data: [],
      query: '',
      loading: false,
      selected: null,
    };
  },
  props: {
    parentCode: String,
    config: Object,
    type: String,
    icon: String,
    value: {
      default() {
        return undefined;
      },
    },
    componentType: String,
    validate: Function,
    afterValidate: Function,
    disabledRow: Function,
    getList: Function,
    checkboxAll: {
      type: Boolean,
      default() {
        return true;
      },
    },
    selection: Array,
    isSignal: Boolean,
    disabled: Boolean,
    valueKey: String,
    tablePage: Object,
    iSclearSelected: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    model: {
      get() {
        // this.selected = this.value;
        return this.value;
      },
      set(v) {
        this.$emit('input:update', v);
        this.$emit('change:update', v);
      },
    },
    modalConfig: {
      get() {
        return {
          title: '批量修改支付方式',
          options: [],
          labelInValue: {
            label: 'dictDesc',
            value: 'dictCode',
          },
          keywords: 'dictDesc',
          control: {
            placeholder: '请选择',
          },
          restfulParams: {
            pageNum: 0,
            pageSize: 100,
          },
          propsParams: {},
          fetchData: null,
          deleteRow: null,
          getSelectionChange: null,
          hideConfirmButton: false,
          hideCancelButton: false,
          ...this.config,
        };
      },
    },
    controlType: {
      get() {
        if (this.modalConfig.restful && this.modalConfig.restfulParams) { // restful
          return 3;
        }
        if (this.modalConfig.dictTypeCpde && !this.modalConfig.restful && !this.modalConfig.restfulParams) { // dictTypeCode
          return 2;
        }
        return 1;
      },
    },
  },
  methods: {
    open() {
      let flag = true;
      if (this.validate && typeof this.validate === 'function') {
        flag = this.validate();
      }
      if (flag) {
        this.visible = true;
        if (this.selection && this.selected && this.selected.length && this.iSclearSelected) {
          this.selected.splice(0);
        }
      }
    },
    async ok() {
      let flag;
      if (this.afterValidate && typeof this.afterValidate === 'function') {
        flag = await this.afterValidate(this.selected);
      } else {
        flag = this.selected;
      }
      if (flag) {
        if (this.value) {
          this.value.forEach((row) => {
            const index = flag.findIndex((item) => row[this.valueKey] === item[this.valueKey]);
            if (index > -1) flag.splice(index, 1);
          });
        }
        const data = flag.map((row) => {
          const { id, ...other } = row;
          return other;
        });
        this.$emit('input', this.value ? [...this.value, ...data] : data);
        this.$emit('change', this.value ? [...this.value, ...data] : data);
        this.close();
      }
    },
    close() {
      this.visible = false;
    },
    request() {
      const self = this;
      let remoteMethod = null;
      if (this.$slots.form) return Promise.resolve();
      if (this.controlType === 3) {
        const params = { ...{ [this.modalConfig.keywords]: this.query } };
        if (self.modalConfig.restfulParams) {
          Object.assign(params, self.modalConfig.restfulParams);
        }
        remoteMethod = request.post(self.modalConfig.restful, params);
      } else if (this.controlType === 2) {
        const params = { ...{ [this.modalConfig.keywords]: this.query, dictTypeCode: self.modalConfig.dictTypeCpde } };
        if (self.modalConfig.restfulParams) {
          Object.assign(params, self.modalConfig.restfulParams);
        }
        remoteMethod = request.post(self.dictUrl, params);
      } else {
        remoteMethod = new Promise((resolve) => resolve(self.modalConfig.options));
      }
      return remoteMethod;
    },
    loadData() {
      try {
        this.request().then((res) => {
          if (!res) return;
          switch (this.controlType) {
            case 2: // 字典
              this.data = res.result || [];
              this.loading = false;
              break;
            case 3: // restful
              this.data = (res && res.result && res.result.data ? res.result.data : (res && res.result && res.result)) || [];
              this.loading = false;
              break;
            default:
              this.data = this.modalConfig.options || [];
              this.loading = false;
          }
        }).catch((e) => {
          console.log(e);
        });
      } catch (e) {
        console.log(e);
      }
    },

    fetch() {
      this.loading = true;
      return _.throttle(() => {
        this.loadData();
      }, 3000)();
    },
    remoteMethod(query) {
      this.query = query;
      this.fetch();
    },
    handleSelectChange(e) {
      this.$emit('input', e, this.data);
      this.$emit('change', e, this.data);
    },
    handleTableChange(e) {
      if (this.isSignal) {
        this.$emit('input', e);
        this.$emit('change', e);
        this.close();
      }
      // this.$emit('input', e);
      // this.$emit('change', e);
    },
    handlePageChange(page) {
      this.$emit('page-change', page);
    },
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v) {
          if (this.getList && typeof this.getList === 'function') {
            this.getList();
          } else {
            this.fetch();
          }
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.selected = v;
      },
    },
  },
  mounted() {
    if (this.componentType === 'select') {
      this.visible = true;
    }
  },
  render() {
    const handleSelectChange = this.handleSelectChange.bind(this);
    const handleTableChange = this.handleTableChange.bind(this);
    const disabledRow = this.disabledRow ? this.disabledRow.bind(this) : () => true;
    const WrapComponent = this.$scopedSlots.wrapComponent;
    const DataTable = this.$slots.dataTable;
    const { labelInValue } = this.modalConfig;
    let Content = null;
    if (this.componentType === 'select') {
      const getLabel = (row) => {
        let label = '';
        if (Array.isArray(labelInValue.label)) {
          for (const key of labelInValue.label) {
            label += `${row[key]} | `;
          }
          label = label.replace(/\s+\|+\s$/, '');
        } else {
          label = row[labelInValue.label];
        }
        return label;
      };

      Content = <el-select v-model={this.selected}
                           placeholder={this.modalConfig.control.placeholder}
                           onFocus={this.fetch}
                           onKeyup={this.fetch}
                           disabled={this.disabled}
                           value-key={this.modalConfig.valueKey}
                           multiple={this.modalConfig.multiple}
                           filterable={this.controlType === 3}
                           remote={this.controlType === 3}
                           loading={this.loading}
                           remote-method={this.remoteMethod}
                           onChange={handleSelectChange}
                           propsParentCode={this.parentCode}
      >
        {this.data.length ? this.data.map((o, key) => <el-option
          disabled={!disabledRow(o)}
          key={key}
          label={getLabel(o)}
          value={this.modalConfig.labelInValue.value ? o[this.modalConfig.labelInValue.value] : o}
        />) : <el-option disabled value={0}>无数据</el-option>}

      </el-select>;
    } else {
      Content = <fragment>
        {WrapComponent ? WrapComponent({ event: this.open }) : <el-link
          disabled={this.disabled}
          type={this.type || 'primary'} icon={this.icon || 'el-icon-plus'}
          onClick={this.open}
        >{this.$slots.default || this.modalConfig.title}</el-link>}
        {DataTable ? <DataTable disabled={this.disabled}/> : null}
        <a-drawer
          title={this.modalConfig.title || this.$slots.default}
          visible={this.visible}
          width={this.modalConfig.width || '800px'}
          destroyOnClose
          footer={null}
          maskClosable={false}
          onClose={this.close}
        >
          <fragment>
            {
              this.$slots.form
              || <el-form label-position={'left'}>
                <el-row>
                  <el-col span={24}>
                    <el-form-item
                      label-width={'0'}
                    >
                      <BasePage
                        selectedRows={this.value}
                        valueKey={this.valueKey}

                        disabledRow={disabledRow}
                        v-model={this.selected}
                        ref={'BasePage'}
                        onChange={handleTableChange}
                        listTemplateCode={this.modalConfig.listTemplateCode}
                        isAllowCheckboxAll={this.checkboxAll}
                        code={this.parentCode}
                        noTop
                        noCell
                        showSubmit
                        paramsProps={this.modalConfig.propsParams}
                        pagination={this.tablePage}
                        useLocalData={this.modalConfig.useLocalData}
                        columns={this.modalConfig.columns}
                        search={this.modalConfig.searchList || []}
                        fetchData={this.modalConfig.fetchData}
                        on-delete-row={this.modalConfig.deleteRow || Function}
                        propsParentCode={this.parentCode}
                        on-page-change={this.handlePageChange}

                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            }
          </fragment>
          <div class={'modal-footer'}>
            <div class={'dialog-footer'}>
              {this.modalConfig.hideConfirmButton ? null : <el-button
                type="primary"

                onClick={this.ok}
                size={'small'}
                icon={'el-icon-check'}
              >提交
              </el-button>}
              {this.modalConfig.hideCancelButton ? null : <el-button
                type={'danger'}
                onClick={this.close}
                size={'small'}
                icon={'el-icon-close'}
              >关闭
              </el-button>}
            </div>
          </div>
        </a-drawer>
      </fragment>;
    }

    return Content;
  },
};
