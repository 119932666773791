/*
 * @Author: chenlei
 * @Date: 2020-11-24 10:16:36
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-24 10:16:49
 * @Description:
 */
export default {
  namespace: 'mdm',
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {},
};
