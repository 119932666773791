var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-table",
    {
      ref: "xTable",
      staticClass: "my-xtable-element",
      attrs: {
        border: "",
        "show-overflow": "",
        "show-footer": "",
        "keep-source": "",
        "highlight-hover-row": "",
        "highlight-current-row": "",
        data: _vm.value,
        "edit-config": { trigger: "click", mode: "row", icon: "el-icon-edit" },
        footerMethod: _vm.footerMethod || null,
        footerColspanMethod: _vm.footerColspanMethod || null,
      },
    },
    [
      _vm._l(_vm.tableColumn, function (item, index) {
        return [
          _c("FormTableColumn", { key: index, attrs: { tableColumn: item } }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }