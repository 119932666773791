<template>
  <div class="process_log">
    <div class="process_log-box">
      <div style="background-color:#ffffff; height: auto; padding-top: 10px;">
        <div class="approval row" v-for="(item,index) in processLogDataArr" :key="index" style="margin-top:0;margin-bottom:10px;">
          <div class="approval-title">
        <span>
            {{item.logDate}}
            <i></i>
        </span>
          </div>
          <div class="approval-box row">
            <div v-for="(item2,index2) in item.optRecordRspVOList" :key="index2">
              <div class="title">
                <div class="time"> {{item2.createTime}}</div>
                <span class="fl">操作人员: <span class="c">{{item2.userName}}</span>

                </span>
                <span class="fr">{{item.logDate}}  {{item2.createTime}}</span>
              </div>
              <div class="ph10 bom">
                <el-row :gutter="10">
                  <el-col v-bind="col">
                    <span class="bn">处理岗位：<span class="c">{{item2.positionName}}</span></span>
                  </el-col>
                  <el-col v-bind="col">
                    <span class="bn">操作动作：<span class="c"><span style="">{{item2.operationStr}}</span></span></span>
                  </el-col>
                  <el-col v-bind="col">
                    <span class="bn">节点名称：<span class="c">{{item2.taskName}}</span></span>
                  </el-col>
                  <el-col v-bind="col">
                    <span class="bn">流程名称：<span class="c">{{item2.processName}}</span></span>
                  </el-col>
                  <el-col :span="24">
                    <span class="bn">操作描述：<span class="c">{{item2.content}}</span></span>
                  </el-col>
                  <el-col :span="24" v-if="item2.fileList && item2.fileList.length">
                    <div style="display: flex">
                      <span style="white-space: nowrap">审批文件：</span>
                      <span class="c" style="flex:1;width:0">
                      <FileList :file-list="item2.fileList | getParams"/>
                    </span>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <!--              <div class="bom row" style="padding-top: 5px">-->
              <!--                &lt;!&ndash;                <div class="row">&ndash;&gt;-->
              <!--                &lt;!&ndash;                  <span class="b">处理岗位: <span class="c">{{item2.positionName
              }}</span></span>&ndash;&gt;-->
              <!--                &lt;!&ndash;                  <span class="b">操作动作: <span class="c"><span style="">{{item2.operationStr
              }}</span></span></span>&ndash;&gt;-->
              <!--                &lt;!&ndash;                  <span class="b">节点名称: <span class="c">{{item2.taskName
              }}</span></span>&ndash;&gt;-->
              <!--                &lt;!&ndash;                  <span class="b">流程名称: <span class="c">{{item2.processName
              }}</span></span>&ndash;&gt;-->
              <!--                &lt;!&ndash;                </div>&ndash;&gt;-->
              <!--                <div class="row">-->
              <!--                  <span class="bn">处理岗位：<span class="c">{{item2.positionName}}</span></span>-->
              <!--                </div>-->
              <!--                <div class="row">-->
              <!--                  <span class="bn">操作动作：<span class="c"><span style="">{{item2.operationStr}}</span></span></span>-->
              <!--                </div>-->
              <!--                <div class="row">-->
              <!--                  <span class="bn">节点名称：<span class="c">{{item2.taskName}}</span></span>-->
              <!--                </div>-->
              <!--                <div class="row">-->
              <!--                  <span class="bn">流程名称：<span class="c">{{item2.processName}}</span></span>-->
              <!--                </div>-->
              <!--                <div class="row">-->
              <!--                  <span class="bn">操作描述：<span class="c">{{item2.content}}</span></span>-->
              <!--                </div>-->
              <!--                <div class="row" v-if="item2.fileList && item2.fileList.length">-->
              <!--                  <div style="display: flex">-->
              <!--                    <span style="white-space: nowrap">审批文件：</span>-->
              <!--                    <span class="c" style="flex:1;width:0">-->
              <!--                      <FileList :file-list="item2.fileList | getParams"/>-->
              <!--                    </span>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="row">-->

              <!--                  &lt;!&ndash;<el-row class="row" v-if="item2.fileList">-->
              <!--                    <el-col span="3">-->
              <!--                      <span>流程文件：</span>-->
              <!--                    </el-col>-->
              <!--                    <el-col span="21">-->
              <!--                      <el-collapse>-->
              <!--                        <el-collapse-item name="1" :disabled="item2.fileList.length < 4">-->
              <!--                          <template v-slot:title>-->
              <!--                            <FileList :file-list="item2.fileList.slice(0,3) | getParams"></FileList>-->
              <!--                          </template>-->
              <!--                          <div>-->
              <!--                            <FileList :file-list="item2.fileList.slice(3,item2.fileList.length) | getParams"></FileList>-->
              <!--                          </div>-->
              <!--                        </el-collapse-item>-->

              <!--                      </el-collapse>-->
              <!--                    </el-col>-->
              <!--                  </el-row>&ndash;&gt;-->
              <!--                </div>-->
              <!--              </div>-->
            </div>

          </div>
        </div>

        <!--<div class="approval row  orange " style="margin-top:0;margin-bottom:10px;">-->
        <!--<div class="approval-title">-->
        <!--<span>-->
        <!--2020-11-04-->
        <!--<i></i>-->
        <!--</span>-->
        <!--</div>-->
        <!--<div class="approval-box row">-->

        <!--<div class="title">-->
        <!--<div class="time"> 11:38</div>-->
        <!--<span class="fl">操作人员: <span class="c">超级管理员</span>-->

        <!--</span>-->
        <!--<span class="fr">2020-11-04 11:38</span>-->
        <!--</div>-->
        <!--<div class="bom row">-->
        <!--<div class="row">-->
        <!--<span class="b">处理岗位: <span class="c">超级管理员1</span></span>-->
        <!--<span class="b">操作动作: <span class="c">指向干预</span></span>-->
        <!--<span class="b">节点名称: <span class="c">被考核人</span></span>-->
        <!--<span class="b">流程名称: <span class="c">月大区负责人过程指标考评表（自评)</span></span>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<span class="bn">操作描述：<span class="c">指向节点【发起人】1</span></span>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="title">-->
        <!--<div class="time"> 11:00</div>-->
        <!--<span class="fl">操作人员: <span class="c">超级管理员</span>-->

        <!--</span>-->
        <!--<span class="fr">2020-11-04 11:00</span>-->
        <!--</div>-->
        <!--<div class="bom row">-->
        <!--<div class="row">-->
        <!--<span class="b">处理岗位: <span class="c">超级管理员1</span></span>-->
        <!--<span class="b">操作动作: <span class="c">提交申请</span></span>-->
        <!--<span class="b">节点名称: <span class="c">发起人</span></span>-->
        <!--<span class="b">流程名称: <span class="c">月大区负责人过程指标考评表（自评)</span></span>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<span class="bn">操作描述：<span class="c">自动完成初始节点</span></span>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="title">-->
        <!--<div class="time"> 11:00</div>-->
        <!--<span class="fl">操作人员: <span class="c">超级管理员</span>-->

        <!--</span>-->
        <!--<span class="fr">2020-11-04 11:00</span>-->
        <!--</div>-->
        <!--<div class="bom row">-->
        <!--<div class="row">-->
        <!--<span class="b">处理岗位: <span class="c">超级管理员1</span></span>-->
        <!--<span class="b">操作动作: <span class="c">发起</span></span>-->
        <!--<span class="b">节点名称: <span class="c">发起流程</span></span>-->
        <!--<span class="b">流程名称: <span class="c">月大区负责人过程指标考评表（自评)</span></span>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<span class="bn">操作描述：<span class="c"></span></span>-->
        <!--</div>-->
        <!--</div>-->

        <!--</div>-->
        <!--</div>-->
      </div>
    </div>

  </div>
</template>

<script>
import request from '@/found/utils/request';
import _ from 'xe-utils';
import FormUpload from '../form/components/form_upload.vue';

const FileList = {
  functional: true,
  render(h, context) {
    return <FormUpload style={{ width: '100%' }}
                       v-model={context.props.fileList}
                       disabled={true}
    ></FormUpload>;
  },
};

export default {
  name: 'process_log',
  props: {
    params: Object,
    processInstanceId: String,
  },
  filters: {
    getParams(list) {
      return list.map((v) => ({
        fileName: v.objectName,
        url: v.fileAddress,
        objectName: v.objectName,
      }));
    },
  },
  data() {
    return {
      processLogDataArr: [],
      col: {
        sm: 24,
        md: 12,
        lg: 8,
      },
    };
  },
  components: {
    FileList,
  },
  created() {
    if (!_.isEmpty(this.params) || this.processInstanceId) {
      const params = this.processInstanceId ? { processInstanceId: this.processInstanceId } : this.params;
      this.getOptRecords(params);
    }
  },
  // watch: {
  //   processInstanceId(newProcessInstanceId) {
  //     if (newProcessInstanceId) {
  //       this.getOptRecords(newProcessInstanceId);
  //     }
  //   },
  // },
  methods: {
    // 查询流程审批记录
    // /activiti/activitiProcessController/getOptRecords
    getOptRecords(params) {
      request.post('/activiti/activitiProcessController/getOptLogs', params).then((res) => {
        if (res.success) {
          this.processLogDataArr = res.result;
          this.processLogDataArr = this.processLogDataArr.map((a) => {
            let a1 = [];
            a1 = a.optRecordRspVOList.map((b) => ({
              ...b,
              createTime: this.formatTime(b.createTime, 'h:m'),
            }));
            return { ...a, optRecordRspVOList: a1 };
          });
        }
      });
    },

    /**
     * 格式化时间
     */
    // 转换时间戳
    formatTime(timestamp, format) {
      const formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
      let returnArr = [];
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      returnArr.push(year, month, day, hour, minute, second);
      returnArr = returnArr.map((a) => {
        let n = a;
        n = n.toString();
        return n[1] ? n : `0${n}`;
      });
      let aaa = format;
      for (const i in returnArr) {
        if (i) {
          aaa = aaa.replace(formatArr[i], returnArr[i]);
        }
      }
      return aaa;
    },

  },
};
</script>

<style scoped lang="less">

.process_log {
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px #52525259;
    background: #b2b2b23b;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }

  * {
    margin: 0;
    padding: 0;
    outline: none;
    list-style: none;
  }

  ul {
    margin-top: 0;
  }

  a {
    text-decoration: none;
    color: #373737;
  }

  a:hover {
    color: #1baff4;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .none {
    display: none;
  }

  .padding0-20 {
    padding: 0 20px;
  }

  /** breadbrumbs **/

  .breadbrumbs {
    height: 35px;
    line-height: 35px;
    background: #f5f5f5 url(https://peixun.chinayanghe.com/eispactiviti/resources/images/main/p-icn_03.jpg) no-repeat 20px 8px;
    background-size: auto 14px;
    margin-bottom: 20px;
    padding: 0 20px 0 45px;
    font-size: 12px;
    color: #666666;
  }

  .breadbrumbs a.blue {
    color: #3F82C7;
  }

  .breadbrumbs .icon {
    display: inline-block;
    padding: 0 5px;
  }

  /**  **/

  .approval {
    padding: 0 100px;
    margin-top: 20px;
    position: relative;
  }

  .approval:before {
    content: "";
    width: 2px;
    height: 100%;
    display: block;
    background: #3796D4;
    position: absolute;
    left: 96px;
    top: 0;
  }

  .approval-title {
    height: 26px;
  }

  .approval-title span {
    height: 26px;
    line-height: 26px;
    display: inline-block;
    color: #ffffff;
    padding: 0 35px 0 15px;
    border-radius: 3px;
    background: url(https://peixun.chinayanghe.com/eispactiviti/resources/images/main/blue.png) no-repeat;
    position: relative;
  }

  .approval-box {
    padding-left: 50px;
    padding-bottom: 10px;
  }

  .approval-box .title {
    height: 28px;
    border: 1px #eeeeee solid;
    background-color: #F8F8F8;
    margin-top: 20px;
    color: #666666;
    font-size: 12px;
    padding: 0 20px;
    line-height: 28px;
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  .approval-box .bom {
    border: 1px #eeeeee solid;
    border-top: 0;
    background-color: #F5F9FC;
    color: #666666;
    font-size: 12px;
    padding: 0 20px;
  }

  .approval-box .bom .b {
    width: 25%;
    display: inline-block;
    // float: left;
    padding: 5px 0;
  }

  .approval-box .bom .bn {
    display: inline-block;
    //padding: 0 0 5px;
  }

  .approval-box .title .c, .approval-box .bom .c {
    color: #151515;
  }

  .approval-box .time {
    width: 60px;
    height: 20px;
    line-height: 20px;
    background-color: #ffffff;
    position: absolute;
    left: -70px;
    top: 0;
  }

  .approval.orange:before {
    background-color: #FFAB39;
  }

  .approval.orange .approval-title span {
    background: url(https://peixun.chinayanghe.com/eispactiviti/resources/images/main/orange.png) no-repeat;
  }

  .approval.green:before {
    background-color: #86B558;
  }

  .approval.green .approval-title span {
    background: url(https://peixun.chinayanghe.com/eispactiviti/resources/images/main/green.png) no-repeat;
  }

  /**  **/

  .process-tab {
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #B9D5F1;
  }

  .process-tab .title {
    width: 100px;
    // float: left;
    display: inline-block;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #3C7FB9;
  }

  .process-tab ul {
    float: left;
  }

  .process-tab li {
    width: 70px;
    height: 40px;
    float: left;
    position: relative;
  }

  .process-tab li a {
    width: 70px;
    height: 38px;
    display: block;
    text-align: center;
    color: #666666;
    position: absolute;
    left: 0;
    top: 0;
  }

  .process-tab li.active a {
    height: 38px;
    border: 1px solid #B9D5F1;
    border-bottom: 1px #ffffff solid;
    border-top: 2px #3C7FB9 solid;
  }

  .process-search {
    width: 110px;
    height: 24px;
    border: 1px solid #3C7FB9;
    padding: 0 5px 0 25px;
    background: url(https://peixun.chinayanghe.com/eispactiviti/resources/images/main/p-icn_07.jpg) no-repeat 5px center;
    background-size: auto 15px;
  }

  .process-tab .txt {
    display: inline-block;
    padding: 0 10px;
    color: #3C7FB9;
  }

  .process-content {
    padding: 20px 0;
  }

  .process-content th, .process-content td {
    font-size: 12px;
    color: #666666;
  }

  .process-content th {
    background-color: #f6f6f6;
    height: 40px;
    font-weight: normal;
    color: #999999;
  }

  .process-content td {
    background-color: #ffffff;
    height: 40px;
    text-align: center;
  }

  .process-content td a.btn {
    display: inline-block;
    line-height: 0;
    margin: 3px 5px;
  }

  .process-content td a.blue {
    color: #3C7FB9;
  }

  .process-content tr.hover td.active {
    background-color: #EDF3F7;
  }

  /deep/ .file-list-detail {
    padding: 3px 0;
  }

  /deep/ .file-list {
    margin: 0 5px;
  }

  /deep/ .file-list {
    width: auto;
    display: inline-flex;
  }

  /deep/ .file-name {
    flex: 1;
    padding: 0 10px;
    white-space: nowrap;
    width: auto
  }
}

</style>
