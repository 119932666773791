/*
 * @Author: yzr
 * @Date: 2020-11-23 15:13:14
 * @LastEditors: yzr
 * @LastEditTime: 2021-01-04 15:15:02
 */
import Vue from 'vue';
// eslint-disable-next-line import/order,import/named
import { httpPlugin } from './utils/request';
import './plugins';

// 第三方插件引入
import highcharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';
// vue-infinite-scroll引入
import infiniteScroll from 'vue-infinite-scroll';
// 样式引入
// import 'reset-css'; // 样式标准格式化
import './styles/index.less';
// 字体图标库
import './assets/font-icon/iconfont.css';
import './styles/setting.less';
import './styles/theme/index.css';

Vue.use(httpPlugin);
Vue.use(infiniteScroll);

highcharts3d(highcharts);
Vue.config.productionTip = false;

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info);
  if (vm.buttons) {
    // eslint-disable-next-line no-param-reassign
    vm.buttons.loading = false;
  }
  // handle error
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 只在 2.2.0+ 可用
};

Vue.config.performance = true;

export default Vue;
