<template>
  <a-drawer
    :title="modalConfig.title"
    :visible="modalConfig.visible"
    :width="modalConfig.width||'80%'"
    :destroyOnClose="true"
    :footer="null"
    :maskClosable="false"
    @close="close"
  >
    <el-scrollbar
      wrap-class="drawer-scrollbar"
      view-class="drawer-container"
      class="dialog-style"
      tag="div"
    >
      <slot></slot>
      <div v-if="modalConfig.showFooter" class="dialog-footer">
        <div>
          <el-button  icon="el-icon-check" type="primary" @click="onOk">确定</el-button>
          <el-button  icon="el-icon-close" type="danger" @click="close">取消</el-button>
        </div>
      </div>
    </el-scrollbar>
  </a-drawer>
</template>

<script>
export default {
  props: {
    modalConfig: {
      type: Object,
      default: () => ({
        title: '标题',
        visible: false,
      }),
    },
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    // modal弹窗确定事件
    onOk() {
      this.$emit('onOk');
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  .ant-drawer-body {
    height: 100%;
    padding:0;
  }
}
/deep/.drawer-container {
  height: 100%;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
}
.dialog-style {
  height: 100%;
}
/deep/.drawer-scrollbar {
  overflow-x: hidden;
  max-height: calc(100vh - 128px);
  height: calc(100vh - 128px);
}
</style>
