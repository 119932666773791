var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "800px" } },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable1",
          attrs: {
            "row-key": "",
            "row-id": "id",
            height: "800px",
            data: _vm.tableData,
          },
        },
        [
          _vm.beforObjIsShow === "Y"
            ? _c("vxe-table-column", {
                attrs: { label: "更新之前的对象", "class-name": "cell_stayle" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("json-viewer", {
                            attrs: {
                              value: row.oldObject || {},
                              "expand-depth": 5,
                              copyable: "",
                              boxed: "",
                              sort: "",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2568133065
                ),
              })
            : _vm._e(),
          _vm.afterObjIsShow === "Y"
            ? _c("vxe-table-column", {
                attrs: { label: "更新之后的对象", "class-name": "cell_stayle" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("json-viewer", {
                            attrs: {
                              value: row.newObject || {},
                              "expand-depth": 5,
                              copyable: "",
                              boxed: "",
                              sort: "",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3433821266
                ),
              })
            : _vm._e(),
          _vm.contrastObjIsShow === "Y"
            ? _c("vxe-table-column", {
                attrs: { label: "对比的结果", "class-name": "cell_stayle" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          _c("json-viewer", {
                            attrs: {
                              value: row.compareResult || {},
                              "expand-depth": 5,
                              copyable: "",
                              boxed: "",
                              sort: "",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1208579733
                ),
              })
            : _vm._e(),
          _c("vxe-table-column", { attrs: { label: "", width: "0" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }