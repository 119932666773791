/*
 * @Author: Boiao Ch
 * @Date: 2020-11-19 17:57:45
 * @Last Modified by: Boiao Ch
 * @Last Modified time: 2020-11-24 00:20:35
 */

import './antd';

// Form Create
import './form-create';

// vxe-table
import './utils'; // 顺序很重要
import './table';

// 自定义组件
import './custom';

// 拖拽
import './vuedraggable';
// element-ui
import './elementui';

/** @desc vue-jsonp */
import './vue-jsonp';

import '../directives';
