import BasePage from '../page_base';

export default {
  name: 'form-select-budget-fee',
  props: ['value', 'code', 'el', 'paramsProps'],
  data() {
    return {
      functionCode: 'tpm_fee_budget',
      rule: [],
      dialogVisible: false,
      dataSource: [],
    };
  },
  components: { BasePage },
  render() {
    const handleOk = this.handleOk.bind(this);
    const handleClose = this.handleClose.bind(this);
    let value = typeof this.value === 'object' && this.value && !!this.value.feeBudgetCode ? this.value.feeBudgetCode : '选择预算';
    if (typeof this.value === 'string') {
      value = this.value;
    }
    return (<div>
      {this.el === 'input' ? <el-input readonly value={value} className={'inline-table'} disabled={this.disabled}
                                       nativeOnClick={() => this.dialogVisible = true}/> : <el-button onClick={() => {
                                         this.dialogVisible = true;
                                       }}>{value}
      </el-button>}
      <el-dialog
        append-to-body
        destroy-on-close
        title="选择预算"
        visible={this.dialogVisible}
        width="60%"
        scopedSlots={{
          default: () => <BasePage ref={'BasePage'} {
            ...{
              props: {
                // setToolsBtnProps: (btn) => ({ ...btn, type: 'hidden' }),
                // setRowBtnProps: (btn) => ({ ...btn, type: 'hidden' }),
                // setSearchFieldProps: (field) => ({ ...field, type: 'hidden' }),
                listTemplateCode: this.functionCode,
                noTop: true,
                noCell: true,
                showSubmit: false,
                checkboxType: 'checkTyperadio',
                paramsProps: this.paramsProps,
              },
            }
          }/>,
        }}
      >
        <div slot={'footer'}>
          <el-button
            {...{
              props: {
                type: 'primary',
                size: 'small',
                icon: 'el-icon-check',
              },
              on: { click: handleOk },
            }}
          >确定
          </el-button>
          <el-button

            {...{
              props: {
                type: 'danger',
                size: 'small',
                icon: 'el-icon-close',
              },
              on: { click: handleClose },
            }}
          >关闭
          </el-button>
        </div>
      </el-dialog>
    </div>);
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    handleOk() {
      if (!this.$refs.BasePage.selectRow[0]) {
        this.$message.warning('请选择费用预算');
        return console.info('请选择费用预算');
      }
      if (this.$refs.BasePage.selectRow[0].feeBudgetCode === this.code) {
        this.$message.warning('调入方与调出方费用预算不能相同');
        return console.info('调入方与调出方费用预算不能相同');
      }
      this.$emit('input', this.$refs.BasePage.selectRow[0]);
      this.$emit('change', this.$refs.BasePage.selectRow[0]);
      this.dialogVisible = false;
      return false;
    },
  },
};
