import request from '@/found/utils/request';
import Init from '../components/form/tools/init';

export default class FormCofing {
  constructor(functionCode, parentCode = undefined) {
    this.functionCode = functionCode;
    this.parentCode = parentCode;
  }

  rule = []

  // 获取表单配置信息
  async getFormRule() {
    const res = await request.post('/mdm/mdmtableconfig/form/query', {
      functionCode: this.functionCode,
      parentCode: this.parentCode,
    }, {
      headers: {
        functionCode: this.functionCode,
      },
    });
    // return res.result;
    return Init.initConfig(res.result, null);
  }
}
