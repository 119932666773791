import { VXETable } from 'vxe-table';

export default function () {
  VXETable.renderer.add('MyLink', {
    // 默认显示模板
    renderDefault(h, renderOpts, params) {
      const { row, column } = params;
      const { events } = renderOpts;
      return [
        h('el-link', 123),
        <a class='my-link' onClick={() => events.click && events.click(params)}>{row[column.property]}</a>,
      ];
    },
  });

  return {
    formConfig: {
      titleWidth: 100,
      titleAlign: 'right',
      item: [
        // {
        //   field: 'name',
        //   span: '6',
        //   title: {
        //     name: '名字',
        //   },
        //   folding: false,
        //   render: {
        //     name: 'ElInput',
        //     attrs: {
        //       placeholder: '请输入名字',
        //     },
        //     props: { },
        //   },
        // },
      ],
    },
    toolBarConfig: [
      // {
      //   type: 'button',
      //   buttonType: 'primary',
      //   icon: 'el-icon-plus',
      //   code: 'add',
      //   name: '新增',
      // },
      // {
      //   type: 'dropdown',
      //   buttonType: null,
      //   icon: 'el-icon-delete',
      //   name: '删除',
      //   code: 'delete',
      //   props: {},
      //   children: [
      //     {
      //       name: '删除',
      //     },
      //   ],
      // },
    ],
    columns: [],
    tableConfig: {
      border: true,
      treeConfig: {
        children: 'children',
      },
      idKey: 'id', // 默认勾选判断字段
      resizable: true, // 所有的列是否允许拖动列宽调整大小
      showOverflow: true, // 当内容过长时显示为省略号
      keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
      highlightHoverRow: true, // 鼠标滑动高亮
      // height: '600',
    },

    rule: [
      // {
      //   type: 'input',
      //   field: 'functionName',
      //   title: '菜单名称',
      //   value: this.formConfig && this.formConfig.functionName,
      //   refresh: true,
      //   props: {
      //     placeholder: '请输入菜单名称',
      //     disabled: false,
      //     loading: true,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   validate: [
      //     {
      //       trigger: 'blur',
      //       required: true,
      //       message: '请输入菜单名称',
      //     },
      //     {
      //       min: 2,
      //       max: 50,
      //       message: '长度在 2 到 50 个字符',
      //       trigger: 'blur',
      //     },
      //   ],
      //   col: {
      //     md: { span: 12 },
      //   },
      // },
      // {
      //   type: 'select',
      //   field: 'functionLevel',
      //   title: '菜单等级',
      //   value: this.formConfig && this.formConfig.functionLevel,
      //   props: {
      //     placeholder: '请选择菜单等级',
      //     disabled: false,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   validate: [
      //     {
      //       trigger: 'change',
      //       required: true,
      //       message: '请选择菜单等级',
      //     },
      //   ],
      //   col: {
      //     md: { span: 12 },
      //   },
      //   on: {
      //     change: this.changeFn,
      //   },
      //   options: [
      //     {
      //       value: '0',
      //       label: '一级菜单',
      //     },
      //     {
      //       value: '1',
      //       label: '下级菜单',
      //     },
      //   ],
      // },
      // {
      //   type: 'select',
      //   field: 'functionModule',
      //   title: '所属模块',
      //   value: this.formConfig && this.formConfig.functionModule,
      //   props: {
      //     placeholder: '请选择所属模块',
      //     disabled: false,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   validate: [
      //     {
      //       trigger: 'change',
      //       required: true,
      //       message: '请选择菜单等级',
      //     },
      //   ],
      //   col: {
      //     md: { span: 12 },
      //   },
      //   options: [
      //     {
      //       value: '1',
      //       label: 'mdm',
      //     },
      //     {
      //       value: '2',
      //       label: 'dms',
      //     },
      //     {
      //       value: '3',
      //       label: 'tpm',
      //     },
      //     {
      //       value: '4',
      //       label: 'sfa',
      //     },
      //   ],
      // },
      // {
      //   type: 'FormSelectTree',
      //   field: 'parentCode',
      //   title: '上级菜单',
      //   value: this.formConfig && this.formConfig.parentCode,
      //   props: {
      //     options: [],
      //     size: 'small',
      //     placeholder: '请选择上级菜单',
      //     disabled: false,
      //     loading: false,
      //     clearable: true, // 是否显示清空按钮
      //     props: {
      //       parent: 'parentCode', // 父级唯一标识
      //       value: 'functionCode', // 唯一标识
      //       label: 'functionName', // 标签显示
      //       children: 'children', // 子级
      //     },
      //   },
      //   validate: [
      //     {
      //       trigger: 'change',
      //       required: true,
      //       message: '请选择上级菜单',
      //     },
      //   ],
      //   col: {
      //     md: { span: 12 },
      //   },
      //   restful: '/mdm/mdmfunction/system_tree',
      //   restfulParams: {},
      // },
      // {
      //   type: 'input',
      //   field: 'functionUrl',
      //   title: '菜单地址',
      //   value: this.formConfig && this.formConfig.functionUrl,
      //   refresh: true,
      //   props: {
      //     placeholder: '请输入菜单地址',
      //     disabled: false,
      //     loading: true,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   col: {
      //     md: { span: 12 },
      //   },
      // },
      // {
      //   type: 'input',
      //   field: 'functionIcon',
      //   title: '菜单图标',
      //   value: this.formConfig && this.formConfig.functionIcon,
      //   refresh: true,
      //   props: {
      //     placeholder: '请输入菜单图标',
      //     disabled: false,
      //     loading: true,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   validate: [],
      //   col: {
      //     md: { span: 12 },
      //   },
      // },
      // {
      //   type: 'select',
      //   field: 'functionConfig',
      //   title: '菜单类型',
      //   value: this.formConfig && this.formConfig.functionConfig,
      //   props: {
      //     placeholder: '请选择菜单类型',
      //     disabled: false,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   validate: [
      //     {
      //       trigger: 'change',
      //       required: true,
      //       message: '请选择菜单类型',
      //     },
      //   ],
      //   col: {
      //     md: { span: 12 },
      //   },
      //   options: [
      //     {
      //       value: '0',
      //       label: '系统菜单',
      //     },
      //   ],
      // },
      // {
      //   type: 'input',
      //   field: 'functionOrder',
      //   title: '排序',
      //   value: this.formConfig && this.formConfig.functionOrder,
      //   props: {
      //     placeholder: '请输入排序',
      //     disabled: false,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   validate: [
      //     {
      //       trigger: 'blur',
      //       required: true,
      //       message: '请输入排序',
      //     },
      //   ],
      //   col: {
      //     md: { span: 12 },
      //   },
      // },
      // {
      //   type: 'select',
      //   field: 'isConfigure',
      //   title: '是否允许页面配置',
      //   value: this.formConfig && this.formConfig.isConfigure,
      //   props: {
      //     placeholder: '请选择',
      //     disabled: false,
      //     clearable: true, // 是否显示清空按钮
      //   },
      //   col: {
      //     md: { span: 12 },
      //   },
      //   options: [
      //     {
      //       value: '1',
      //       label: '是',
      //     },
      //     {
      //       value: '0',
      //       label: '否',
      //     },
      //   ],
      // },
      // {
      //   type: 'TableForm',
      //   field: 'list',
      //   title: '列表',
      //   value: {
      //     idKey: 'id',
      //     tableData: [{ functionName: 1231 }, { functionName: 7686 }],
      //     selection: [],
      //   },
      // },
    ],
  };
}
