<template>
  <a-tree-select
    show-search
    style="width: 100%"
    :value="treeValue"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :placeholder="placeholder"
    :disabled="disabled"
    allow-clear
    :multiple = "multiple"
    :tree-default-expand-all="expandAll"
    :treeData="treeData"
    :replaceFields="props"
    @change="onChange"
    :notFoundContent="loading?'加载中...':'无数据'"
    @focus="onFocus"
    treeNodeFilterProp="title"
  ></a-tree-select>
</template>

<script>
import request from '../../../utils/request';
import { isEmpty } from '../tools/init';

export default {
  props: {
    value: String,
    restful: String,
    restfulParams: Object,
    remoteParams: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    refresh: {
      type: Boolean,
      default() {
        return false;
      },
    },
    headers: Object,
    multiple: {
      type: Boolean,
      default() {
        return false;
      },
    },
    props: {
      type: Object,
      default() {
        return {
          children: 'children',
          title: 'title',
          key: 'key',
          value: 'value',
        };
      },
    },
  },
  data() {
    return {
      expandAll: false,
      treeValue: null,
      treeData: [],
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.multiple) {
          const valueArr = (val || '').split(',').filter((a) => (a));
          console.log(valueArr);
          this.setValue(valueArr);
        } else {
          this.setValue(val);
        }
        if (!this.restful) return;
        if (val && isEmpty(this.treeData)) {
          this.getTreeData({});
        }
      },
    },
  },
  methods: {
    setValue(value) {
      this.treeValue = value;
    },
    onChange(value, label, extra) {
      console.log(value, label, extra);
      this.treeValue = value;
      this.$emit('input', this.multiple ? this.treeValue.join(',') : this.treeValue);
      this.$emit('change', this.multiple ? this.treeValue.join(',') : this.treeValue, label, extra);
      this.$emit('cpsChange', value, label, extra);
    },
    onFocus() {
      if (!this.restful) return;
      if (isEmpty(this.treeData) || this.refresh) {
        this.getTreeData({});
      }
    },
    onSearch(value) {
      return false;
    },
    // 获取数据
    getTreeData(searchParams) {
      this.loading = true;
      request.post(this.restful, { ...this.restfulParams, ...searchParams }, { headers: this.headers }).then((res) => {
        if (res.success) {
          this.treeData = res.result.data || res.result || [];
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less"></style>
