<!--
 * @Author: yzr
 * @Date: 2020-11-23 15:13:14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-01 14:59:11
-->
<template>
  <a-modal
    :title="modalConfig.title"
    :visible="modalConfig.visible"
    :width="modalConfig.width || '1000px'"
    :destroyOnClose="true"
    :footer="null"
    centered
    :maskClosable="false"
    @cancel="close"
  >
    <el-scrollbar
      wrap-class="modal-scrollbar"
      :class="modalConfig.bottomPadding === false?'paddingbottom20':'dialog-style'"
      :style="{height: modalConfig.height || '60vh'}"
      :native="false"
      view-class="modal-container"
      :noresize="false"
      tag="div"
    >
      <slot></slot>
      <!-- <div v-if="modalConfig.showFooter" class="dialog-footer">
        <div>
          <el-button  icon="el-icon-check" type="primary" @click="onOk">确定</el-button>
          <el-button  icon="el-icon-close" type="danger" @click="close">取消</el-button>
        </div>
      </div> -->
      <div v-if="modalConfig.showFooter" class="dialog-footer">
        <div>
          <el-button  icon="el-icon-check" type="primary" @click="onOk" v-if="modalConfig.buttons.sure">确定</el-button>
          <el-button  icon="el-icon-close" type="danger" @click="close" v-else-if="modalConfig.buttons.close">关闭</el-button>
        </div>
      </div>
    </el-scrollbar>
  </a-modal>
</template>

<script>
export default {
  props: {
    // modalConfig: {
    //   type: Object,
    //   default: () => ({
    //     title: '标题',
    //     visible: false,
    //     showFooter: false,
    //     bottomPadding: true,
    //   }),
    // },
    modalConfig: {
      type: Object,
      default: () => ({
        title: '标题',
        visible: false,
        showFooter: false,
        bottomPadding: true,
        buttons: {
          sure: true, // 确定按钮
          close: true, // 关闭按钮
        },
      }),
    },
  },
  created() {

  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    // modal弹窗确定事件
    onOk() {
      this.$emit('onOk');
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-body {
  padding: 0;
}
.dialog-style {
  padding-bottom: 73px;
}

.paddingbottom20{
  padding-bottom: 20px;
}
/deep/.modal-container {
  height: 100%;
}
/deep/.modal-scrollbar {
  overflow-x: hidden;
  padding: 20px;
}
</style>
