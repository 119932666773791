const Storage = {
  s: {
    set(key, val, isObject = false) {
      return isObject
        ? sessionStorage.setItem(key, JSON.stringify(val))
        : sessionStorage.setItem(key, val);
    },
    get(key, isReturnObject = false) {
      const val = sessionStorage.getItem(key);
      if (val) {
        try {
          return isReturnObject
            ? JSON.parse(sessionStorage.getItem(key))
            : sessionStorage.getItem(key);
        } catch (e) {
          return null;
        }
      } else {
        return null;
      }
    },
    remove(key) {
      if (!sessionStorage[key]) return false;
      sessionStorage.removeItem(key);
      return true;
    },
    clear() {
      sessionStorage.clear();
    },
  },
  l: {
    set(key, val, isObject = false) {
      return isObject
        ? localStorage.setItem(key, JSON.stringify(val))
        : localStorage.setItem(key, val);
    },
    get(key, isReturnObject = false) {
      const val = localStorage.getItem(key);
      if (val) {
        try {
          return isReturnObject ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
        } catch (e) {
          return null;
        }
      } else {
        return null;
      }
    },
    remove(key) {
      if (!localStorage[key]) return false;
      localStorage.removeItem(key);
      return true;
    },
    clear() {
      localStorage.clear();
    },
  },
};

export default Storage;
