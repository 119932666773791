<template>
  <div
    class="page"
    ref="page"
    :style="'height:' + (isModalTable && isCalculateHeight ? modalPageHeight : '')"
    v-loading="pageLoading"
  >
    <!-- 搜索框 -->
    <div class="page-search" ref="search" v-if="searchList && searchList.length > 0">
      <vxe-form
        :data="formData"
        :titleWidth="configs.formConfig.titleWidth"
        :titleAlign="configs.formConfig.titleAlign"
        @submit="searchEvent"
        @toggle-collapse="toggleCollapse"
        @reset="searchEvent"
        :loading="isFormLoading"
      >
        <vxe-form-item
          v-for="(item, key) in searchList"
          :key="key"
          :title="item.label"
          :item-render="item.render"
          v-bind="item"
        >
          <template v-if="item.render.type === 'customSearch'">
            <component
              :is="item.render.typeName"
              :formData="formData"
              :value="formData[item.render.field]"
              @getCustomData="val => getCustomData(item.render, val)"
            />
          </template>
        </vxe-form-item>
        <vxe-form-item
          :span="searchSpan"
          align="right"
          :collapse-node="checkShowCollapseButton()"
          :item-render="{
            name: 'ElButtons',
            children: [
              {
                content: '查询',
                props: {
                  type: 'primary',
                  nativeType: 'submit',
                  icon: 'el-icon-search',
                  size: 'small',
                }
              },
              {
                content: '重置',
                props: { nativeType: 'reset', icon: 'el-icon-refresh', size: 'small' }
              }
            ]
          }"
        ></vxe-form-item>
      </vxe-form>
    </div>
    <!-- 操作按钮 -->
    <div
      class="page-toolbar"
      v-if="(configs.toolBarConfig && configs.toolBarConfig.length) || personalSetting"
    >
      <vxe-toolbar>
        <template v-slot:buttons>
          <span
            style="margin-left: 10px;background: transparent"
            v-for="(item, index) in configs.toolBarConfig.slice(0, btnNum)"
            :key="index"
          >
            <el-button
              :key="index"
              :size="item.size || 'small'"
              :icon="item.icon"
              v-if="item.code !== 'personal_setting'"
              @click="buttonClick({ val: item })"
              :type="item.buttonType"
              >{{ item.name }}
            </el-button>
          </span>
          <el-dropdown
            :style="configs.toolBarConfig.length > 0 ? { marginLeft: '10px' } : ''"
            trigger="click"
            ref="dropdown"
            hide-on-click
            v-if="personalSetting"
          >
            <el-button>{{ personalSetting.name }}</el-button>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex;flex-flow: column;padding:0 10px;">
                <!-- <slot>
                  <PersonalSetting
                    @changeColumns="changeColumns"
                    :headerFunctionCode="headerFunctionCode"
                    :parentCode="tableParentCode"
                  />
                </slot> -->
                <PersonalSetting
                  @changeColumns="changeColumns"
                  :headerFunctionCode="headerFunctionCode"
                  :parentCode="tableParentCode"
                />
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown style="margin-left: 10px;" v-if="configs.toolBarConfig.length > btnNum">
            <el-button type="primary">更多</el-button>
            <el-dropdown-menu slot="dropdown">
              <div style="display: flex;flex-flow: column;padding:0 10px;">
                <span v-for="(item, index) in configs.toolBarConfig.slice(btnNum)" :key="index">
                  <el-button
                    style="margin:5px 0 0 0"
                    :key="index"
                    :size="item.size || 'small'"
                    :icon="item.icon"
                    @click="buttonClick({ val: item })"
                    :type="item.buttonType"
                    >{{ item.name }}
                  </el-button>
                </span>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </vxe-toolbar>
    </div>
    <!-- 列表 -->
    <div class="page-table" v-if="configs.columns" v-loading="tableLoading">
      <vxe-table
        :row-class-name="tableRowClassName"
        :header-row-class-name="tableHeaderClassName"
        v-bind="configs.tableConfig"
        @checkbox-change="checkboxChange"
        @checkbox-all="checkboxAll"
        @radio-change="radioChange"
        @cell-click="cellClick"
        @edit-actived="editActivedEvent"
        class="mytable-scrollbar"
        size="mini"
        ref="xTable"
        :editConfig="editConfig"
        :rowId="configs.tableConfig && (configs.tableConfig.idKey || 'id')"
        :data="tableData"
        :tree-config="{
          ...this.handleTreeConfig(),
          loadMethod: loadChildrenMethod
        }"
        :height="isCalculateHeight ? tableHeight : ''"
      >
        <vxe-table-column
          v-for="(item, index) in configs.columns"
          :key="index + (item.field||item.fixed) + (item.formorder||'')"
          v-bind="item"
          :cellRender="item.cellRender"
          :tree-node="item.treeNode"
          :min-width="item.minWidth || 100"
        >
          <template v-if="item.buttons" v-slot="{ row }">
            <div class="inline-block" v-if="item.noShowMore">
              <div v-for="(btn, k) in item.buttons" :key="k">
                <a type="text" @click="val => clickCell({ val: btn, row })" v-if="clickVisible({ val: btn, row })">{{ btn.name }} </a>
              </div>
            </div>
            <div class="setting-btn" v-else>
              <div class="inline-block">
                <div v-for="(btn, k) in item.buttons.slice(0, 1)" :key="k">
                  <a type="text" @click="val => clickCell({ val: btn, row })" v-if="clickVisible({ val: btn, row })">{{ btn.name }} </a>
                </div>
              </div>
              <el-divider
                direction="vertical"
                v-if="item.buttons.length > 1 && clickVisible({ val: 'divider', row })"
              ></el-divider>
              <div class="inline-block" v-if="item.buttons.length === 2">
                <div v-for="(btn, k) in item.buttons.slice(1, 2)" :key="k">
                  <a type="text" @click="val => clickCell({ val: btn, row })" v-if="clickVisible({ val: btn, row })">{{ btn.name }} </a>
                </div>
              </div>
              <el-dropdown
                @command="val => clickCell({ val, row })"
                v-if="item.buttons.length > 2 && clickVisible({ val: '', row })"
              >
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div v-for="(btn, k) in item.buttons.slice(1, item.buttons.length)" :key="k">
                    <el-dropdown-item :command="btn" v-if="clickVisible({ val: btn, row })">
                      {{ btn.name }}
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
          <template v-else-if="item.dictCode" v-slot="{ row }">
            <span>{{ getDictCode(row[item.field], item.dictCode) }}</span>
          </template>
        </vxe-table-column>
      </vxe-table>
    </div>
    <!-- 分页 -->
    <div
      class="page-pageination"
      :class="isCalculateHeight ? 'bottom-pageination' : ''"
      v-if="
        tableData.length > 0 &&
          configs.columns &&
          (typeof configs.pagination === 'undefined' ||
            (typeof configs.pagination !== 'undefined' && configs.pagination))
      "
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tablePage.currentPage"
        :page-sizes="paginationSize"
        :page-size="tablePage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        align="right"
        :total="tablePage.totalResult || tableData.length"
      >
      </el-pagination>
    </div>
    <!-- 弹框 -->

    <component
      :modalConfig.sync="modalConfig"
      :visible.sync="modalConfig.visible"
      v-if="modalName"
      :is="modalName"
      ref="modalName"
      :rules.sync="configs.rule"
      @submit="onSubmit"
      @onClose="closeModal"
      @onGetList="getList"
      @onGetSelect="onGetSelect"
      :formConfig="formConfig"
      :propsObj="propsObjInData"
      @load-complete="formLoadComplete"
      :modal-form-data.sync="modalFormData"
    />
    <Modal
      :modalConfig.sync="modalConfig"
      @onClose="closeModal"
      @onOk="onModalOk"
      :visible.sync="modalConfig.visible"
      v-else
    >
      <component
        :is="formName"
        ref="modalForm"
        :rules.sync="configs.rule"
        @submit="onSubmit"
        @onClose="closeModal"
        @onGetList="getList"
        @resetTree="resetTree"
        @onGetSelect="onGetSelect"
        :formConfig="formConfig"
        :modalConfig="modalConfig"
        :propsObj="propsObjInData"
        @load-complete="formLoadComplete"
        :modal-form-data.sync="modalFormData"
      />
      <div v-if="modalConfig.showFooter" class="dialog-footer">
        <slot name="footer">
          <el-button icon="el-icon-check" type="primary" @click="onModalOk">确定</el-button>
          <el-button icon="el-icon-close" type="danger" @click="closeModal">取消</el-button>
        </slot>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from 'xe-utils';
import { mapState } from 'vuex';
import { forEach } from 'mathjs';
import Modal from '../modal';
import Form from '../form';
import request from '../../utils/request';
import TableTools from '../../plugins/TableTools';
import ExportModal from '../export_modal';
import PersonalSetting from '../personal_setting';
import LogTable from '../log_table';
import * as utils from '../../utils/index';

// 提交审批
import submitApprove from '../submit_approve/submit_approve.vue';

export default {
  components: {
    Modal,
    Form,
    ExportModal,
    submitApprove,
    PersonalSetting,
    LogTable,
  },
  computed: {
    ...mapState({ selectConfig: (state) => state.app.selectConfig }),
  },
  data() {
    return {
      code: 'all',
      fenli: '',
      fenliAll: '',
      dropdown: true,
      autoGetList: true, // 自动请求列表数据
      btnNum: 8,
      modalName: '',
      requestUrl: '',
      deleteUrl: '',
      oldSpan: 6,
      searchSpan: 6, // 搜索按钮所占比例
      spanFirstCount: 0, // 搜索按钮所占比例
      spanEndCount: 0, // 搜索按钮所占比例
      params: {},
      loading: false,
      pageLoading: false,
      tableLoading: false,
      formConfig: null, // 弹框数据
      propsObjInData: {}, // 自定义数据
      formData: {}, // 搜索框绑定数据
      searchFormData: {},
      modalFormData: {}, // 弹出层绑定数据
      configs: {
        // 配置信息
        columns: [], // 列信息
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          searchList: [],
          item: [],
        }, // 搜索框信息
        tableConfig: {
          border: true,
          treeConfig: {
            children: 'children',
          },
          idKey: 'id', // 默认勾选判断字段
          resizable: true, // 所有的列是否允许拖动列宽调整大小
          showOverflow: true, // 当内容过长时显示为省略号
          keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
          highlightHoverRow: true, // 鼠标滑动高亮
          highlightCurrentRow: true,
          stripe: true,
          checkboxConfig: { checkMethod: this.checkBoxMethod },
        }, // 表信息
        toolBarConfig: null, // 操作按钮信息
      },
      tableData: [], // 列表数据
      selection: [], // 默认选中
      checkStrictly: false,
      selectRow: [],
      selectCache: [],
      tablePage: {
        // 分页
        currentPage: 1,
        pageSize: 15,
        totalResult: 0,
      },
      paginationSize: [5, 10, 15, 20, 50, 100, 150, 200, 500],
      modalConfig: {}, // 弹框信息
      formName: 'Form',
      searchList: [],
      cellClickConfig: null,
      editConfig: {
        showIcon: false,
      },
      dictCodeList: {},
      selectType: 'checkbox',
      clearFormConfig: true,
      headerFunctionCode: '',
      personalSetting: null,
      isCalculateHeight: true,
      otherHeight: 150,
      searchOpenHeight: 0,
      tableHeight: this.setPageHeight(),
      screenHeight: window.innerHeight,
      isModalTable: false,
      modalPageHeight: '200px',
      tableParentCode: this.$store.state.menus.selectMenu,
      checkType: null,
      isRowClickCheckBox: true, // 是否点击行选中
      exportParams: {}, // 导出传参
      requestType: 'POST',
      isFormLoading: false,
    };
  },
  watch: {
    screenHeight(val) {
      this.setPageHeight();
    },
    tableData(val) {
      this.setPageHeight();
    },
    $route(to, from) {
      this.setPageHeight();
      // this.closeModal();
    },
    modalConfig: {
      deep: true,
      handler(val) {
        // this.setPageHeight();
        this.$nextTick(() => {
          // if (!val.visible) {
          if (document.getElementsByClassName('modal-container').length > 0) {
            const node1 = document.getElementsByClassName('modal-container')[0];
            const node2Arr = document.getElementsByClassName('page');
            node2Arr.forEach((item) => {
              if (utils.isFatcher(item, node1)) {
                this.isModalTable = true;
                const pageHeight = this.$refs.page.getClientRects()
                  && this.$refs.page.getClientRects()[0]
                  && this.$refs.page.getClientRects()[0].height;
                this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
              } else {
                this.isModalTable = false;
                const pageHeight = this.$refs.page.getClientRects()
                  && this.$refs.page.getClientRects()[0]
                  && this.$refs.page.getClientRects()[0].height;
                this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
              }
            });
          } else {
            this.isModalTable = false;
            setTimeout(() => {
              const pageHeight = this.$refs.page.getClientRects()
                && this.$refs.page.getClientRects()[0]
                && this.$refs.page.getClientRects()[0].height;
              this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
            }, 10);
          }
        });
      },
    },
    propsObjInData: {
      deep: true,
      handler(val) {
        // this.setPageHeight();
        this.$nextTick(() => {
          if (!val.visible) {
            if (document.getElementsByClassName('modal-container').length > 0) {
              this.isModalTable = true;
            } else {
              this.isModalTable = false;
              setTimeout(() => {
                const pageHeight = this.$refs.page.getClientRects()
                  && this.$refs.page.getClientRects()[0]
                  && this.$refs.page.getClientRects()[0].height;
                this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
              }, 10);
            }
          }
        });
      },
    },
  },
  mounted() {
    window.onresize = () => (() => {
      window.screenHeight = window.innerHeight;
      this.screenHeight = window.screenHeight;
    })();
    const list = { ...this.formData };
    const itemList = [];
    const { formConfig, columns } = this.configs;

    if (formConfig.searchList && formConfig.searchList.length > 0) {
      this.searchList = this.searchList.concat(formConfig.searchList);
    }

    // 设置搜索框key
    if (formConfig) {
      Promise.all(
        formConfig.item.map(async (v, i) => {
          list[v.field] = v.value || '';
          itemList[i] = await this.setSearchConfig(v, i);
          return v;
        }),
      ).then(() => {
        this.concatSelectList(itemList, 'itemList');
        this.formData = {
          ...this.formData,
          ...list,
        };
      });
    }
    if (columns.length > 0) {
      // 获取数据
      if (this.autoGetList) this.getList();
    }
  },
  /**
   *
   */
  methods: {
    // 控制按钮分割divider是否显示
    dividerIsShow(btns, row) {
      let btnCounts = 0;
      btns.forEach((v) => {
        if (this.clickVisible({ val: v, row })) {
          btnCounts += 1;
        }
      });
      return btnCounts > 1;
    },
    tableRowClassName({ row, rowIndex }) {
      return '';
    },
    tableHeaderClassName({ row, rowIndex }) {
      return 'table-header';
    },
    // 对搜索框进行拼接
    concatSelectList(list, itemList) {
      if (list.length > 0) {
        if (itemList) {
          this.searchList = list.concat(this.searchList);
        } else {
          // this.searchList = this.searchList.concat(list);
          this.searchList = list;
        }
      }
      // 配置回调获取到了
      if (this.configs.columns.length > 0) {
        this.configs.columns.forEach((v) => {
          for (let i = 0; i < this.searchList.length; i += 1) {
            const a = this.searchList[i];
            if (a.field === v.field && v.search === 'false') {
              this.searchList.splice(i, 1);
              i -= 1;
            }
          }
        });
      }
      let spanEndCount = 0;
      let spanFirstCount = 0;
      this.searchList.map((v) => {
        const rowData = v;
        spanEndCount += parseInt(rowData.span || 6, 0);
        if (spanEndCount > 18) {
          rowData.folding = true;
        } else {
          spanFirstCount += parseInt(rowData.span || 6, 0);
        }
        return rowData;
      });
      this.spanEndCount = spanEndCount % 24;
      this.spanFirstCount = spanFirstCount;
      this.searchSpan = 24 - spanFirstCount >= 6 ? 24 - spanFirstCount : 24;
      this.oldSpan = 24 - spanFirstCount >= 6 ? 24 - spanFirstCount : 24;
    },
    setTableSelectedRow() {
      console.log('table_page自身的', this.$refs.xTable.loadData);
    },
    selectable({ row }) {
      if (this.disabledRow && typeof this.disabledRow === 'function') {
        // console.log(this.disabledRow(row));
        return this.disabledRow(row);
      }
      return true;
    },
    editActivedEvent: Function,
    headerCellClick(cell, event) {
      console.log('cell', cell);
      console.log('event', event);
    },
    formLoadComplete: Function,
    isExtendsFn(item) {
      return (
        item.buttonOperationType
        && Number(item.buttonOperationType) > 0
        && item.apiUrl !== ''
        && !!item.apiUrl
      );
    },
    async getSelectOptions(rowData) {
      if (rowData.dictCode) {
        const res = await request.post('/mdm/mdmdictdata/list', {
          dictTypeCode: rowData.dictCode,
        });
        return res.result;
      }
      if (rowData.apiUrl) {
        // 如果有自定义url，使用自定义接口
        // 传入固定参数
        const params = {
          ...rowData.paramsFixed,
          ...this.selectConfig,
        };
        const res = await request[rowData.methods || 'post'](rowData.apiUrl, params);
        // result have a value, but the value type is not an array
        if (res.result && !_.isArray(res.result)) {
          return res.result.data;
        }
        return res.result;
      }
      return [];
    },
    // 处理options
    setOptions(val, rowData) {
      let tempVal = [];
      if (_.isArray(val)) {
        tempVal = val;
      } else {
        tempVal = val.data ? val.data : [];
      }
      const list = [];
      tempVal.forEach((v) => {
        const value = rowData.optionsKey ? v[rowData.optionsKey.value] : v.dictCode;
        const label = rowData.optionsKey ? v[rowData.optionsKey.label] : v.dictValue;
        list.push({
          value,
          label,
        });
      });
      return list;
    },
    // 搜索条件前置
    beforeSetSearch(val) {
      return val;
    },
    // 设置搜索条件
    async setSearchConfig(val) {
      const rowData = this.beforeSetSearch(val);
      if (rowData.type === 'input') {
        rowData.type = 'ElInput';
        rowData.placeholder = `请输入${rowData.title}`;
      }

      if (rowData.type === 'select') {
        rowData.type = 'ElSelect';
        if (!rowData.options || rowData.options.length === 0) {
          const res = await this.getSelectOptions(rowData);
          rowData.options = this.setOptions(res, rowData);
        }
        // console.log(rowData);
        rowData.props = {
          filterable: rowData.isSearch,
          remote: !!rowData.apiUrl,
          multiple: rowData.multiple || false,
          filterMethod: (value) => {
            if (!value) return;
            const params = {
              [rowData.paramsName]: value,
              ...rowData.paramsFixed,
              ...this.selectConfig,
            };
            request.post(rowData.apiUrl, params, rowData.headers).then((r) => {
              const selectField = this.searchList.filter((v) => v.field === rowData.field)[0];
              selectField.render.options = this.setOptions(r.result, rowData);
            });
          },
        };
        rowData.events = {
          ...rowData.events,
          focus: async () => {
            if (!this.formData[rowData.field] || (this.formData[rowData.field] instanceof Array && this.formData[rowData.field].length === 0)) {
              rowData.options = [];
              const res = await this.getSelectOptions(rowData);
              rowData.options = await this.setOptions(res, rowData);
              this.searchList.map((list) => {
                const item = list;
                if (item.field === rowData.field) {
                  if (item.render) {
                    item.render.options = rowData.options;
                  }
                }
                return item;
              });
            }
          },
        };
        rowData.placeholder = `请选择${rowData.title}`;
      }
      if (rowData.type === 'DatePicker') {
        rowData.type = 'ElDatePicker';
        rowData.props = {
          valueFormat: 'yyyy-MM-dd',
        };
        rowData.placeholder = `请选择${rowData.title}`;
      }
      if (rowData.type === 'DatePickerMonth') {
        rowData.type = 'ElDatePicker';
        rowData.props = {
          valueFormat: 'yyyy-MM',
          type: 'month',
        };
        rowData.placeholder = `请选择${rowData.title}`;
      }
      if (rowData.type === 'DateTimePicker') {
        rowData.type = 'ElDatePicker';
        rowData.props = {
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          'default-time': rowData.field.toLowerCase().includes('end') ? '23:59:59' : '00:00:00',
        };
        rowData.placeholder = `请选择${rowData.title}`;
      }
      if (rowData.type === 'TimePicker') {
        rowData.type = 'ElTimePicker';
        rowData.props = {
          valueFormat: 'HH:mm:ss',
          arrowControl: true,
          pickerOptions: {
            selectableRange: '00:00:00 - 23:59:59',
          },
        };
        rowData.placeholder = `请选择${rowData.title}`;
      }
      return {
        field: rowData.field,
        span: rowData.span || '6',
        label: rowData.label || '',
        title: {
          name: rowData.title,
        },
        render: {
          ...rowData,
          name: rowData.type,
          attrs: {
            placeholder: rowData.placeholder,
          },
          options: rowData.options,
          props: {
            ...rowData.props,
            clearable: true,
            placeholder: rowData.placeholder,
          },
        },
      };
    },
    // 自定义搜索条件的回调
    getCustomData(item, val) {
      this.formData[item.field] = val;
    },
    commandDropdown(val) {
      console.log(val);
    },
    // 操作列配置
    changeColumns() {
      this.$refs.dropdown.hide();
      this.searchList = [];
      const list = { ...this.formData };
      const itemList = [];
      const { formConfig, columns } = this.configs;

      if (formConfig.searchList && formConfig.searchList.length > 0) {
        this.searchList = this.searchList.concat(formConfig.searchList);
      }

      // 设置搜索框key
      if (formConfig) {
        Promise.all(
          formConfig.item.map(async (v, i) => {
            list[v.field] = v.value || '';
            itemList[i] = await this.setSearchConfig(v, i);
            return v;
          }),
        ).then(() => {
          this.concatSelectList(itemList, 'itemList');
          this.formData = {
            ...this.formData,
            ...list,
          };
        });
      }
      this.getConfigList(
        this.getConfigListData.functionCode,
        this.getConfigListData.noCell,
        this.getConfigListData.noTop,
        this.getConfigListData.parentCode,
      );
    },
    // 获取配置
    async getConfigList(functionCode, noCell, noTop, parentCode) {
      this.configs.columns = [];
      this.headerFunctionCode = functionCode;
      this.getConfigListData = {
        functionCode,
        noCell,
        noTop,
        parentCode,
      };
      const params = {
        function_code: functionCode,
        functionCode,
      };

      // 支持获取指定模版
      if (typeof parentCode !== 'undefined') {
        params.parentCode = parentCode;
      }
      this.pageLoading = true;
      const res = await request.post('/mdm/mdmtableconfig/query', params);
      this.pageLoading = false;
      if (res.result.url && !this.requestUrl) this.requestUrl = res.result.url;
      // 置空一个对象作为搜索条件双向绑定
      const searchList = {};
      const searchConfig = [];
      // 置空一个数组作为列配置
      const columns = [];
      // 置空一个数组作为数据字典配置
      const dictCodeList = [];
      if (res.success) {
        // 获取列信息
        await Promise.all(
          res.result.column.map(async (v, i) => {
            let rowData = v;
            rowData.showOverflow = true;
            if (rowData.dictCode) {
              dictCodeList.push(rowData.dictCode);
            }

            // 判断当前字段是否属于勾选框类型
            if (rowData.field.indexOf('checkType') !== -1) {
              rowData = {
                ...rowData,
                fixed: 'left', // 固定位置在左边
                align: 'left',
                width: '36',
                type: rowData.field.replace('checkType', ''),
              };
              this.checkType = rowData.field.replace('checkType', '');
            }
            if (rowData.editView === 'true') {
              rowData.className = 'formatter-view';
              rowData = {
                ...rowData,
                editRender: {
                  name: '$button',
                  events: {
                    click: this.cellClick,
                  },
                },
              };
            }
            rowData = this.setColumn(rowData);
            columns.push(rowData);
            // 判断当前字段是否支持搜索
            if (rowData.search === 'true') {
              searchList[rowData.field] = '';
              // 获取当前字段搜索配置
              let searchItem = await this.setSearchConfig(rowData, i);
              searchItem = this.setSearchField(searchItem);
              searchConfig[i] = searchItem;
            }
            if (!rowData.width && rowData.title) {
              const con = document.createElement('canvas').getContext('2d');
              con.font = '700 12px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif'; // 自己需要的字体什么的
              // 生成宽度查询函数
              const strlen = (str) => con.measureText(str).width;
              rowData.minWidth = strlen(rowData.title) + 20 > 100 ? strlen(rowData.title) + 20 : 100;
              rowData.minWidth = Math.ceil(rowData.minWidth) + 10;
            }
            return rowData;
          }),
        ).then(() => {
          this.formData = {
            ...this.formData,
            ...searchList,
          };
          if (this.params.searchData === 'Y') {
            this.formData = {
              ...this.formData,
              ...this.params,
            };
          }
          this.configs.columns = columns;
          if (searchConfig.length > 0) {
            const list = [];
            searchConfig.forEach((a) => {
              if (a) list.push(a);
            });

            this.concatSelectList(list, '');
          }
          if (dictCodeList.length > 0) {
            this.setDictCode(dictCodeList);
          }
          // 设置按钮
          this.setButton(res.result.buttonVos, noCell, noTop);

          // 获取数据
          if (this.autoGetList) this.getList();
        });
      }
      return columns;
    },
    // 设置按钮
    setButton(buttonVos, noCell, noTop) {
      const toolBarConfig = [];
      const cellBarConfig = [];
      buttonVos.forEach((v) => {
        let rowData = {
          ...v,
          icon: v.buttonIcon,
          code: v.buttonCode,
          name: v.buttonName,
        };
        // 判断是否需要头部按钮
        if (v.buttonType === '0' && !noTop) {
          rowData = this.setToolsBtn(rowData);
          // 判断是否是个性设置按钮
          if (v.buttonCode === 'personal_setting') {
            this.personalSetting = rowData;
          } else {
            toolBarConfig.push(rowData);
          }
        } else if (v.buttonType === '1' && !noCell) {
          rowData = this.setRowBtn(rowData);
          cellBarConfig.push(rowData);
        }
      });
      // 如果有行操作按钮新增一列
      if (cellBarConfig.length > 0) {
        let str = '';
        cellBarConfig.forEach((v) => {
          str += v.name;
        });

        this.configs.columns.push({
          title: '操作',
          width: str.length < 10 || cellBarConfig.length > 2 ? '160' : str.length * 16,
          fixed: 'right',
          align: 'center',
          buttons: cellBarConfig,
          type: 'setting',
        });
      }
      this.configs.toolBarConfig = toolBarConfig;
      // console.log(toolBarConfig);
    },
    // 获取列表中包含的数据字典
    setDictCode(list) {
      request.post('/mdm/mdmdictdata/batchDictSelect', list).then((res) => {
        this.dictCodeList = res.result;
      });
    },
    // 获取列表中包含的数据字典
    getDictCode(val, code) {
      if (JSON.stringify(this.dictCodeList) !== '{}') {
        if (this.dictCodeList[code]) {
          const dict = this.dictCodeList[code].find((v) => v.dictCode === `${val}`);
          return dict ? dict.dictValue : val;
        }
        return val;
      }
      return val;
    },
    // 检查搜索条件里面是否有你折叠后的空间
    checkShowCollapseButton() {
      for (const item of this.searchList) {
        if (item.folding) {
          return true;
        }
      }
      return false;
    },
    // 搜索按钮展开收起后都居右，且收起时占位6
    toggleCollapse(val) {
      if (val.collapse) {
        if (this.searchList.length === 4) {
          this.searchSpan = 24;
        } else {
          this.searchSpan = (4 - (this.searchList.length % 4)) * 6;
        }
      } else {
        this.searchSpan = this.oldSpan;
      }
      this.setPageHeight();
    },
    // 动态计算page高度
    setPageHeight() {
      if (this.searchList && this.searchList.length === 0) {
        if (
          this.configs
          && this.configs.toolBarConfig
          && this.configs.toolBarConfig.length === 0
          && !!this.personalSetting === false
        ) {
          this.otherHeight = 53;
        } else {
          this.otherHeight = 101;
        }
      } else if (
        this.configs
        && this.configs.toolBarConfig
        && this.configs.toolBarConfig.length === 0
        && !!this.personalSetting === false
      ) {
        this.otherHeight = 101;
      }
      this.$nextTick(() => {
        this.searchOpenHeight = this.$refs.search && this.$refs.search.getClientRects()[0]
          ? this.$refs.search.getClientRects()[0].height - 44
          : 0;
        if (document.getElementsByClassName('modal-container').length > 0) {
          const node1 = document.getElementsByClassName('modal-container')[0];
          const node2Arr = document.getElementsByClassName('page');
          node2Arr.forEach((item) => {
            if (utils.isFatcher(item, node1)) {
              this.isModalTable = true;
              const pageHeight = this.$refs.page.getClientRects()
                && this.$refs.page.getClientRects()[0]
                && this.$refs.page.getClientRects()[0].height;
              this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
            } else {
              this.isModalTable = false;
              const pageHeight = this.$refs.page.getClientRects()
                && this.$refs.page.getClientRects()[0]
                && this.$refs.page.getClientRects()[0].height;
              this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
            }
          });
        } else {
          this.isModalTable = false;
          const pageHeight = this.$refs.page.getClientRects()
            && this.$refs.page.getClientRects()[0]
            && this.$refs.page.getClientRects()[0].height;
          this.tableHeight = pageHeight - this.otherHeight - this.searchOpenHeight;
        }
      });
    },
    // 获取弹框中的选中项
    onGetSelect(val) {
      this.value = val;
    },
    handleTreeConfig() {
      if (this.configs && this.configs.tableConfig && this.configs.tableConfig.treeConfig) {
        return this.configs.tableConfig.treeConfig;
      }
      return {};
    },
    // 获取列表数据前置函数，返回false不执行后面的操作
    beforeGetList() {
      return true;
    },
    // 获取列表数据后置函数
    afterGetList() {
      return true;
    },
    // 获取列表数据
    getList() {
      if (this.beforeGetList()) {
        const { searchFormData, tablePage } = this;
        const params = {
          ...this.params,
          ...searchFormData,
          pageNum: tablePage.currentPage,
          pageSize: tablePage.pageSize,
        };
        if (this.requestType === 'GET') {
          params.page = tablePage.currentPage;
          params.size = tablePage.pageSize;
        }
        Object.assign(params, this.paramsProps ? this.paramsProps : {});
        if (!this.requestUrl) return Promise.resolve();
        if (!this.isPageChange) {
          this.selection = [];
          this.selectRow = [];
        }
        this.isPageChange = false;
        if (this.dataSource && this.dataSource instanceof Array) {
          this.tableData = this.dataSource;
        } else {
          this.tableLoading = true;
          if (this.requestType === 'POST') {
            return request
              .post(
                this.requestUrl,
                { ...params },
                { headers: { functionCode: this.headerFunctionCode } },
              )
              .then((res) => {
                if (res.success) {
                  this.tableLoading = false;
                  if (res.result.data) {
                    this.tableData = res.result.data;
                    this.tablePage.totalResult = res.result.count;
                  } else if (res.result.length >= 0) {
                    this.tableData = res.result;
                  } else {
                    this.tableData = [];
                  }
                  this.afterGetList();
                  this.setSelectData();
                }
              });
          } if (this.requestType === 'GET') {
            return request
              .get(
                this.requestUrl,
                {
                  ...params,
                },
                { headers: { functionCode: this.headerFunctionCode } },
              )
              .then((res) => {
                if (res.success) {
                  this.tableLoading = false;
                  if (res.result.records) {
                    this.tableData = res.result.records;
                    this.tablePage.totalResult = res.result.total;
                  } else if (res.result.records.length >= 0) {
                    this.tableData = res.result.records;
                  }
                  this.afterGetList();
                  this.setSelectData();
                }
              });
          }
        }
      }
    },
    // 操作按钮前置事件
    beforeButtonClick() {
      return true;
    },
    // 格式化表单数据
    formatData(data) {
      if (Array.isArray(data)) {
        return {};
      }
      return data;
    },
    // 操作按钮事件
    buttonClick({ val }) {
      if (!this.beforeButtonClick({ val })) return false;
      if (this.isExtendsFn(val)) {
        for (const key of Object.keys(this.modalFormData)) {
          delete this.modalFormData[key];
        }
        const Tools = new TableTools(val);
        Tools.triggerClick(
          this.selectRow,
          (rule, detail) => {
            // console.log(detail);
            // if (detail) this.modalFormData = this.formatData({...detail})

            // Object.assign(this.modalFormData, this.formatData({ ...detail}));
            this.modalClick({ val, row: this.selectRow }, rule);
          },
          this.getList,
        );
      } else if (val.code === 'delete') {
        this.deleteClick({ val });
      } else if (val.code === 'export' || val.code === 'dealt_export' || val.code === 'terminal_area_org_export') {
        console.log('ccc');
        this.exportClick({ val });
      } else if (val.code === 'import' || val.code === 'role_import') {
        console.log(val.code);
        this.importClick({ val });
      } else if (val.code === 'log') {
        this.logClick({ val });
      } else {
        this.modalClick({ val, row: this.selectRow });
      }

      return { ...val };
    },
    // 重置树
    resetTree() {
      this.$emit('resetTrees');
    },
    beforeClickCell({ val, row }) {
      return { val, row };
    },
    // 行操作事件
    clickCell({ val, row }) {
      if (this.isExtendsFn(val)) {
        for (const key of Object.keys(this.modalFormData)) {
          delete this.modalFormData[key];
        }
        const result = this.beforeClickCell({ val, row });
        if (!result) return;
        const Tools = new TableTools(result.val);
        Tools.triggerClick(
          result.row,
          (rule, detail) => {
            console.log(detail);
            // if (detail) this.modalFormData = this.formatData({...detail})
            // Object.assign(this.modalFormData, this.formatData({ ...detail}));

            // console.log(this.formatData({...detail}));
            this.modalClick(
              {
                val: result.val,
                row: detail ? this.formatData({ ...detail }) : result.row,
              },
              rule,
            );
          },
          this.getList,
        );
      } else if (val.code === 'delete') {
        this.deleteClick({ val, row });
      } else if (val.code === 'log') {
        this.logClick({ val, row });
      } else {
        this.modalClick({ val, row });
      }

      return { val, row };
    },
    // 行按钮隐藏
    clickVisible() {
      return true;
    },
    // 搜索事件前置事件
    beforeSearchEvent() {
      return true;
    },
    cityData(val) {
      console.log('1111');
      // this.cityEmit = val;
      // debugger;
      if (val) {
        console.log('接收值');
      }
    },
    // 搜索事件
    searchEvent(val) {
      this.isPageChange = true;
      if (!this.beforeSearchEvent(val)) return;
      this.searchFormData = JSON.parse(JSON.stringify(this.formData));
      this.tablePage.currentPage = 1;
      if (val.$event.type === 'reset') {
        this.isPageChange = false;
        if (this.params && this.params.code) {
          this.params.code = '';
          this.$emit('resetTrees');
        } else {
          this.params = { ...this.params, code: '' };
          this.getList();
        }
        return;
      }
      this.getList();
    },
    // 弹框操作前置
    beforeModalClick({ val, row }, rule) {
      return { val, row, rule };
    },
    // 弹框操作
    async modalClick({ val, row }, rule) {
      const self = this;
      // this.formConfig = {};
      const result = await this.beforeModalClick({ val, row }, rule);
      if (!result || !rule) return false;
      if (result.rule) {
        if (val.code !== 'add') {
          this.modalFormData = {
            ...(_.isEmpty(result.row)
              ? {}
              : (Array.isArray(result.row) && result.row[0]) || result.row || {}),
          };
        }
        self.modalConfig.config = result.val;
        self.modalConfig.title = result.val.name;
        self.configs.rule = result.rule.map((field) => this.setFormField(field));
      }
      this.openModal();
      return { val, row };
    },
    // 导出
    exportClick({ val }) {
      if (val.code === 'export' || val.code === 'dealt_export' || val.code === 'terminal_area_org_export') {
        this.modalName = 'ExportModal';
        this.propsObjInData = {
          functionCode: val.doCode ? val.doCode : val.functionCode,
          requestUrl: val.queryUrl ? val.queryUrl : this.requestUrl, // 非导入导出文案的导入导出功能，查询数据接口不是列表接口，可通过查询接口传入
          visible: true,
          exportMessage: '',
          isExport: true,
          ids: this.selection,
          formData: { ...this.params, ...this.searchFormData, ...this.exportParams },
          sid: utils.getUuid(32, 16),
        };
      }
    },
    // 打开日志
    logClick({ val, row }) {
      this.modalConfig.title = '日志';
      this.formName = 'LogTable';
      this.formConfig = {
        code: val.code,
        row,
      };
      // this.propsObjInData.selection = [row.id];
      this.openFull();
    },
    // 导入
    importClick({ val }) {
      if (val.code === 'import' || val.code === 'role_import') {
        if (val.configCode) {
          this.modalName = 'ExportModal';
          this.propsObjInData = {
            functionCode: val.doCode ? val.doCode : val.functionCode,
            requestUrl: val.queryUrl ? val.queryUrl : this.requestUrl,
            visible: true,
            exportMessage: '',
            isExport: false,
            configCode: val.configCode,
            sid: utils.getUuid(32, 16),
          };
        } else {
          this.$message.error('请配置导入编码');
        }
      }
    },
    // 删除事件前置
    beforeDeleteClick() {
      return true;
    },
    // 删除事件
    deleteClick({ val, row }) {
      if (!this.beforeDeleteClick({ val, row })) return false;
      const data = {
        params: { ids: [] },
        url: val.apiUrl,
        title: val.ask,
        msg: val.buttonName,
      };
      if (this.selection.length === 0) {
        this.$message('请选择一条数据');
        return false;
      }
      if (row) {
        data.params.ids = [row.id];
      } else {
        data.params = this.selection;
      }
      this.messageBox(data);
      return { val, row };
    },
    // 确认弹框
    messageBox(data) {
      this.$confirm(data.title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.post(data.url, data.params).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: `${data.msg}成功`,
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `已取消${data.msg}`,
          });
        });
    },
    // 打开弹窗
    openModal() {
      console.log('22222222222222222222', this.modalConfig);
      this.modalConfig = {
        ...this.modalConfig,
        ...{
          visible: true,
          type:
            this.modalConfig.type === undefined
            || this.modalConfig.type === ''
            || !this.modalConfig.type
              ? 'Modal'
              : this.modalConfig.type,
        },
      };
    },
    // 打开抽屉
    openDrawer() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Drawer' },
      };
    },
    // 全屏打开
    openFull() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Full' },
      };
    },
    // 关闭弹窗
    closeModal() {
      if (this.clearFormConfig) {
        this.formConfig = {};
      }
      this.formName = 'Form';
      this.modalFormData = {}; // 清空弹窗中表单的数据
      this.modalConfig = {
        ...this.modalConfig,
        width: '',
        type: '',
        visible: false,
      };
    },
    // 单选
    radioChange({ row }) {
      if (this.beforeRadioChange({ row })) {
        // 获取选择依据
        const { idKey } = this.configs.tableConfig;
        const isChecked = this.$refs.xTable.isCheckedByRadioRow(row);
        this.$refs.xTable.clearRadioRow();
        if (!isChecked) {
          this.selectRow = [];
          this.selection = [];
        } else {
          this.$refs.xTable.setRadioRow(row);
          this.selectRow = [row];
          this.selection = [row[idKey]];
        }
        this.afterRadioChange({ row });
      }
    },
    // 单选框前置
    beforeRadioChange() {
      return true;
    },
    afterRadioChange() {
      return true;
    },
    // 多选
    checkboxChange({ row, checked, records }) {
      if (!this.beforeCheckboxChange({ row, checked })) return false;
      const isChecked = this.$refs.xTable.isCheckedByCheckboxRow(row);
      // 获取选择依据
      const { idKey } = this.configs.tableConfig;

      // this.$refs.xTable.toggleCheckboxRow(row);
      if (!isChecked) {
        const list = [];
        this.selection.forEach((v, k) => {
          if (v === row[idKey]) {
            this.selectRow.splice(k, 1);
          } else {
            list.push(v);
          }
        });
        this.selection = list;
      } else {
        this.selectRow.push(row);
        this.selection.push(row[idKey]);
      }
      this.afterCheckboxChange({ row, checked, records });
      return true;
    },
    // 复选框前置
    beforeCheckboxChange() {
      return true;
    },
    // 复选框后置
    afterCheckboxChange() {
      return true;
    },
    // 全选
    checkboxAll(val) {
      // 获取选择依据
      const { idKey } = this.configs.tableConfig;
      this.selection = val.records.map((v) => v[idKey]);
      this.selectRow = val.records;
      this.afterCheckAllChange(val);
    },
    afterCheckAllChange() {
      return true;
    },
    // 选择分页数量
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.currentPage = 1;
      this.getList();
    },
    // 选择页数
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
      this.isPageChange = true;
      this.getList();
    },
    // 点击勾选
    clickCheck({ row, column }) {
      if (
        column.type === 'checkbox'
        || column.type === 'radio'
        || column.type === 'setting'
        || column.className === 'formatter-view'
        || !this.isRowClickCheckBox
        || column.treeNode
      ) {
        return false;
      }
      const { idKey } = this.configs.tableConfig;
      if (this.checkType === 'checkbox') {
        const isChecked = this.$refs.xTable.isCheckedByCheckboxRow(row);
        this.$refs.xTable.toggleCheckboxRow(row);
        if (isChecked) {
          const list = [];
          this.selection.forEach((v, k) => {
            if (v === row[idKey]) {
              this.selectRow.splice(k, 1);
            } else {
              list.push(v);
            }
          });
          this.selection = list;
        } else {
          this.selectRow.push(row);
          this.selection.push(row[idKey]);
        }
      } else if (this.checkType === 'radio') {
        const isChecked = this.$refs.xTable.isCheckedByRadioRow(row);
        this.$refs.xTable.clearRadioRow();
        if (isChecked) {
          this.selectRow = [];
          this.selection = [];
        } else {
          this.$refs.xTable.setRadioRow(row);
          this.selectRow = [row];
          this.selection = [row[idKey]];
        }
      }
      this.afterClickCheck();
    },
    // 勾选回调
    afterClickCheck() {},
    // 获取相同的tableData
    setSelectData() {
      const { idKey } = this.configs.tableConfig;
      const list = [];
      this.selectRow.forEach((v) => {
        this.tableData.forEach((a) => {
          if (a[idKey] === v[idKey]) {
            list.push(a);
          }
          if (a.children && a.children.length > 0) {
            a.children.forEach((b) => {
              if (b[idKey] === v[idKey]) {
                list.push(b);
              }
            });
          }
        });
      });
      if (this.$refs.xTable) {
        if (this.checkType === 'checkbox') {
          this.$refs.xTable.setCheckboxRow(list, true);
        } else {
          this.$refs.xTable.setRadioRow(list[0], true);
        }
      }
    },
    // 单元格查看点击
    cellClick({ row, column }) {
      this.clickCheck({ row, column });
      const config = this.cellClickConfig;
      if (column.type === 'click' && column.className === 'formatter-view') {
        const val = {
          code: 'click',
        };
        this.modalClick({ val, row }, []);
      } else if (config && column.className === 'formatter-view') {
        for (const key of Object.keys(this.modalFormData)) {
          delete this.modalFormData[key];
        }
        const val = {
          code: 'view',
          buttonCode: 'view',
          name: '查看',
          buttonName: '查看',
          doCode: config.functionCode,
          queryUrl: config.queryUrl,
          buttonOperationType: 3,
          params: { id: row.id },
          parentCode: this.$store.state.menus.selectMenu,
        };
        const Tools = new TableTools(val);
        this.formConfig = this.formConfig ? { ...this.formConfig, code: 'view' } : { code: 'view' };
        this.modalConfig = this.modalConfig
          ? { ...this.modalConfig, title: '查看' }
          : { title: '查看' };
        Tools.triggerClick(row, (rule, detail) => {
          if (detail) {
            Object.assign(this.modalFormData, this.formatData({ ...detail }));
          }
          this.modalClick(
            {
              val,
              row:
                Reflect.ownKeys(this.modalFormData).includes('id') && this.modalFormData.id
                  ? this.modalFormData
                  : row,
            },
            rule,
          );
        });
      } else if (column.className === 'formatter-view') {
        const val = {
          code: 'view',
        };
        this.formConfig = this.formConfig ? { ...this.formConfig, code: 'view' } : { code: 'view' };
        this.modalConfig = this.modalConfig
          ? { ...this.modalConfig, title: '查看' }
          : { title: '查看' };
        this.modalClick({ val, row }, []);
      }
    },
    beforeSubmit(data) {
      return data;
    },
    async onSubmit(formData) {
      if (!formData) return false;
      if (this.modalConfig.config) {
        const Tools = new TableTools(this.modalConfig.config);
        const res = await Tools.handleOk(this.beforeSubmit({ ...this.modalFormData, ...formData }));
        if (res.success) {
          this.$message.success('操作成功');
          this.closeModal();
          this.getList();
          this.afterSubmit({ ...this.modalFormData, ...formData });
        }
      } else {
        this.closeModal();
      }
    },
    afterSubmit() {},
    // 异步加载子节点
    async loadChildrenMethod({ row }) {
      if (!this.loadChildBefore({ row })) return false;
      const { formData } = this;
      const params = {
        ...this.loadChildBefore({ row }),
        ...formData,
      };

      const res = await request.post(this.requestUrl, { ...params });
      return res.result.data || res.result || [];
    },
    // 设置表格复选框状态
    checkBoxMethod() {
      return true;
    },
    // 异步加载前置
    loadChildBefore() {
      return true;
    },
    // 弹窗确定事件
    onModalOk() {},

    /**
     * 设置列
     * @param col
     */
    setColumn(col) {
      return { ...col };
    },
    /**
     * 设置表单字段
     * @param field
     */
    setFormField(field) {
      return { ...field };
    },
    /**
     * 设置工具栏按钮
     * @param btn
     */
    setToolsBtn(btn) {
      return { ...btn };
    },
    /**
     * 设置数据表格行按钮
     * @param btn
     */
    setRowBtn(btn) {
      return { ...btn };
    },

    /**
     * 设置搜索条件
     * @param field
     */
    setSearchField(field) {
      return { ...field };
    },
  },
};
</script>

<style scoped lang="less">
@import url("./index.less");
// /deep/.page-pageination{
//   position: absolute;
//   right: 20px;
//   bottom: 20px;
// }
/deep/ .el-scrollbar__view .tree-scrollr- {
  height: 100%;
}
/deep/ .vxe-form--item-content .el-input {
  display: inline-block !important;
}

/deep/ .page-pageination {
  .el-input {
    display: inline-block !important;
  }
}

/deep/ .formatter-image {
  .vxe-cell,
  .vxe-cell--html {
    height: 160px !important;
    max-height: 160px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 120px;
      height: 120px;
      object-fit: contain;
    }
  }
}
/deep/ .formatter-image-mall {
  .vxe-cell,
  .vxe-cell--html {
    height: 80px !important;
    max-height: 80px !important;
    display: flex;
    // justify-content: center;
    align-items: center;

    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }
}

/deep/ .formatter-view {
  color: #409eff;
  cursor: pointer;
}

a {
  cursor: pointer;
}

/deep/ .vxe-form--item-trigger-node {
  min-width: 60px;
}

/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #ffffff;
}

/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: rgba(221, 222, 224, 0.3);
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(221, 222, 224, 0.8);
}

.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: rgba(221, 222, 224, 0.8);
}

/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
</style>
