export default {
  namespace: 'config',
  namespaced: true,
  state: {
    selectMenu: {
      name: 11,
    },
  },
  mutations: {
    SET_SELECTEDMENU(state, payload) {
      state.selectMenu = payload;
    },

  },
  actions: {

  },
  modules: {},
};
