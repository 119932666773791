import FormHeader from './form_header.vue';
import FormButton from './form_button.vue';
import FormTable from './form_table.vue';
import FormSlotTable from './form_table';
import FormEdit from './form_edit.vue';
import FormSelectTree from './form_select_tree.vue';
import FormUpload from './form_upload.vue';
import FormViewText from './form_view_text';
import FormSelect from './form_select';
import FormCheckbox from './form_checkbox';
import FormPassword from './form_password.vue';
import FormSelectTreeNew from './form_select_tree_new.vue';

export {
  FormHeader,
  FormButton,
  FormTable,
  FormEdit,
  FormSelectTree,
  FormUpload,
  FormViewText,
  FormSelect,
  FormSlotTable,
  FormCheckbox,
  FormPassword,
  FormSelectTreeNew,
};
