<template>
  <App />
</template>

<script>
import App from '@/found/App.vue';

export default {
  components: {
    App,
  },
};
</script>

<style lang="less">
.form_drawer { // dms
  #el-drawer__title > span {
    font-size: 20px;
  }
}

</style>
