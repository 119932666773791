export default {
  namespaced: true,
  state: {
    cacheData: {},
  },

  mutations: {
    SET_CACHE_DATA(state, payload) {
      state.cacheData = payload;
    },
  },
  actions: {
    set_cache_data({ commit }, payload) {
      commit('SET_CACHE_DATA', payload);
    },
  },
};
