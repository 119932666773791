<template>
  <div>
    <Modal :modalConfig="modalConfig" v-if="propsObj.visible" @onClose="dialog_btnClose" @cancel="dialog_btnClose">
      <el-upload
        v-if="!propsObj.isExport && propsObj.code !== 'view_prograss'"
        class="upload-demo"
        drag
        action="1"
        :headers="{
            loginUserToken: Storage.l.get('TokenKey')
        }"
        show-file-list
        :on-exceed="handleExceed"
        :before-upload="beforeUpload"
        :on-preview="handlePreview"
        :on-change="handleChange"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :on-success="handleSuccess"
        :limit="40"
        accept=".xls, .xlsx"
        :file-list="fileList"
        :auto-upload="false"
        multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过40M</div>
      </el-upload>
      <div class="flex" v-if="!propsObj.isExport && fileList.length">
        <el-upload
          class="upload-demo"
          action="1"
          disabled
          :auto-upload="false"
          :file-list="fileList">
        </el-upload>
        <el-button type="primary" @click="download_originfile('success')"><i class="el-icon-download"></i>下载原文件</el-button>
      </div>
      <div v-html="propsObj.exportMessage"></div>
      <div class="dialog-footer">
        <el-button type="primary" :loading="uploadLoading" @click="startUpload(false)" v-if="!propsObj.isExport && propsObj.code !== 'view_prograss'" size="small">开始上传 </el-button>
        <el-button type="primary" @click="startUpload(true)" v-if="!propsObj.isExport && propsObj.code !== 'view_prograss'" size="small">测试 </el-button>
        <el-button type="primary" @click="download_originfile('fail')" v-if="!propsObj.isExport && propsObj.code === 'view_prograss' && propsObj.row.filePathAsImportFail" size="small">下载失败文件 </el-button>
        <el-button type="primary" @click="download_modal" v-if="!propsObj.isExport && propsObj.code !== 'view_prograss'" size="small">下载模板 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import ReconnectingWebSocket from 'reconnecting-websocket';
import TablePage from '../table_page';
import request, { portUrl, websocketUrl } from '../../utils/request';
import Modal from '../modal';
import Storage from '../../utils/storage';

export default {
  name: 'ExportModal',
  extends: TablePage,
  props: {
    propsObj: Object,
  },
  components: {
    Modal,
  },
  data() {
    return {
      Storage,
      modalConfig: {
        title: '导出文件',
        visible: true,
        type: 'Modal',
        width: '50%',
      },
      fileList: [],
      tableList: [],
      isUpload: false,
      files: [],
      uploadLoading: false,
    };
  },
  created() {
    this.createWebsocket();
    if (this.propsObj && Object.keys(this.propsObj).length > 0 && !this.propsObj.isExport) {
      this.modalConfig.title = '导入文件';
      const arr = [];
      if (this.propsObj.row) {
        arr.push({
          name: this.propsObj.row.fileName,
          url: this.propsObj.row.filePath,
        });
      }
      this.fileList = arr;
    }
    if (this.propsObj.code !== 'download' && this.propsObj.code !== 'view_prograss' && this.propsObj.isExport) {
      this.openDownload();
    }
  },
  watch: {
    propsObj: {
      deep: true,
      handler(val) {
        this.createWebsocket();
        if (this.propsObj && Object.keys(this.propsObj).length > 0 && !this.propsObj.isExport) {
          this.modalConfig.title = '导入文件';
          this.fileList = this.propsObj.row.filePath;
        }
        // if (this.propsObj.code !== 'download' && this.propsObj.code !== 'view_prograss' && this.propsObj.isExport) {
        //   this.openDownload();
        // }
      },
    },
  },
  methods: {
    /** @desc 按钮关闭事件 */
    dialog_btnClose() {
      this.propsObj.visible = false;
      this.$parent.modalName = null;
      this.closeWebSocket();
      this.$forceUpdate();
    },
    // 下载模板
    download_modal() {
      const a = document.createElement('a');
      a.href = `/excel/excelImportController/demoFile?configCode=${this.propsObj.configCode}&token=${Storage.l.get('TokenKey')}`;
      document.body.appendChild(a);
      a.click(); // 下载
      URL.revokeObjectURL(a.href); // 释放URL 对象
      document.body.removeChild(a); // 删除 a 标签
    },
    // 开始上传
    startUpload(type) {
      console.log('开始上传啦');
      const formData = new FormData();
      this.files.map((v) => {
        const item = v;
        formData.append('file', item);
        return item;
      });
      const urlParam = `?configCode=${this.propsObj.configCode}&webSocketClientId=${this.sid}&test=${type}`;
      this.uploadLoading = true;
      request.postform(`/excel/excelImportController/import${urlParam}`, formData).then((res) => {
        this.uploadLoading = false;
        if (res.success) {
          // this.$message.success('上传成功');
          // this.$parent.modalName = null;
          // this.closeWebSocket();
          // this.propsObj.visible = false;
          // this.$parent.getList();
        }
      }).catch((err) => {
        console.log(err);
        console.log('err');
        this.uploadLoading = false;
      });
    },
    // 下载原文件或失败文件
    download_originfile(type) {
      const objectName = (type === 'success') ? this.propsObj.row.objectName : this.propsObj.row.objectNameAsImportFail;
      const a = document.createElement('a');
      a.href = `/upload/downloadController/downloadExcelFile?objectName=${objectName}`;
      document.body.appendChild(a);
      a.click(); // 下载
      URL.revokeObjectURL(a.href); // 释放URL 对象
      document.body.removeChild(a); // 删除 a 标签
    },
    beforeUpload(files) {
      const { name, size } = files;
      let fileType = name.split('.')[name.split('.').length - 1];
      fileType = fileType.toLowerCase();
      const isLt40M = (size / 1024 / 1024) <= 40;
      if (this.accept && this.accept.indexOf(fileType) === -1) {
        this.isUpload = true;
        this.$message.warning(
          `当前限制选择 ${
            this.accept
          } 类型件，本次选择了错误文件`,
        );
        return false;
      }
      if (!isLt40M) {
        this.loading = false;
        this.$message.error('上传表格大小不超过40MB');
        this.$refs.upload.clearFiles();
        return false;
      }
      return true;
    },
    handleChange(file, fileList) {
      this.files = [];
      fileList.map((v) => {
        const item = v;
        this.files.push(item.raw);
        return item;
      });
    },
    handleRemove(file) {
      this.tableList.forEach((v, k) => {
        if (v.url === file.url) {
          this.tableList.splice(k, 1);
        }
      });
      this.tableList = this.tableList.concat([]);
      this.value.fileList = this.tableList;
      this.$emit('onGetFileList', this.tableList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleSuccess(res) {
      this.isUpload = false;
      if (res.code === 200 && res.success) {
        // debugger;
        this.$message.success(res.message);
        res.result.forEach((v) => {
          this.tableList.push({
            fileName: v.fileName,
            url: v.url,
            urlPath: v.urlPath,
            urlPathPrefix: v.urlPathPrefix,
            objectName: v.objectName,
          });
          this.tableList = this.tableList.concat([]);
          this.value.fileList = this.tableList;
          this.$emit('onGetFileList', this.tableList);
        });
      }
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
    },
    beforeRemove(file) {
      if (!this.isUpload) {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
      this.isUpload = false;

      return true;
    },
    openDownload() {
      const param = {
        // sid: this.sid,
        sid: this.propsObj.sid,
        requestUrl: this.propsObj.requestUrl,
        parentCode: this.$store.state.menus.selectMenu,
        ids: this.propsObj.ids,
        ...this.propsObj.formData,
        functionCode: this.propsObj.formData.functionCode || this.propsObj.functionCode,
      };
      request.post('/excel/excelController/repeatedWriteExport', param).then(() => {
        // if (res.success) {

        // }
      });
    },
    // 创建 websocket 链接
    createWebsocket() {
      if (typeof (WebSocket) === 'undefined') {
        this.$message.error('您的浏览器不支持socket');
      } else {
        console.log('浏览器支持socket');
      }
      // const sid = sessionStorage.getItem('sid');
      const { sid } = this.propsObj;
      this.sid = sid;
      const token = JSON.parse(localStorage.getItem('userInfo')).loginUserToken;
      console.log(`${websocketUrl}/excel/websocket/user?sid=${sid}&&token=${token}`);
      // 根据业务类型 wsUrl 拼接

      this.websocket = new ReconnectingWebSocket(
        `${websocketUrl}/excel/websocket/user?sid=${sid}&&token=${token}`,
      );
      // 连接发生错误的回调方法
      this.websocket.onerror = this.websocketOnerror;
      // 连接成功建立的回调方法
      this.websocket.onopen = this.websocketOnopen;
      // 接收到消息的回调方法
      this.websocket.onmessage = (event) => {
        this.websocketOnmessage(event, sid);
      };
      // 连接关闭的回调方法
      this.websocket.onclose = this.websocketOnclose;
      // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
      this.websocket.onbeforeunload = this.websocketOnbeforeunload;
    },
    // 连接发生错误的回调方法
    websocketOnerror() {
      console.log('连接发生错误的回调方法');
    },
    // 连接成功建立的回调方法
    websocketOnopen() {
      console.log('连接成功建立的回调方法');
    },
    // 接收到消息的回调方法
    websocketOnmessage(event) {
      console.log(event);
      const data = JSON.parse(event.data);
      // console.log('接收到消息的回调方法', data);
      this.propsObj.exportMessage += `${data.message}<br/>`;
      // 滑动条移动到最底部
      this.$nextTick(() => {
        const div = document.getElementsByClassName('modal-scrollbar')[0];
        div.scrollTop = div.scrollHeight;
      });
    },
    // 连接关闭的回调方法
    websocketOnclose() {
      console.log('连接关闭的回调方法');
    },
    // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常
    websocketOnbeforeunload() {
      this.closeWebSocket();
      console.log(
        '监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常',
      );
    },
    // 关闭WebSocket连接
    closeWebSocket() {
      console.log('关闭');
      this.websocket.close();
    },
  },
};
</script>
<style lang="less" scoped>
  .dialog-footer {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 99;
    background-color: white;
  }
  /deep/ .el-upload-dragger, /deep/ .el-upload{
    width: 98%;
  }
  .flex{
    display: flex;
    align-items: flex-end;
    .upload-demo{
      flex: 1;
      margin-right: 20px;
    }
  }
</style>
