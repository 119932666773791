<template>
  <component :is="modalConfig.type" @onClose="closeModal" @onOk="onOk" :modalConfig="modalConfig">
    <slot></slot>
  </component>
</template>

<script>
import {
  Modal, Drawer, Full, EmptyDrawer,
} from './components';

export default {
  components: {
    Modal,
    Drawer,
    Full,
    EmptyDrawer,
  },
  props: {
    modalConfig: {
      type: Object,
      default: () => ({
        type: 'Modal',
        title: '标题',
        visible: false,
        width: '50%',
      }),
    },
  },
  watch: {
    $route() {
      this.$emit('onClose');
    },
  },
  methods: {
    closeModal() {
      this.$emit('onClose');
    },
    onOk() {
      this.$emit('onOk');
    },
  },
};
</script>

<style></style>
