/**
 * 精度计算
 * @param num1 {number}
 * @param num2 {number}
 * @param type {string}  调用方法 multiply:乘 add:加  subtract:减 divide:除
 * */
function precise(num1, num2, type) {
  let p1 = 0;
  let p2 = 0;

  if (num1.toString().split('.').length > 1) {
    p1 = num1.toString().split('.')[1].length;
  }

  if (num2.toString().split('.').length > 1) {
    p2 = num2.toString().split('.')[1].length;
  }

  const p = p1 > p2 ? p1 : p2;

  const pow = 10 ** p;
  const n1 = num1 * pow;
  const n2 = num2 * pow;
  let result;
  console.log(n1, n2, pow);
  switch (type) {
    case 'multiply':
      result = (n1 * n2) / (pow ** 2);
      break;
    case 'add':
      result = (n1 + n2) / pow;
      break;
    case 'subtract':
      result = (n1 - n2) / pow;
      break;
    default:
      result = (n1 / n2) / (pow ** 2);
      break;
  }

  return result;
}
const precisePlugin = {};
precisePlugin.install = function (Vue) {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$precise = precise;
};
export default precisePlugin;
