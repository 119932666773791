export default [
  {
    path: '/scheduler',
    name: 'scheduler',
    meta: {
      title: 'scheduler',
    },
    redirect: '/scheduler/manage',
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'manage',
        name: 'scheduler_manage',
        component: () => import(/* webpackChunkName: "scheduler/manage" */ '../views/scheduler_manage'),
        meta: { title: '任务管理' },
      },
      {
        path: 'log',
        name: 'scheduler_log',
        component: () => import(/* webpackChunkName: "scheduler/manage" */ '../views/scheduler_log'),
        meta: { title: '调度日志' },
      },

    ],
  },
];
