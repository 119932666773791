var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.modalConfig.title,
        visible: _vm.modalConfig.visible,
        width: _vm.modalConfig.width || "600px",
        destroyOnClose: true,
        footer: null,
        maskClosable: false,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "el-scrollbar",
        {
          staticClass: "dialog-style",
          attrs: {
            "wrap-class": "drawer-scrollbar",
            "view-class": "drawer-container",
            tag: "div",
          },
        },
        [
          _vm._t("default"),
          _vm.modalConfig.showFooter
            ? _c("div", { staticClass: "dialog-footer" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-check", type: "primary" },
                        on: { click: _vm.onOk },
                      },
                      [_vm._v("确定")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { icon: "el-icon-close", type: "danger" },
                        on: { click: _vm.close },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }