/**
 * create by: yangjunxiao
 * description: TODO
 * create time: 2020/12/7 4:07 下午
 */

import { Message, MessageBox } from 'element-ui';
import request from '../utils/request';
import FormCofing from './formCofing';

// 不需要doCode的弹窗
const noDoCode = [
  'process_log',
];

export default class TableTools {
  constructor(btn) {
    console.log(btn);
    this.btn = btn;
    this.types = [
      'simple', 'ask', 'modal',
    ];
  }

  /**
   *
   * @param params 选中数据或者当前行数据
   * @returns {*}
   */
  triggerClick(params, next, refresh) {
    if (!params) throw new Error('缺少选中数据');
    let rows = params;
    if (rows instanceof Array) {
      console.log(rows);
    } else {
      rows = [rows];
    }
    const fn = this.getType().bind(this);
    const requestParams = this.validate(rows);
    if (!requestParams) return;
    fn(requestParams, next, refresh);
  }

  getType() {
    if (['process_log'].includes(this.btn.code)) {
      return this.modal;
    }
    return this[this.types[Number(this.btn.buttonOperationType) - 1]];
  }

  validate(rows) {
    if (rows.length < 1 && Number(this.btn.buttonType) === 0 && this.btn.buttonCode.search('add') < 0 && this.btn.buttonCode.search('resetCode') < 0 && this.btn.buttonCode.search('reduction_coding') < 0) {
      window.App.$message({
        type: 'info',
        message: '最少选择一条数据操作',
      });
      return false;
    }
    let params = rows.map((r) => r.id);
    if (['submit_approval', 'edit', 'change', 'adjustment'].includes(this.btn.buttonCode)) {
      params = { id: rows.map((r) => r.id)[0] };
    }
    return params;
  }

  simple(params, next, refresh) {
    if (next) {
      return request.post(this.btn.apiUrl, params).then((res) => {
        if (res.success) {
          Message({
            type: 'success',
            message: `${this.btn.name}成功`,
          });
          refresh();
          next(false);
        } else {
          Message({
            type: 'error',
            message: res.message,
          });
        }
        return res;
      });
    }
  }

  ask(params, next, refresh) {
    return new Promise((resolve) => {
      MessageBox.confirm(this.btn.ask, '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        if (this.btn.apiUrl) {
          resolve(request.post(this.btn.apiUrl, params).then((res) => {
            if (res.success) {
              Message({
                type: 'success',
                message: `${this.btn.name}成功`,
              });
              next(false);
            } else {
              Message({
                type: 'error',
                message: res.message,
              });
            }
            if (refresh && typeof refresh === 'function') refresh();
            return res;
          }));
        } else {
          next();
          // {message: 'local_operation', data: params}
        }
      }).catch(() => {
        this({
          type: 'info',
          message: `已取消${this.btn.name}`,
        });
      });
    });
  }

  async modal(params, next) {
    if (this.btn.doCode) {
      const formFn = new FormCofing(this.btn.doCode, this.btn.parentCode);
      let detail = null;
      // 如果不是新增按钮 并且queryUrl存在时查询详情
      if (this.btn.buttonCode.search('add') < 0 && this.btn.queryUrl) {
        const res = await request[this.btn.method ? this.btn.method : 'get'](this.btn.queryUrl, this.btn.params ? this.btn.params : params, {
          headers: {
            functionCode: this.btn.doCode,
          },
        });
        detail = res.result;
      }
      const rule = await formFn.getFormRule(detail);
      // if (detail) {
      //   Object.keys(detail).forEach((key) => {
      //     if (rule.findIndex((i) => i.field === key) < 0) {
      //       rule.push({
      //         type: 'hidden',
      //         value: detail[key],
      //       });
      //     }
      //   });
      // }
      next(rule, detail);
    } else if (noDoCode.includes(this.btn.code)) {
      next([
        {
          type: 'customProcessLog', field: 'customProcessLog', props: {}, col: {},
        },
      ], null);
    } else {
      next();
    }
  }

  handleOk(formData) {
    // console.log(this.btn.id);
    // const ids = this.btn.id ? this.btn.id : '';
    const formDatas = {
      ...formData,
      // id: ids,
    };
    if (formDatas) return request.post(this.btn.apiUrl, formDatas);
    return false;
  }
}
