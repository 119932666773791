<template>
  <vxe-table
    border
    show-overflow
    show-footer
    keep-source
    highlight-hover-row
    highlight-current-row
    ref="xTable"
    :data="value"
    class="my-xtable-element"
    :edit-config="{ trigger: 'click', mode: 'row', icon: 'el-icon-edit' }"
    :footerMethod="footerMethod || null"
    :footerColspanMethod="footerColspanMethod || null"
  >
    <template v-for="(item, index) in tableColumn">
      <FormTableColumn :tableColumn="item" :key="index" />
    </template>
  </vxe-table>
</template>

<script>
import FormTableColumn from './form_table_column.vue';

export default {
  props: {
    value: Array,
    tableColumn: Array,
    footerMethod: Function,
    footerColspanMethod: Function,
  },
  components: {
    FormTableColumn,
  },
};
</script>

<style></style>
