/*
 * @Author: yzr
 * @Date: 2020-11-23 15:13:14
 * @LastEditors: Nehc
 * @LastEditTime: 2020-12-25 11:18:59
 */
export default [
  {
    path: '/fee_manage',
    name: 'fee',
    meta: {
      title: 'FEE',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: '/fee_manage/record_manage',
        name: 'fee_record_manage',
        meta: {
          title: '对账管理',
        },
        redirect: '/fee_manage/record_manage/discount_cost_pool',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'discount_manage_detail',
            name: 'fee_discount_manage_detail',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_manage_detail" */ '../views/record_manage/discount_manage/form/components/condition_table.vue'),
            meta: { title: '费用池明细' },
          },
          {
            path: 'discount_cost_pool',
            name: 'fee_discount_cost_pool',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_cost_pool" */ '../views/record_manage/discount_cost_pool'),
            meta: { title: '折扣费用池' },
          },
          {
            path: 'discount_goods',
            name: 'fee_discount_goods',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_goods" */ '../views/record_manage/discount_goods'),
            meta: { title: '货补费用池' },
          },
          {
            path: 'discount_cost_pool_detail',
            name: 'fee_discount_cost_pool_detail',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_cost_pool_detail" */ '../views/record_manage/discount_cost_pool/form/components/condition_table.vue'),
            meta: { title: '折扣费用池上账明细' },
          },
          {
            path: 'discount_goods_detail',
            name: 'fee_discount_goods_detail',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_goods_detail" */ '../views/record_manage/discount_goods/form/components/condition_table.vue'),
            meta: { title: '货补费用池上账明细' },
          },
          {
            path: 'discount_cost_pool_operation',
            name: 'fee_discount_cost_pool_operation',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_cost_pool_operation" */ '../views/record_manage/discount_cost_pool/form/components/condition_detail_table.vue'),
            meta: { title: '折扣费用池操作记录' },
          },
          {
            path: 'discount_goods_operation',
            name: 'fee_discount_goods_operation',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_goods_operation" */ '../views/record_manage/discount_goods/form/components/condition_detail_table.vue'),
            meta: { title: '货补费用池操作记录' },
          },
          {
            path: 'discount_cost_pool_examine',
            name: 'fee_discount_cost_pool_examine',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_cost_pool_examine" */ '../views/record_manage/discount_cost_pool_examine'),
            meta: { title: '产品折扣调整审批' },
          },
          {
            path: 'discount_goods_examine',
            name: 'fee_discount_goods_examine',
            component: () => import(/* webpackChunkName: "record_manage/fee_discount_goods_examine" */ '../views/record_manage/discount_goods_examine'),
            meta: { title: '产品折扣调整审批' },
          },
        ],
      },
      {
        path: '/fee_manage/new_fee_center',
        name: 'new_fee',
        meta: {
          title: '费用中心',
        },
        redirect: '/fee_manage/new_fee_center/fill_goods_cost',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'fill_goods_cost_details',
            name: 'fill_goods_cost_details',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/fill_goods_cost_details'),
            meta: { title: '货补费用明细使用表' },
          },
          {
            path: 'discount_fee_details',
            name: 'discount_fee_details',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/discount_fee_details'),
            meta: { title: '折扣费用明细使用表' },
          },
          {
            path: 'cost_details_use',
            name: 'cost_details_use',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/cost_details_use'),
            meta: { title: '费用明细使用表' },
          },
          {
            path: 'fill_goods_cost_use',
            name: 'fill_goods_cost_use',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/fill_goods_cost_use'),
            meta: { title: '货补费用使用表' },
          },
          {
            path: 'discount_fee_use',
            name: 'discount_fee_use',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/discount_fee_use'),
            meta: { title: '折扣费用使用表' },
          },
          {
            path: 'cost_use',
            name: 'cost_use',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/cost_use'),
            meta: { title: '费用使用表' },
          },
          {
            path: 'fill_goods_fee_detail',
            name: 'fill_goods_fee_detail',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/fill_goods_fee_detail'),
            meta: { title: '货补费用明细' },
          },
          {
            path: 'discount_fee_details_account',
            name: 'discount_fee_details_account',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/discount_fee_details_account'),
            meta: { title: '折扣费用明细' },
          },
          {
            path: 'cost_detail_account',
            name: 'cost_detail_account',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/cost_detail_account'),
            meta: { title: '费用明细' },
          },
          {
            path: 'fill_goods_fee_manage',
            name: 'fill_goods_fee_manage',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/fill_goods_fee_manage'),
            meta: { title: '货补费用管理' },
          },
          {
            path: 'discount_fee_manage',
            name: 'discount_fee_manage',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/discount_fee_manage'),
            meta: { title: '折扣费用管理' },
          },
          {
            path: 'cost_manage',
            name: 'cost_manage',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/new_fee/cost_manage'),
            meta: { title: '费用管理' },
          },
        ],
      },
      {
        path: '/fee_manage/standby_fee',
        name: 'standby_fee',
        meta: {
          title: '费用中心备用',
        },
        redirect: '/fee_manage/standby_fee/fee_pool_page_1',
        component: { render: (e) => e('router-view') },
        children: [
          {
            path: 'fee_pool_page_1',
            name: 'fee_pool_page_1',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_page_1'),
            meta: { title: '费用管理' },
          },
          {
            path: 'fee_pool_page_2',
            name: 'fee_pool_page_2',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_page_2'),
            meta: { title: '折扣费用管理' },
          },
          {
            path: 'fee_pool_page_3',
            name: 'fee_pool_page_3',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_page_3'),
            meta: { title: '货补费用管理' },
          },
          {
            path: 'fee_pool_detail_page_1',
            name: 'fee_pool_detail_page_1',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_detail_page_1'),
            meta: { title: '费用明细' },
          },
          {
            path: 'fee_pool_detail_page_2',
            name: 'fee_pool_detail_page_2',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_detail_page_2'),
            meta: { title: '折扣费用明细' },
          },
          {
            path: 'fee_pool_detail_page_3',
            name: 'fee_pool_detail_page_3',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_detail_page_3'),
            meta: { title: '货补费用明细' },
          },
          {
            path: 'fee_pool_operation_page_1',
            name: 'fee_pool_operation_page_1',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_operation_page_1'),
            meta: { title: '费用使用表' },
          },
          {
            path: 'fee_pool_operation_page_2',
            name: 'fee_pool_operation_page_2',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_operation_page_2'),
            meta: { title: '折扣费用使用表' },
          },
          {
            path: 'fee_pool_operation_page_3',
            name: 'fee_pool_operation_page_3',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_operation_page_3'),
            meta: { title: '货补费用使用表' },
          },
          {
            path: 'fee_pool_detail_log_page_1',
            name: 'fee_pool_detail_log_page_1',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_detail_log_page_1'),
            meta: { title: '费用明细使用表' },
          },
          {
            path: 'fee_pool_detail_log_page_2',
            name: 'fee_pool_detail_log_page_2',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_detail_log_page_2'),
            meta: { title: '折扣费用明细使用表' },
          },
          {
            path: 'fee_pool_detail_log_page_3',
            name: 'fee_pool_detail_log_page_3',
            component: () => import(/* webpackChunkName: "record_manage/new_fee_center" */ '../views/standby_fee/fee_pool_detail_log_page_3'),
            meta: { title: '货补费用明细使用表' },
          },
        ],
      },
    ],
  },
];
