const subRouters = [
  {
    path: '/tpm_center/budget_jiulei',
    name: 'budget_jiulei',
    meta: {
      title: '参数中心',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'fiscal_year_set',
        name: 'fiscal_year_set',
        component: () => import(
          /* webpackChunkName: "parameter_center/fiscal_year_set" */ '../views/parameter_center/fiscal_year_set'
        ),
        meta: { title: '财年设置' },
      },
      {
        path: 'cost_subject',
        name: 'cost_subject',
        component: () => import(
          /* webpackChunkName: "parameter_center/cost_subject" */ '../views/parameter_center/cost_subject'
        ),
        meta: { title: '费用科目' },
      },
      {
        path: 'finance_subject',
        name: 'finance_subject',
        component: () => import(
          /* webpackChunkName: "parameter_center/finance_subject" */ '../views/parameter_center/finance_subject'
        ),
        meta: { title: '财务科目' },
      },
      {
        path: 'investment_type',
        name: 'investment_type',
        component: () => import(
          /* webpackChunkName: "parameter_center/investment_type" */ '../views/parameter_center/investment_type'
        ),
        meta: { title: '投入类型' },
      },
      {
        path: 'activity_type',
        name: 'activity_type',
        component: () => import(
          /* webpackChunkName: "parameter_center/activity_type" */ '../views/parameter_center/activity_type'
        ),
        meta: { title: '活动类型' },
      },
      {
        path: 'act_collect_field',
        name: 'act_collect_field',
        component: () => import(
          /* webpackChunkName: "parameter_center/act_collect_field" */ '../views/parameter_center/act_collect_field'
        ),
        meta: { title: '活动采集字段' },
      },
      {
        path: 'audit_collect',
        name: 'audit_collect',
        component: () => import(
          /* webpackChunkName: "parameter_center/audit_collect" */ '../views/parameter_center/audit_collect'
        ),
        meta: { title: '采集核销示例' },
      },
    ],
  },
  {
    path: '/tpm_center/budget_manage',
    name: 'budget_manage',
    meta: {
      title: '预算中心',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'cost_budget',
        name: 'cost_budget',
        component: () => import(
          /* webpackChunkName: "budget_center/cost_budget" */ '../views/budget_center/cost_budget'
        ),
        meta: { title: '费用预算' },
      },
    ],
  },
  {
    path: '/tpm_center/activity_center',
    name: 'activity_center',
    meta: {
      title: '活动中心',
    },
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'activity_manage',
        name: 'activity_manage',
        component: () => import(
          /* webpackChunkName: "activity_center/activity_manage" */ '../views/activity_center/activity_manage'
        ),
        meta: { title: '活动管理' },
      },
      {
        path: 'activity_overview',
        name: 'activity_overview',
        component: () => import(
          /* webpackChunkName: "activity_center/activity_overview" */ '../views/activity_center/activity_overview'
        ),
        meta: { title: '活动概览' },
      },
      {
        path: 'activity_write_off',
        name: 'activity_write_off',
        component: () => import(
          /* webpackChunkName: "activity_center/activity_write_off" */ '../views/activity_center/activity_write_off'
        ),
        meta: { title: '活动核销' },
      },
      {
        path: 'cost_account',
        name: 'cost_account',
        component: () => import(
          /* webpackChunkName: "activity_center/cost_account" */ '../views/activity_center/cost_account'
        ),
        meta: { title: '费用上账' },
      },
    ],
  },
];

export default subRouters;
