<template>
  <div>
    <div v-if="showView"
         v-html="value"
         class="edit-view"></div>
    <div v-else
         ref="editor"
         style="text-align:left"></div>
  </div>

</template>

<script>
import E from 'wangeditor';
import Storage from '../../../utils/storage';

export default {
  name: 'Edit',
  // model: {
  //   prop: 'content',
  //   event: 'change'
  // },
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    notView: Boolean,
    config: {
      type: Object,
      default() {
        return {
          height: 150,
        };
      },
    },
  },
  data() {
    return {
      currentValue: null,
      currentEditor: null,
      hasInit: false, // 是否初始化
    };
  },
  watch: {
    value: {
      handler(val) {
        let value = val;
        if (value === '') value = '<p><br></p>';
        this.setContent(value);
      },
      immediate: true,
    },
    currentValue(val) {
      const html = val; // xss等处理
      if (html !== this.currentEditor.txt.html()) {
        this.$nextTick(
          () => this.currentEditor
            && this.currentEditor.txt
            && this.currentEditor.txt.html(html),
        );
      }
    },
    disabled(val) {
      // 启用和禁用
      return val ? this.currentEditor.disable() : this.currentEditor.enable();
    },
  },
  computed: {
    showView() {
      return this.notView;
    },
  },
  mounted() {
    // this.currentEditor = new E(this.$refs.editor);
    // editor.customConfig = {
    //   onchange: (html) => {
    //     this.$emit('change', html);
    //     console.log(html);
    //   },
    //   uploadImgServer: '/api/UploadImg', // 上传图片到服务器
    //   uploadFileName: 'file', // 后端使用这个字段获取图片信息
    //   uploadImgMaxLength: 1, // 限制一次最多上传 1 张图片
    // };
    // editor.config.onchange = (html) => {
    //   // this.change(html)
    //   this.$emit('change', html);
    // };
    // editor.create();
  },
  methods: {
    setContent(val) {
      const setValueFunc = () => {
        if (val !== this.currentValue) {
          this.currentValue = val;
        }
      };
      // 检查编辑器是否已初始化
      if (!this.hasInit) {
        this.$nextTick(() => {
          this.renderEditor();
          setValueFunc();
        });
      } else {
        setValueFunc();
      }
    },
    asyncValue(html) {
      let value = html;
      if (value === '<p><br></p>') value = '';
      this.$emit('input', value);
    },
    renderEditor() {
      // 渲染编辑器
      if (this.hasInit) {
        return console.log('编辑器已初始化');
      }
      const editor = new E(this.$refs.editor);
      // 日志配置
      editor.config.height = 150;
      // 配置字体
      editor.config.fontNames = [
        '黑体',
        '仿宋',
        '楷体',
        '标楷体',
        '华文仿宋',
        '华文楷体',
        '宋体',
        '微软雅黑',
        'Arial',
        'Tahoma',
        'Verdana',
        'Times New Roman',
        'Courier New',
      ];

      editor.config.fontSizes = {
        'x-small': { name: '10px', value: '1' },
        small: { name: '12px', value: '2' },
        normal: { name: '16px', value: '3' },
        large: { name: '18px', value: '4' },
        'x-large': { name: '24px', value: '5' },
        'xx-large': { name: '32px', value: '6' },
        'xxx-large': { name: '48px', value: '7' },
      };
      // _this.editorConfig(editor)
      // _this.imageConfig(editor)

      // console.log(editor.config)
      editor.config.uploadImgServer = '/upload/uploadController/uploadOssList';
      editor.config.uploadFileName = 'file';
      editor.config.uploadImgHeaders = {
        loginUserToken: Storage.l.get('TokenKey'),
      };
      editor.config.uploadImgHooks = {
        customInsert(insertImgFn, result) {
          console.log(result);
          insertImgFn(result.result[0].url);
        },
      };

      editor.config.uploadVideoServer = '/upload/uploadController/uploadOssList';
      editor.config.uploadVideoName = 'file';
      editor.config.uploadVideoHeaders = {
        loginUserToken: Storage.l.get('TokenKey'),
      };
      editor.config.uploadVideoHooks = {
        customInsert(insertImgFn, result) {
          console.log(result);
          insertImgFn(result.result[0].url);
        },
      };

      editor.config = {
        ...editor.config,
        ...this.config,
      };

      this.hasInit = true;
      editor.config.onchange = (html) => {
        this.asyncValue(html);
      };
      editor.create();
      // 启用和禁用
      if (this.disabled) {
        editor.disable();
      } else {
        editor.enable();
      }
      this.currentEditor = editor;
      return null;
    },
  },
};
</script>

<style lang="less" scoped>
.edit-view {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 15px;
}
/deep/ font[size="1"] {
    font-size: 10px;
}
/deep/ font[size="2"] {
    font-size: 12px;
}
/deep/ font[size="3"] {
    font-size: 16px;
}
/deep/ font[size="4"] {
    font-size: 18px;
}
/deep/ font[size="5"] {
    font-size: 24px;
}
/deep/ font[size="6"] {
    font-size: 32px;
}
/deep/ font[size="7"] {
    font-size: 48px;
}
</style>
