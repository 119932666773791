var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tableColumn.children && _vm.tableColumn.children.length
    ? _c(
        "vxe-table-colgroup",
        { attrs: { title: _vm.tableColumn.title, align: "center" } },
        _vm._l(_vm.tableColumn.children, function (item, index) {
          return _c("FormTableColumn", {
            key: index,
            attrs: { tableColumn: item },
          })
        }),
        1
      )
    : _c(
        "vxe-table-column",
        _vm._b({}, "vxe-table-column", _vm.tableColumn, false)
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }