// 获取配置初始化
const Init = {
  // 初始化请求的form配置文件
  initConfig(data, detail) {
    const validateType = {
      select: 'change',
      multi_select: 'change',
      input: 'blur',
      inputNumber: 'blur',
      textarea: 'blur',
      tree: 'change',
      FormEdit: 'blur',
      FormSelectTree: 'change',
      cascader: 'change',
      DatePicker: 'change',
      TimePicker: 'change',
      FormSelectTreeNew: 'change',
      FormSelectTreeNewMultiple: 'change',
    };
    const tempRule = [];
    data.forEach((item) => {
      const v = {
        type: item.type,
        field: item.field,
        title: item.title,
        value: (detail && detail[item.field]) || item.formvalue,
        dictCode: item.dictCode,
        emitPrefix: item.field,
        editableInCreate: item.editableInCreate,
        editableInEdit: item.editableInEdit,
        visibleInEdit: item.visibleInEdit,
        visibleInLook: item.visibleInLook,
        props: {
          clearable: true,
        },
        validate: [
          {
            trigger: validateType[item.type],
            required: false,
            message: `${item.title}不能为空`,
          },
        ],
      };

      if (item.type === 'multi_select') {
        v.type = 'select';
        if (v.value) {
          try {
            v.value = JSON.parse(v.value);
          } catch (e) {
            v.value = v.value || [];
          }
        } else {
          v.value = [];
        }

        v.props = {
          ...v.props,
          multiple: true,
          loading: false,
        };
      }
      if (item.type === 'FormSelectTreeNewMultiple') {
        v.type = 'FormSelectTreeNew';
        v.props = {
          ...v.props,
          multiple: true,
        };
      }
      if (item.type === 'select') {
        v.props = {
          ...v.props,
          loading: false,
        };
        v.on = {
          ...v.on,
          getLabel() {},
          optionCallback() {},
        };
      }
      if (item.type === 'FormSelectTree') {
        v.props = {
          ...v.props,
          loading: false,
        };
      }
      if (item.type === 'textarea') {
        v.type = 'input';
        v.props = {
          ...v.props,
          type: 'textarea',
          autosize: true,
        };
      }
      if (item.type === 'fileUpload') {
        v.type = 'FormUpload';
        v.props = {
          ...v.props,
        };
        v.value = v.value || [];
      }
      if (item.width) {
        v.col = {
          md: { span: item.width },
        };
      }
      if (item.type === 'formHeader') {
        v.title = undefined;
        v.props = {
          title: item.title,
        };
      }
      if (!item.visible) {
        v.type = 'hidden';
      }
      if (item.required) {
        v.validate = [
          {
            trigger: validateType[item.type],
            required: true,
            message: `${item.title}不能为空`,
          },
        ];
      }
      tempRule.push(v);
    });
    console.log(tempRule.map((r) => r.value), 'tempRule_____________________');
    return tempRule;
  },
};

export const isEmpty = (value) => {
  if (value == null) { // 等同于 value === undefined || value === null
    return true;
  }
  const type = Object.prototype.toString.call(value).slice(8, -1);
  switch (type) {
    case 'String':
      return !value;
    case 'Array':
      return !value.length;
    case 'Object':
      return Object.keys(value).length === 0; // 普通对象使用 for...in 判断，有 key 即为 false
    default:
      return false; // 其他对象均视作非空
  }
};

export default Init;
