import Vue from 'vue';
import _ from 'xe-utils';
import {
  VXETable,
  Table,
  Column,
  Header,
  Footer,
  Filter,
  Edit,
  Menu,
  Export,
  Keyboard,
  Validator,
  Icon,
  Grid,
  Toolbar,
  Pager,
  Checkbox,
  Radio,
  Input,
  Textarea,
  Button,
  Modal,
  Tooltip,
  Form,
  Select,
  Switch,
  List,
} from 'vxe-table';
import 'vxe-table/lib/style.css';
import 'vxe-table-plugin-element/dist/style.css';
import VXETablePluginElement from 'vxe-table-plugin-element';

// 本地化设置
import zhCN from 'vxe-table/lib/locale/lang/zh-CN';
// import enUS from 'vxe-table/lib/locale/lang/en-US';
// // 按需加载的方式默认是不带国际化的，需要自行导入

// // 引入自定义样式
// const messages = {
//   zh_CN: {
//     ...zhCN,
//   },
//   en_US: {
//     ...enUS,
//   },
// };

// const i18n = new VueI18n({
//   locale: 'zh_CN',
//   messages,
// });

// Vue.use(VXETable, {
//   // 对组件内置的提示语进行国际化翻译
//   i18n: (key, args) => i18n.t(key, args),
// });
VXETable.setup({
  i18n: (key) => _.get(zhCN, key),
  size: 'small',
  table: {
    autoResize: true,
    border: true,
  },
});

// 表格模块
Vue.use(Icon);
Vue.use(Header);
Vue.use(Footer);
Vue.use(Filter);
Vue.use(Edit);
Vue.use(Menu);
Vue.use(Export);
Vue.use(Keyboard);
Vue.use(Validator);

// 可选组件
Vue.use(Column);
Vue.use(Grid);
Vue.use(Tooltip);
Vue.use(Toolbar);
Vue.use(Pager);
Vue.use(Form);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Switch);
Vue.use(Input);
Vue.use(Select);
Vue.use(Button);
Vue.use(Modal);
Vue.use(List);
Vue.use(Textarea);

VXETable.use(VXETablePluginElement);
VXETable.formats.mixin({
  formatAmount({ cellValue }, digits = 2) {
    return _.commafy(_.toNumber(cellValue), { digits });
  },
});

// 安装表格
// Vue.use(VXETable);
Vue.use(Table);

// 给 vue 实例挂载内部对象，例如：
Vue.prototype.$XModal = VXETable.modal;
Vue.prototype.$XPrint = VXETable.print;
Vue.prototype.$XSaveFile = VXETable.saveFile;
Vue.prototype.$XReadFile = VXETable.readFile;

// export default { i18n };
