import TablePage from '../../../../../../components/table_page';

export default {
  extends: TablePage,
  props: {
    directSystemId: String,
    invoiceId: String,
    selectStore: Array,
    bsDirectSystemId: String,
  },
  data() {
    return {
      isCalculateHeight: false,
      isRowClickCheckBox: false,
      params: {
        bsDirectSystemId: '',
      },
    };
  },
  created() {
    this.getConfigList('product');
  },
  watch: {
    directSystemId(v) {
      this.getList();
    },
    invoiceId(v) {
      this.getList();
    },
    bsDirectSystemId(v) {
      this.params.bsDirectSystemId = v;
      this.getList();
    },
  },
  methods: {
    beforeGetList() {
      if (!this.bsDirectSystemId) {
        return false;
      }
      this.params.bsDirectSystemId = this.bsDirectSystemId;
      return true;
    },
    checkBoxMethod({ row }) {
      return !this.selectStore.includes(row.id);
    },
  },
};
