<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';
import GraspingRulesStores from './components/grasping_rules_stores.vue';

formCreate.component('graspingRulesStores', GraspingRulesStores);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'edit_form',
      productSelect: [],
    };
  },
  async created() {
    // this.rule = await this.getFormRule('kms_on_product_form');
    // this.getProduct();
  },
  methods: {

    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'bsDirectSystemCode') {
        item.restful = '/kms/kmstenantrydirectcustomerorg/list';
        item.headers = { functionCode: 'warehouse_store_list' };
        item.restfulParams = {
          enableStatus: '009',
        };
        item.optionsKey = { label: 'bsDirectSystemName', value: 'bsDirectSystemCode' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'bsDirectSystemName',
        };
      }
      if (item.field === 'terminalCode') {
        item.restful = '/kms/kmstenantrydirectstore/list';
        item.optionsKey = {
          label: 'terminalName',
          value: 'terminalCode',
        };
        item.refresh = true;
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'terminalName',
        };
        item.restfulParamsGetValue = {
          bsDirectSystemCode: 'bsDirectSystemCode',
        };
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      this.basisRule = this.rule;
      const directSystemId = this.getRule('bsDirectSystemCode');
      const terminalCode = this.getRule('terminalCode');
      const graspingRulesStores = this.getRule('graspingRulesStores');
      terminalCode.on.change = (e) => {
        const selectData = terminalCode.options.find((item) => item.terminalCode === e);
        this.setValue({
          directStoreId: selectData.id,
          terminalName: selectData.terminalName,
        });
      };
      directSystemId.on.change = (e) => {
        if (!e) {
          this.bsDirectSystemId = '';
          this.bsInvoiceId = '';
          this.bsDirectSystemCode = '';
          this.setValue({
            bsDirectSystemCode: '',
            bsDirectSystemName: '',
          });
          this.bsDirectSystemCode = '';
          graspingRulesStores.props.directSystemId = this.directSystemId;
          return;
        }
        const selectData = directSystemId.options.find((item) => item.bsDirectSystemCode === e);
        console.log(selectData);
        this.bsDirectSystemId = selectData.bsDirectSystemId;
        this.bsInvoiceId = selectData.bsDirectSystemId;
        this.directId = selectData.directId;
        this.bsDirectSystemCode = selectData.bsDirectSystemCode;
        this.setValue({
          bsDirectSystemId: selectData.bsDirectSystemId,
          bsDirectSystemName: selectData.bsDirectSystemName,
          directId: selectData.directId,
          terminalCode: '',
          terminalName: '',
        });
        this.directSystemId = selectData.bsDirectSystemId;
        graspingRulesStores.props.directSystemId = this.directSystemId;
        graspingRulesStores.props.bsDirectSystemId = selectData.bsDirectSystemId;
      };
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        this.disabled(true, ['directSystemId', 'terminalCode']);
        request.post('/kms/kmstotalwarehousestore/query', {
          id: this.formConfig.row.id,
          directSystemId: this.formConfig.row.directSystemId,
          directSystemName: this.formConfig.row.directSystemName,
        }, { headers: { functionCode: 'warehouse_store_list' } }).then((res) => {
          if (res.success) {
            console.log(res);
            graspingRulesStores.props.directSystemId = res.result.directSystemId;
            graspingRulesStores.props.invoiceId = res.result.invoiceId;
            graspingRulesStores.props.bsDirectSystemId = res.result.bsDirectSystemId;
            this.setValue({
              ...res.result,
              graspingRulesStores: res.result.directProductReqVos || [],
            });
          }
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        let url = '/kms/kmstotalwarehousestore/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/kms/kmstotalwarehousestore/update';
          params.id = this.formConfig.row.id;
        }

        params.directProductReqVos = params.graspingRulesStores;
        delete params.graspingRulesStores;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
