<template>
  <div class="container">
    <div class="fix-container">
      <div class="title-container">
        <el-button :icon="triggerIcon" @click="trigger" type="text" />
        <span :hidden="!show" class="tittle">{{ title }}</span>
        <div>
          <el-button :hidden="!show || isAdd" icon="el-icon-plus" type="text" @click="addData" />
          <el-button :hidden="!show" icon="el-icon-refresh" type="text" @click="reset" />
        </div>
      </div>
      <div :hidden="!show" class="search-container">
        <el-input
          :placeholder="placeholder"
          v-model="filterText"
          clearable
          suffix-icon="el-icon-search"
        >
        </el-input>
      </div>
    </div>
    <el-scrollbar
      wrap-class="tree-scrollbar"
      class="tree-style"
      :native="false"
      view-class="tree-scrollr-container"
      :noresize="false"
      tag="div"
    >
      <div class="grid-content" :hidden="!show" ref="elTree">
        <div>
          <el-tree
            v-if="isShow"
            class="filter-tree"
            :data="treeData"
            :props="defaultProps"
            highlight-current
            :default-expand-all="false"
            :expand-on-click-node="false"
            node-key="id"
            @node-click="handleCheckChange"
            :current-node-key="currentNodeKey"
          >
            <span class="el-tree-node__label" slot-scope="{ node }">
              <el-tooltip
                :disabled="calWordWidth(node.label)"
                class="item"
                effect="dark"
                :content="node.label"
                placement="right"
              >
                <div class="tree_label_text" :class="node.disabled?'disabled':''">{{ `${node.data.bsDirectSystemCode || ''}${node.data.bsDirectSystemCode?'-':''}${node.data.bsDirectSystemName || node.label}` }}</div>
              </el-tooltip>
              <el-dropdown
                v-if="operationBtns.length"
                class="operation"
                @command="command => handleCommand(command, node)"
              >
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in operationBtns"
                    :key="item.command"
                    :command="item.command"
                    >{{ item.btntext }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </el-tree>
        </div>
      </div>
    </el-scrollbar>
    <Modal :modalConfig="modalConfig" @onClose="closeModal">
      <Form @onClose="closeModal" @reset="reset"/>
    </Modal>
  </div>
</template>

<script>
import request from '../../../../utils/request';
import debounce from '../../../../utils/debounce';
import Modal from '../../../../components/modal/components/modal';
import Form from './components/form.vue';

export default {
  components: {
    Modal,
    Form,
  },
  props: {
    value: String,
    restful: String,
    headers: Object,
    title: String,
    placeholder: {
      type: String,
      default: '请输入组织名称/编码',
    },
    defaultProps: {
      type: Object,
      default() {
        return {
          children: 'children',
          label: 'name',
          value: 'code',
          isLeaf: 'isLeaf',
        };
      },
    },
    operationBtns: {
      type: Array,
      default() {
        return [
          {
            command: 'a',
            btntext: '操作1',
          },
        ];
      },
    },
    searchName: {
      type: String,
      default: 'name',
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    filterText() {
      this.searchDebounce();
    },
  },
  data() {
    return {
      filterText: '',
      treeData: [],
      codes: '',
      names: '',
      defaultExpandedKeys: [],
      defaultExpandAll: false,
      showTree: true,
      searchDebounce: null,
      isNode: true,
      show: true,
      triggerIcon: 'el-icon-d-arrow-left',
      currentNodeKey: '',
      isShow: true,
      modalConfig: {
        visible: false,
        title: '新增',
        width: 600,
        height: 600,
      },
    };
  },
  created() {
    this.getTreeList();
  },
  mounted() {
    this.searchDebounce = debounce(() => this.getTreeList(), 500);
  },
  methods: {
    // 计算文字长度
    calWordWidth(word) {
      const con = document.createElement('canvas').getContext('2d');
      con.font = '700 12px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif'; // 自己需要的字体什么的
      // 生成宽度查询函数
      const strlen = (str) => con.measureText(str).width;
      return strlen(word) < this.$refs.elTree.clientWidth - 50;
    },
    reset() {
      this.$emit('change', '');
      this.getTreeList('reset');
    },
    getTreeList(type) {
      if (type === 'reset') {
        this.filterText = '';
      }
      const data = {
        [this.searchName]: this.filterText,
      };
      request.post(this.restful, data, { headers: this.headers }).then((res) => {
        if (res.success) {
          const selectData = res.result.data.find((item) => item.enableStatus !== '003');
          console.log(selectData);
          this.currentNodeKey = selectData ? selectData.id : '';
          this.isShow = false;
          setTimeout(() => {
            this.treeData = res.result.data;
            console.log(this.treeData);
            this.treeData.map((v) => {
              const item = v;
              item.isLeaf = true;
              item.disabled = item.enableStatus === '003';
              return item;
            });
            if (selectData) {
              this.handleCheckChange(selectData);
            }
            if (this.filterText !== '') {
              this.isNode = false;
            } else {
              this.isNode = true;
            }
            this.isShow = true;
          }, 1);
        }
      });
    },
    // 控制左边的树显示隐藏
    trigger() {
      this.show = !this.show;
      if (this.show) {
        this.triggerIcon = 'el-icon-d-arrow-left';
      } else {
        this.triggerIcon = 'el-icon-d-arrow-right';
      }
      this.$emit('trigger', this.show);
    },
    handleCheckChange(data) {
      console.log(data);
      if (data.enableStatus === '003') return false;
      this.codes = data[this.defaultProps.value];
      this.names = data[this.defaultProps.label];
      this.$emit('change', {
        codes: this.codes,
        names: this.names,
        bsDirectId: data.bsDirectSystemId,
        directId: data.directId ? data.directId : '',
      });
    },
    // 操作按钮
    handleCommand(command, node) {
      console.log(command);
      // this.$emit('handleCommand', { command, node });
      if (command === 'enable') {
        request.post('/mdm/mdmDirectSystemController/enable', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
            this.reset();
          }
        });
      }
      if (command === 'disabled') {
        request.post('/mdm/mdmDirectSystemController/disable', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
            this.reset();
          }
        });
      }
      if (command === 'remove') {
        request.post('/kms/kmstenantrydirectcustomerorg/delete', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
            this.reset();
          }
        });
      }
    },
    // 添加数据
    addData() {
      this.modalConfig.visible = true;
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.disabled{
  color: rgb(150, 150, 150);
    cursor: not-allowed;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  .fix-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 10px;
    height: 90px;
    z-index: 99;
    background-color: white;
    padding-bottom: 4px;
    .title-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      .tittle {
        font-size: 14px;
        font-weight: 600px;
        line-height: 26px;
      }
    }
    .search-container {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
  }
  .grid-content {
    margin-top: 90px;
    .filter-tree {
      padding-top: 10px;
    }
  }
}
.tree-scrollr-container {
  min-height: 300px;
  padding: 0px !important;
  overflow: hidden;
}
.table-container {
  padding: 0 10px;
}
.tree-style {
  height: calc(100vh - 90px);
}
/deep/.tree-container {
  height: 100%;
}
/deep/.tree-scrollbar {
  overflow-x: hidden;
}
/deep/.el-scrollbar__view {
  height: auto !important;
}
/deep/ .el-tree-node__label {
  display: flex;
  overflow: hidden;
  width: 100%;
  padding-right: 20px;
  justify-content: space-between;
  .tree_label_text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    width: 100%;
  }
}
</style>
