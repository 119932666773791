<template>
  <div class="page-container">
    <el-row>
      <el-col :span="treeSpan" class="tree-container">
        <TablePageList
          :title="title"
          style="paddingRight: 10px"
          :placeholder="placeholder"
          :restful="restful"
          :headers="headers"
          :isAdd="isAdd"
          @trigger="trigger"
          @change="treeChange"
          :defaultProps="defaultProps"
          :searchName = 'searchName'
          ref="tableTree"
          :operationBtns="operationBtns"
          @handleCommand="handleCommand"
        />
      </el-col>
      <el-col :span="contentSpan">
        <el-scrollbar
          wrap-class="tree-scrollbar"
          class="tree-style"
          :native="false"
          view-class="tree-scrollr-container"
          :noresize="false"
          tag="div"
        >
          <component
            :is="treeTable"
            class="table-container"
            :treeCode="treeCode"
            :treeName="treeName"
            :bsDirectId="bsDirectId"
            :directId="directId"
            ref="table"
            @resetTrees="reset"
          />
        </el-scrollbar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TablePageList from '../table_page_list';

export default {
  name: 'tablePageTreeBase',
  components: {
    TablePageList,
  },
  created() {},
  computed: {},
  methods: {
    treeChange(data) {
      this.treeCode = data.codes;
      this.treeName = data.names;
      this.bsDirectId = data.bsDirectId;
      this.directId = data.directId;
      this.$refs.table.tablePage.currentPage = 1;
    },
    // 重置
    reset() {
      if (this.treeCode === '') {
        this.$refs.table.getList();
      }
      this.treeCode = '';
      this.$refs.tableTree.getTreeList('reset');
    },
    trigger(show) {
      if (show) {
        this.treeSpan = 4;
        this.contentSpan = 20;
      } else {
        this.treeSpan = 1;
        this.contentSpan = 23;
      }
    },
    // 左侧列表操作
    handleCommand({ command, node }) {
      console.log(command, node);
    },
  },

  data() {
    return {
      treeCode: '',
      treeName: '',
      bsDirectId: '',
      directId: '',
      treeValue: '',
      treeSpan: 4,
      contentSpan: 20,
      treeTable: 'TablePage',
      placeholder: '请输入',
      title: '',
      restful: '',
      headers: {},
      defaultProps: {
        children: 'children',
        label: 'name',
        value: 'code',
        isLeaf: 'isLeaf',
      },
      operationBtns: [],
      searchName: 'name',
      isAdd: false,
    };
  },
};
</script>
<style lang="less" scoped>
.page-container {
  max-height: calc(100vh - 90px);
  overflow: hidden;
}
.tree-scrollr-container {
  min-height: 300px;
  padding: 0px !important;
  overflow: hidden;
}
.table-container {
  padding: 0 10px;
}
.tree-style {
  height: calc(100vh - 90px);
}
/deep/.tree-container {
  height: 100%;
}
/deep/.tree-scrollbar {
  overflow-x: hidden;
}
/deep/.el-scrollbar__view{
  height: 100%;
}
</style>
