var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        {
          ref: "xTable1",
          attrs: { "row-key": "", "row-id": "id", data: _vm.tableData },
        },
        [
          _c("vxe-table-column", {
            attrs: { title: "日志编码", field: "menuCode" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作类型", field: "operationTypeName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作人", field: "operationUserName" },
          }),
          _c("vxe-table-column", {
            attrs: { title: "操作时间", field: "operationTime" },
          }),
          _c("vxe-table-column", {
            attrs: { label: "操作", width: "90", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.openModal(row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "Modal",
        {
          ref: "modalForm",
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal },
        },
        [_c("LogDetail", { attrs: { logDetail: _vm.logDetail } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }