var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.title) + " "),
        _vm.showAdd
          ? _c("i", {
              staticClass: "el-icon-circle-plus-outline add-icon",
              on: { click: _vm.add },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }