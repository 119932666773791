// import { saveErrorLogger } from '@/api/data'
import router from '@/src/router';
// import routers from '@/router/routers'
import {
  getBreadCrumbList,
  setTagNavListInLocalstorage,
  // getMenuByRouter,
  getTagNavListFromLocalstorage,
  getHomeRoute,
  getNextRoute,
  routeHasExist,
  routeEqual,
  getRouteTitleHandled, getConfig,
} from '../../utils';

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route);
  state.tagNavList = state.tagNavList.filter((item) => !routeEqual(item, route));
  router.push(nextRoute);
};

export default {
  state: {
    breadCrumbList: [],
    tagNavList: [],
    homeRoute: {},
    errorList: [],
    hasReadErrorPage: false,
    selectConfig: null,
    lastLoginInfo: {}, // 上次登录信息
    userToken: '', // 忘记密码时的token信息
  },
  getters: {
    // menuList: (state, getters, rootState) => getMenuByRouter(routers, rootState.user.access),
    errorCount: (state) => state.errorList.length,
    lastLoginInfo: (state) => state.lastLoginInfo,
    userToken: (state) => state.userToken,
  },
  mutations: {
    setselectConfig(state, payload) {
      state.selectConfig = payload;
    },
    setBreadCrumb(state, route) {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute);
    },
    setHomeRoute(state, routes) {
      state.homeRoute = getHomeRoute(routes, 'home');
    },
    setTagNavList(state, list) {
      let tagList = [];
      if (list) {
        tagList = [...list];
      } else tagList = getTagNavListFromLocalstorage() || [];
      // if (tagList[0] && tagList[0].name !== 'home') tagList.shift();
      const homeTagIndex = tagList.findIndex((item) => item.name === 'home');
      if (homeTagIndex > 0) {
        const homeTag = tagList.splice(homeTagIndex, 1)[0];
        tagList.unshift(homeTag);
      }
      state.tagNavList = tagList;
      setTagNavListInLocalstorage([...tagList]);
    },
    closeTag(state, route) {
      const tag = state.tagNavList.filter((item) => routeEqual(item, route));
      // eslint-disable-next-line no-param-reassign
      route = tag[0] ? tag[0] : null;
      if (!route) return;
      closePage(state, route);
    },
    addTag(state, { route, type = 'unshift' }) {
      const routers = getRouteTitleHandled(route);
      if (!routeHasExist(state.tagNavList, routers)) {
        if (type === 'push') state.tagNavList.push(routers);
        else if (routers.name === 'home') state.tagNavList.unshift(routers);
        else state.tagNavList.splice(1, 0, routers);
        setTagNavListInLocalstorage([...state.tagNavList]);
      }
    },
    addError(state, error) {
      state.errorList.push(error);
    },
    setHasReadErrorLoggerStatus(state, status = true) {
      state.hasReadErrorPage = status;
    },
    setLastLoginInfo(state, lastLoginInfo) {
      state.lastLoginInfo = lastLoginInfo;
    },
    setUserToken(state, userToken) {
      state.userToken = userToken;
    },
  },
  actions: {
    SET_SELECT_CONFIG({ commit }) {
      getConfig('select_box_option_size').then((res) => {
        commit('setselectConfig', {
          pageSize: res.result,
        });
      });
    },
  },
};
