export default {
  namespaced: true,
  state: {
    theme: {},
  },

  mutations: {
    CHANGE_SETTING(state, payload) {
      state.theme = payload;
    },
  },
  actions: {
    changeSetting({ commit }, payload) {
      commit('CHANGE_SETTING', payload);
    },
  },
};
