import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; // 样式
import FoundRouter from '@/found/router';
import { handleRouterSame, getPropsCollection } from '@/found/utils';
import Storage from '@/found/utils/storage';

Vue.use(VueRouter);

const routes = [
  ...FoundRouter,
];

// let routes = handleRouterSame(FoundRouter, ['login']);
// console.log(routes);

// routes = [
//   {
//     path: '/login',
//     name: 'login',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "Login" */ '../views/login'),
//     meta: { title: '登录' },
//   },
//   ...routes,
// ];

const routerSubContext = require.context('../views', true, /router\/index\.js$/);

const addRouter = [];
const prodRoutes = routes[1].children;
routerSubContext.keys().forEach((route) => {
  const routerModule = routerSubContext(route);
  addRouter.push(...(routerModule.default || routerModule));
});
const addNames = getPropsCollection(addRouter); // 获取自定义路由的所有name属性

// 处理产品路由表
const prodRoutesUpdated = handleRouterSame(prodRoutes, addNames);

// 组合路由
routes[1].children = [...prodRoutesUpdated, ...addRouter];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  // const menuArray = JSON.parse(Storage.l.get('menuArray'));
  // if (to.path !== '/login' && menuArray && !menuArray.includes(to.path)) {
  //   router.replace('/home');
  //   NProgress.done();
  // }
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0);
  NProgress.done();
});

export default router;
