<template>
  <div v-if="visible" class="form-title">{{ title }} <i @click="add" class="el-icon-circle-plus-outline add-icon" v-if="showAdd" /></div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
    visible: {
      type: Boolean,
      default() {
        return true;
      },
    },
    showAdd: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    add() {
      this.$emit('add');
    },
  },
};
</script>

<style lang="less" scoped>
.form-title {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  .add-icon{
    font-size: 16px;
    cursor: pointer;
    color: #409EFF;
  }
}
</style>
