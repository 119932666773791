<template>
  <div>
    <vxe-table row-key row-id="id" ref="xTable1" :data="tableData">
      <vxe-table-column title="日志编码" field="menuCode"></vxe-table-column>
      <vxe-table-column title="操作类型" field="operationTypeName"></vxe-table-column>
      <vxe-table-column title="操作人" field="operationUserName"></vxe-table-column>
      <vxe-table-column title="操作时间" field="operationTime"></vxe-table-column>
      <vxe-table-column label="操作" width="90" align="center">
        <template v-slot="{row}">
          <el-button type="text" @click="openModal(row)">查看</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <!-- 弹框 -->
    <Modal :modalConfig="modalConfig" ref="modalForm" @onClose="closeModal">
      <LogDetail :logDetail="logDetail" />
    </Modal>
  </div>
</template>

<script>
import request from '../../utils/request';
import Modal from '../modal/components/full.vue';
import LogDetail from './components/log_detail.vue';

export default {
  name: 'log',
  components: {
    Modal,
    LogDetail,
  },
  props: {
    formConfig: Object,
  },
  data() {
    return {
      modalConfig: {
        title: '选择字段',
        visible: false,
      },
      logDetail: null,
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取日志列表
    getList() {
      request
        .post('/mdm/m/crmlog/list', {
          pageNum: 1,
          pageSize: 15,
          onlyKey: this.formConfig.row.id,
        })
        .then((res) => {
          if (res.success) {
            this.tableData = res.result.data;
          }
        });
    },
    // 查看
    openModal(row) {
      this.logDetail = row;
      this.modalConfig.visible = true;
    },
    // 获取列表中包含的数据字典
    getDictCode(val, code) {
      if (JSON.stringify(this.dictCodeList) !== '{}') {
        console.log(code, this.dictCodeList);
        if (this.dictCodeList && this.dictCodeList[code]) {
          const dict = this.dictCodeList[code].find(
            (v) => v.dictCode === `${val}`,
          );
          return dict ? dict.dictValue : val;
        }
        return val;
      }
      return val;
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
</style>
