import Vue from 'vue';
import Vuex from 'vuex';

import { FoundStore } from '@/found/store';

Vue.use(Vuex);

const store = {
  state: {
    buttonApiIsLoading: false,
  },
  mutations: {},
  actions: {},
  modules: {},
};

Object.keys(store).forEach((key) => {
  store[key] = { ...FoundStore[key], ...store[key] };
});

/**
 * 处理子模块store作为module进行插入
 */
// console.info('------------------Store子模块开始扫描------------------');
const storeSubContext = require.context('../views', true, /store\/index\.js$/);

storeSubContext.keys().forEach((module) => {
  const storeModule = storeSubContext(module);
  // console.info('模块名称:', storeModule.default.namespace);
  // console.info('模块对象:', storeModule.default);
  // 如果产品的namespace已经包含了项目上的namespace，则抛出错误，并且注入该模块的store
  if (Object.prototype.hasOwnProperty.call(store.modules, storeModule.default.namespace)) {
    console.error('Store模块冲突--->', storeModule.default.namespace);
  } else {
    store.modules = {
      ...store.modules,
      [storeModule.default.namespace]: storeModule.default,
    };
  }
});
// console.info('------------------Store子模块注入完毕------------------');

export default new Vuex.Store(store);
