import request from '@/found/utils/request';
import FormSelect from '../form/components/form_select';

export default {
  components: {},
  props: {
    disabled: Boolean,
    config: {
      type: Object,
      default() {
        return {};
      },
    },
    // requestUrl: String,
    dataSource: {
      type: Array,
      default() {
        return [];
      },
    },
    selectRows: {
      type: Array,
      default() {
        return [];
      },
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    isSignal: Boolean,
    label: {
      type: String,
    },
    value: {

    },
  },
  computed: {
    tableConfig: {
      get() {
        return this.config;
      },
    },
    selectionRows: {
      get() {
        return this.selectRows;
      },
      set(v) {
        this.$emit('selectRows:update', v);
      },
    },
  },
  data() {
    return {
      // requestUrl: '/mdm/mdmCustomerMsgController/findCustomerNotRelateUserSelectList',
      requestUrl: '/mdm/mdmCustomerMsgController/page',
      columns: [
        {
          visible: true,
          type: 'radio',
          formorder: '0',
          fixed: 'left',
          width: '50',
        },
        {
          field: 'customerCode',
          title: '客户编号',
          visible: true,
          align: null,
          type: null,
          width: null,
          formorder: '1',
          fixed: null,
          customName: null,
        },
        {
          field: 'customerName',
          title: '客户名称',
          visible: true,
          align: null,
          type: null,
          width: null,
          formorder: '1',
          fixed: null,
          customName: null,
        },
        {
          field: 'customerTypeName',
          title: '类型',
          visible: true,
          align: null,
          type: null,
          width: null,
          formorder: '1',
          fixed: null,
          customName: null,
        },
        {
          field: 'channelName',
          title: '渠道',
          visible: true,
          align: null,
          type: null,
          width: null,
          formorder: '1',
          fixed: null,
          customName: null,
        },
        {
          field: 'orgName',
          title: '所属组织',
          visible: true,
          align: null,
          type: null,
          width: null,
          formorder: '1',
          fixed: null,
          customName: null,
        },
      ],
      tableData: [],
      tableLoading: false,
      tablePage: {
        currentPage: 0,
        pageSize: 15,
        searchForm: {},
      },
    };
  },
  methods: {
    handleOnChange(selection) {
      // console.log(selection)
      this.selectionRows = [...selection];
      this.$emit('input', this.isSignal ? selection[0] : selection);
      this.$emit('change', this.isSignal ? selection[0] : selection);
    },
    selectChange(selection) {
      this.selectionRows = selection;
    },
    deleteRow(index) {
      this.tableData.splice(index, 1);
    },
    getList(searchForm) {
      const { tablePage } = this;
      const params = {
        ...this.params,
        pageNum: tablePage.currentPage,
        pageSize: tablePage.pageSize,
        ...searchForm,
      };
      if (!this.requestUrl) return Promise.resolve();
      return request.post(this.requestUrl, { ...params });
    },
    validate() {
      // this.getList();
      return true;
    },
    setPageChange(page) {
      this.tablePage = page;
    },
  },
  watch: {},
  mounted() {
    this.columns[0].type = this.isSignal ? 'radio' : 'checkbox';
  },
  render() {
    const { handleOnChange } = this;
    return <FormSelect
      validate={this.validate}
      config={{
        getSelectionChange: this.selectChange,
        fetchData: this.getList,
        columns: this.columns,
        title: '选择客户',
        hideCancelButton: true,
        hideConfirmButton: true,
        searchList: [
          {
            field: 'customerCode',
            span: '6',
            // title: { name: '客户编码' },
            title: '客户编码',
            type: 'ElInput',
            visible: true,
            props: { clearable: true, placeholder: '客户编码' },
            name: 'ElInput',
            placeholder: '请输入客户编码',
            search: 'true',
            attrs: { placeholder: '请输入客户编码' },
          },
          {
            // field: 'customerName',
            span: '6',
            // title: { name: '客户名称' },
            field: 'customerName',
            title: '客户名称',
            visible: true,
            type: 'ElInput',
            width: '',
            formorder: '1',
            props: { clearable: true, placeholder: '客户名称' },
            search: 'true',
            placeholder: '请输入客户名称',
            name: 'ElInput',
            attrs: { placeholder: '请输入客户名称' },
          },
        ],
      }}
      tablePage={this.tablePage}
      isSignal={this.isSignal}
      selection={this.model}
      ref={'FormSelect'}
      icon={'el-icon-search'}
      on-change={handleOnChange}
      on-page-change={this.setPageChange}
      scopedSlots={{
        wrapComponent: (props) => <el-input readonly value={this.label || this.value} class={'inline-table'} disabled={this.disabled} nativeOnClick={props.event}>
            {/* <el-button slot="append" icon="el-icon-search" onClick={props.event} disabled={this.disabled}></el-button> */}
          </el-input>,
      }}
      disabled={this.disabled}
    >

    </FormSelect>;
  },
};
