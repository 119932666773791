import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import CustomerList from './customer-list.vue';

formCreate.component('CustomerList', CustomerList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'specialty_store_form',
      formParentCode: 'CRM20210910000002685',
    };
  },

  methods: {
    // 重置规则
    setRule(v) {
      const item = v;
      if (item.field === 'customerName') { // 选择客户
        item.props = {
          ...item.props,
          params: {
            functionCode: 'customer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (item.field === 'contractDate') {
        item.props = {
          ...item.props,
          type: 'daterange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          unlinkPanels: true,
          align: 'right',
          valueFormat: 'yyyy-MM-dd',
          // defaultTime: ['00:00:00', '23:59:59'],
        };
        item.on = {
          change: (e) => {
            if (e && e.length > 0) {
              this.setValue({
                contractStartDate: e[0],
                contractEndDate: e[1],
              });
            } else {
              this.setValue({
                contractStartDate: null,
                contractEndDate: null,
              });
            }
          },
        };
      }
      return v;
    },

    // 获取详情
    async getDetail() {
      request.post('/mdm/mdmExclusiveShopController/getDetail', { id: this.formConfig.row.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          setTimeout(() => {
            this.setValue({
              ...result,
              attach: result.attach || [],
              contractDate: result.contractStartDate && result.contractEndDate ? [result.contractStartDate, result.contractEndDate] : [],
            });
          }, 100);
        }
      });
    },

    // 完成渲染
    formComplete() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      // 首年合同任务
      const contractTaskName = this.getRule('contractTaskName');
      contractTaskName.props = {
        ...contractTaskName.props,
        placeholder: '首年合同任务(万元)',
      };

      this.setValue({
        responsibleUserCode: userInfo.userName,
        responsibleUserName: userInfo.fullName,
        responsibleOrgCode: userInfo.orgCode,
        responsibleOrgName: userInfo.orgName,
      });

      // 经销商
      const customerName = this.getRule('customerName');
      customerName.on.change = (e) => {
        if (e) {
          this.setValue({
            customerName: e[0].customerName,
            customerCode: e[0].customerCode,
          });
        } else {
          this.setValue({
            customerName: null,
            customerCode: null,
          });
        }
      };
      if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
        this.getDetail(this.formConfig.row.id);
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        const url = '/mdm/mdmExclusiveShopController/edit';
        // 有id  就是编辑
        if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
          formData.id = this.formConfig.row.id;
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
