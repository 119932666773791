<template>
  <div>
    <el-input v-model="searchInput"
              @input="searchWord"
              class="search_input"
              placeholder="请输入标题"></el-input>
    <vxe-table row-key
               :show-header='false'
               row-id='field'
               ref="xTable1"
               class="sortable-row-demo"
               border=none
               max-height="400"
               @checkbox-change="checkboxChange"
               @checkbox-all="checkboxAll"
               :checkbox-config="{checkRowKeys: defaultSelecteRows}"
               :data="tableData">
      <vxe-table-column width="60">
        <template v-slot="{row}">
          <vxe-switch @change="(value)=>changeVisible(row,value)"
                      :value="row.visible === '1'"
                      size="mini"></vxe-switch>
        </template>
      </vxe-table-column>
      <vxe-table-column width="120"
                        title="名称"
                        field="titleDom">
        <template v-slot="{row}">
          <div v-html="row.titleDom">
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column width="40">
        <template v-slot>
          <span class="drag-btn">
            <i class="el-icon-sort"></i>
          </span>
        </template>
      </vxe-table-column>
    </vxe-table>
    <div class="footer">
      <el-button size="mini"
                 @click="submit">确认</el-button>
      <el-button size="mini"
                 @click="repeat">重置</el-button>

    </div>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import axios from 'axios';
import request from '../../utils/request';

export default {
  name: 'PersonalSetting',
  components: {},
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    parentCode: String,
    headerFunctionCode: String,
  },
  data() {
    return {
      searchInput: '',
      tableData: [],
      originalTableData: [],
      defaultSelecteRows: [],
    };
  },
  created() {
    this.rowDrop();
    this.getList();
  },

  beforeDestroy() {
    if (this.sortable1) {
      this.sortable1.destroy();
    }
  },
  methods: {
    searchWord(val) {
      this.tableData.map((v) => {
        const rowData = v;
        if (v.title) {
          let titleDom = '';
          const values = v.title.split(val);
          titleDom = values.join(`<span style="color:red;">${val}</span>`);
          rowData.titleDom = titleDom;
        }
        return rowData;
      });
    },
    changeVisible(row, value) {
      // eslint-disable-next-line no-param-reassign
      row.visible = value.value ? '1' : '0';
      const result = this.sedimentationTable(this.tableData);
      this.tableData = result;
    },
    // 沉底
    sedimentationTable(tableData) {
      const tableTop = [];
      const tableBot = [];
      tableData.forEach((v, k) => {
        if (v.visible === '1') {
          tableTop.push(v);
        } else {
          tableBot.push(v);
        }
      });
      return tableTop.concat(tableBot);
    },
    getList() {
      request
        .post(
          '/mdm/mdmColumnConfigPersonalController/findColumnConfigPersonalList',
          {
            functionCode: this.headerFunctionCode,
            parentCode: this.parentCode,
          },
        )
        .then((res) => {
          if (res.success) {
            // 用双向绑定设置数据
            const tableData = [];
            const defaultSelecteRows = [];
            const originalTableData = [];
            const result = this.sedimentationTable(res.result);
            result.forEach((v) => {
              const rowData = v;
              if (v.title) {
                rowData.titleDom = `<span>${v.title}</span>`;
                tableData.push(rowData);
                if (rowData.visible === '1') {
                  defaultSelecteRows.push(rowData.field);
                }
              } else {
                originalTableData.push(rowData);
              }
            });
            this.originalTableData = originalTableData;
            this.tableData = tableData;
            this.defaultSelecteRows = defaultSelecteRows;
          }
        });
    },
    // 提交
    submit() {
      this.tableData.map((v, k) => {
        const rowData = v;
        rowData.formOrder = k + 1;
        return rowData;
      });
      const submitParams = {
        parentCode: this.$store.state.menus.selectMenu,
        functionCode: this.headerFunctionCode,
        columnList: this.originalTableData.concat(this.tableData),
      };
      // console.log(JSON.stringify(submitParams));
      request
        .post(
          '/mdm/mdmColumnConfigPersonalController/saveColumnConfigPersonal',
          submitParams,
        )
        .then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('changeColumns');
          }
        });
    },
    repeat() {
      const submitParams = {
        parentCode: window.App && window.App.$store && window.App.$store.state.menus.selectMenu,
        functionCode: this.headerFunctionCode,
      };
      request
        .post(
          '/mdm/mdmColumnConfigPersonalController/deleteColumnConfigPersonal',
          submitParams,
        )
        .then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '重置成功',
            });
            this.$emit('changeColumns');

            this.getList();
          }
        });
    },
    // 多选
    checkboxChange({ rowIndex, checked }) {
      this.tableData[rowIndex].visible = checked ? '1' : '0';
    },
    // 全选
    checkboxAll(val) {
      console.log(val);
    },
    rowDrop() {
      this.$nextTick(() => {
        const xTable = this.$refs.xTable1;
        this.sortable1 = Sortable.create(
          xTable.$el.querySelector('.body--wrapper>.vxe-table--body tbody'),
          {
            handle: '.drag-btn',
            animation: 150,
            ghostClass: 'blue-background-class',
            onEnd: ({ newIndex, oldIndex }) => {
              const currRow = this.tableData.splice(oldIndex, 1)[0];
              this.tableData.splice(newIndex, 0, currRow);
            },
          },
        );
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drag-btn {
  width: 100%;
  height: 100%;
}
/deep/.el-input {
  width: 160px;
}
.search_input {
  margin-bottom: 12px;
  width: 100%;
}
/deep/.hight-line {
  color: red;
}
.footer {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}
/deep/.blue-background-class {
  background: #e8eaec;
}
.el-icon-sort{
  cursor:pointer;
  font-size: 16px!important;
}
</style>
