import { VXETable } from 'vxe-table';

VXETable.renderer.add('TableLinkText', {
  renderDefault(h, renderOpts, params) {
    const { row, column } = params;
    const { events } = renderOpts;
    const click = () => {
      events.click(params);
    };
    return [
      <el-link type="primary" onClick={click}>
        {row[column.property]}
      </el-link>,
    ];
  },
});
