<template>
  <div v-loading="loading">
    <form-create
        ref="formCreate"
        v-model="fApi"
        :rule="rules || rule"
        :option="option"
        @mounted="mounted"
        @change="handleFieldChange"
    ></form-create>
    <div>
      <div v-if="buttons.submit || buttons.close || buttons.submitAudit"
          :class="buttonsPosition !== 'center' ? 'dialog-footer' : 'footer'"
      >
        <el-button
            type="primary"
            v-if="buttons.submit"
            @click="submit(1)"
            size="small"
            :loading="$store.state.buttonApiIsLoading"
            icon="el-icon-check"
        >{{buttonText.submit}}
        </el-button>
        <el-button
            type="primary"
            v-if="buttons.submitAudit"
            @click="submit(2)"
            size="small"
            :loading="$store.state.buttonApiIsLoading"
            icon="el-icon-check"
        >{{buttonText.submitAudit}}
        </el-button>
        <el-button
            type="danger"
            v-if="buttons.close"
            @click="closeModal"
            size="small"
            icon="el-icon-close"
        >{{buttonText.close}}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import request from '../../utils/request';
import Storage from '../../utils/storage';
import Init, { isEmpty } from './tools/init';

export default {
  props: {
    rules: Array,
    formConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    noButton: {
      type: Boolean,
      default: false,
    },
    id: String,
    modalFormData: Object,
    modalConfig: Object || Array,
  },
  name: 'Form',
  data() {
    return {
      isInit: 0,
      loading: false,
      formFunctionCode: '',
      formParentCode: undefined,
      // 实例对象
      fApi: {},
      rule: [],
      buttonsPosition: '',
      buttonText: {
        submit: '提交',
        close: '关闭',
        submitAudit: '保存并提交',
      },
      buttons: {
        submit: true,
        close: true,
        loading: false,
        submitAudit: false,
      },
      // 组件参数配置
      option: {
        resetBtn: false,
        submitBtn: false,
        global: {
          // 设置所有组件
          '*': {
            props: {
              size: 'small',
            },
          },
        },
      },
      judgeTime: null,
      activityTableColumn: [],
    };
  },
  provide() {
    return {
      $MODALFORM: this,
    };
  },
  computed: {
    ...mapState({ selectConfig: (state) => state.app.selectConfig }),
    formData: {
      get() {
        return this.modalFormData;
      },
      set(v) {
        this.$emit('modalFormData:update', v);
      },
    },
    ruless: {
      get() {
        return this.rules;
      },
      set(v) {
        this.$emit('update: rules', v);
      },
    },
  },

  created() {
    if (this.formFunctionCode) {
      this.getFormRule(this.formFunctionCode, this.formParentCode);
    }
    if (this.noButton) {
      this.buttons = {
        submit: false,
        close: false,
        loading: false,
        submitAudit: false,
      };
    }
  },
  mounted() {
    if (this.rules && this.rules.length) {
      const rules = this.initRuleData(this.rules);
      this.$nextTick(() => {
        this.rule = this.initMountedRuleData(rules);
      });
    } else if (this.rule && this.rule.length) {
      this.initrule();
      this.initMountedRule();
    }
    this.isInit = 1;
  },
  methods: {
    handleFieldChange(field, value) {
      // console.log(field);
      // console.log(value);
      // console.log($f);
      if (!this.isInit) return;
      if (this.formData !== undefined && this.formData !== null) this.formData[field] = value;
    },

    /**
     *form 的 mounted
     */
    mounted() {
      // console.log(this.formData);
      // this.fApi.setValue(this.formData);
      setTimeout(() => {
        const rules = this.rule.filter((i) => i.type === 'select' && i.visible !== false);
        rules.forEach(({ field }) => this.fApi.el(field) && this.fApi.el(field).handleResize && this.fApi.el(field).handleResize());
      }, 300);
      this.$emit('load-complete');
    },
    // 表单渲染完成后回调
    formComplete() {

    },
    // 获取表单初始配置未渲染前信息
    async getBeforFormRule(functionCode, parentCode = undefined) {
      let res = null;
      if (parentCode) {
        res = await request.post('/mdm/mdmtableconfig/form/query', {
          functionCode,
          parentCode,
        });
      } else {
        res = await request.post('/mdm/mdmtableconfig/form/query', {
          functionCode,
        });
      }

      return Init.initConfig(res.result);
    },
    // 处理配置前置方法
    beforeSetRule(rule) {
      return rule;
    },
    // 获取表单配置信息
    async getFormRule(functionCode, parentCode = undefined) {
      let res = null;
      if (parentCode) {
        res = await request.post('/mdm/mdmtableconfig/form/query', {
          functionCode,
          parentCode,
        });
      } else {
        res = await request.post('/mdm/mdmtableconfig/form/query', {
          functionCode,
        });
      }
      const formInit = Init.initConfig(res.result);
      const beforeRule = this.beforeSetRule(formInit);
      this.rule = beforeRule.map((v) => {
        const item = v;
        return this.setRule(item);
      });
      this.initrule();
      this.$nextTick(() => {
        this.initMountedRule();
        this.formComplete();
      });
      return this.rule;
    },
    // 设置rule
    setRule(item) {
      return item;
    },
    // 设置表单数据
    setValue(value) {
      this.fApi.setValue(value);
      this.initMountedRule();
    },
    parentNeedData() {
      return false;
    },
    // 重置表单
    resetFields() {
      this.fApi.resetFields();
    },
    // 获取表单数据
    getFormData() {
      let data = null;
      this.fApi.submit((formData) => {
        data = formData;
        this.buttons.loading = true;
      }, () => {
        this.$message.error('请正确完整的填写表单');
        return false;
      });
      return data;
    },
    // 获取rule的单个对象
    getRule(field) {
      return this.fApi.getRule(field) || { validate: [], props: {} };
    },
    // 获取制定字段的值
    getFieldValue(field) {
      return this.fApi.getValue(field);
    },
    // 禁用启用某个字段
    disabled(disabled, fields) {
      this.fApi.disabled(disabled, fields);
    },
    // 隐藏某个组件
    display(display, field) {
      if (!display) {
        this.getRule(field).className = 'display-none';
      } else {
        this.getRule(field).className = null;
      }
    },
    // 更新字段规则(rule中的props)
    updateRules(fields) {
      this.fApi.updateRules(fields);
    },
    // 隐藏或显示制定字段 (hidden为true是隐藏，hidden为false是显示)
    hiddenFields(hidden, fields) {
      this.fApi.hidden(hidden, fields);
    },
    // 关闭弹窗
    closeModal() {
      this.$emit('onClose');
    },
    // 获取远程数据
    async getSelectOptions(url, params, headers, type) {
      const newParams = { ...params };
      if (this.selectConfig)Object.assign(newParams, this.selectConfig);
      let res = null;
      if (type && type === 'GET') {
        res = await request.get(url, newParams, { headers });
      } else {
        res = await request.post(url, newParams, { headers });
      }

      return res.result.data || res.result || [];
    },
    // 获取下拉框数据
    async getOptins(field) {
      const rule = this.getRule(field);
      rule.props = {
        ...rule.props,
        loading: false,
      };
      if (rule && rule.refresh) {
        rule.options = [];
      }
      if (!rule.options || rule.options.length === 0 || rule.refresh) {
        rule.props.loading = true;
        const {
          restful, restfulParams, dictCode, restfulParamsGetValue, restType,
        } = rule;
        let { optionsKey } = rule;
        if (!restful && !dictCode) {
          // rule.options = [];
          rule.props.loading = false;
          return;
        }
        let res = [];
        const selectedCode = Array.isArray(rule.value) ? 'selectedCodeList' : 'selectedCode';
        if (rule.dictCode) {
          res = await this.getSelectOptions('/mdm/mdmdictdata/list', {
            dictTypeCode: rule.dictCode,
            [selectedCode]: rule.value,
          }, { ...rule.headers });
          optionsKey = {
            label: 'dictValue',
            value: 'dictCode',
          };
        }
        if (rule.restful) {
          let params = {};
          if (restfulParamsGetValue) {
            const keys = Object.keys(restfulParamsGetValue);
            keys.forEach((v) => {
              const item = v;
              params[item] = this.getFieldValue(restfulParamsGetValue[item]);
            });
          }

          params = {
            ...restfulParams,
            ...params,
            [selectedCode]: rule.value,
          };
          const headers = {
            ...rule.headers,
          };
          res = await this.getSelectOptions(restful, params, headers, restType);
        }
        if (optionsKey) {
          rule.options = res.map((v) => {
            const item = v;
            item.label = item[optionsKey.label];
            item.value = this.getOptionsValue(optionsKey.value, item);
            return item;
          });
        } else {
          rule.options = res;
        }
        rule.props.loading = false;
        if (typeof (rule.on.optionCallback) === 'function') {
          rule.on.optionCallback(rule.options, rule.value);
        }
        this.changeRules();
      }
    },
    getOptionsValue(optionsValue, item) {
      let json = {};
      if (Array.isArray(optionsValue)) {
        for (const key of optionsValue) {
          if (key in item) {
            json[key] = item[key];
          }
        }
      } else if (typeof optionsValue === 'string') {
        json = item[optionsValue];
      }
      return json;
    },
    // 获取多级联动下拉框数据
    async getCascaderOptions(field) {
      const rule = this.getRule(field);
      if (!rule.props.options || rule.props.options.length === 0 || rule.refresh) {
        rule.props.loading = true;
        const { restful, restfulParams, optionsKey } = rule;
        if (!restful) {
          rule.props.options = [];
          rule.props.loading = false;
          return;
        }
        const params = {
          ...restfulParams,
          selectedCode: rule.value,
        };
        const headers = {
          ...rule.headers,
        };
        let res = [];
        if (restful) {
          res = await this.getSelectOptions(restful, params, headers);
        }
        if (optionsKey) {
          rule.props.options = this.cascaderDataDeal(res, optionsKey);
        } else {
          rule.props.options = res;
        }
        rule.props.loading = false;
        this.changeRules();
      }
    },
    // 多级联动下拉框数据处理
    cascaderDataDeal(data, optionsKey) {
      const newData = data.map((v) => {
        const item = v;
        item.label = item[optionsKey.label];
        item.value = item[optionsKey.value];
        if (v.children && v.children.length > 0) {
          this.cascaderDataDeal(v.children, optionsKey);
        } else {
          delete item.children;
        }
        return item;
      });
      return newData;
    },
    // 获取树形下拉框数据
    async getTreeOptions(field) {
      const rule = this.getRule(field);
      if (!rule) {
        return false;
      }
      if (!rule.props) {
        return false;
      }
      if (!Array.isArray(rule.props && rule.props.options) || rule.props.options.length === 0) {
        rule.props.loading = true;
        const { restful, restfulParams } = rule;
        if (!restful) {
          rule.props.options = [];
          rule.props.loading = false;
          return;
        }
        const params = {
          ...restfulParams,
          selectedCode: rule.value,
        };
        const headers = {
          ...rule.headers,
        };
        const res = await this.getSelectOptions(restful, params, headers);
        rule.props.options = res;
        rule.props.loading = false;
        if (res && res.length > 0) { this.setValue({ [field]: rule.value || '' }); }
        this.changeRules();
      }
    },
    // 下拉卡远程搜索
    remoteMethod(query, field) {
      const rule = this.getRule(field);
      rule.restfulParams = { ...rule.restfulParams, [rule.props.remoteParams]: query };
      rule.options = [];
      this.getOptins(field);
    },
    // 初始化处理rule数据
    initRuleData(rules) {
      const self = this;
      const rule = [];
      const types = ['select', 'input', 'cascader', 'FormSelectTree', 'FormSelectTreeNew', 'DatePicker', 'TimePicker'];
      const validateType = {
        select: 'change',
        input: 'blur',
        inputNumber: 'blur',
        textarea: 'blur',
        tree: 'change',
        FormEdit: 'blur',
        FormSelectTree: 'change',
        cascader: 'change',
        DatePicker: 'change',
        TimePicker: 'change',
      };
      const code = this.formConfig.code || this.formConfig.type;
      const buttonCode = (this.formConfig && this.formConfig.buttonCode) || (this.modalConfig && this.modalConfig.config && this.modalConfig.config.buttonCode);
      rules.forEach((v) => {
        const item = v;
        if (!item) return false;
        if (self.formData && self.formData[item.field]) item.value = self.formData[item.field];
        if (types.includes(item.type)) {
          item.props = {
            ...item.props,
            placeholder: (item.title && item.title.replace('：', '')) || '',
          };
        }
        if (item.type === 'select') {
          item.on = {
            ...item.on,
            focus: () => this.getOptins(item.field),
          };
          if (item.props && item.props.remote) {
            // item.refresh = true;
            item.on = {
              ...item.on,
              focus: () => item.props.remoteMethod && item.props.remoteMethod(),
            };
          }
        }
        if (item.type === 'cascader') {
          item.on = {
            ...item.on,
            focus: () => this.getCascaderOptions(item.field),
          };
        }
        if (item.type === 'FormSelectTree') {
          item.on = {
            ...item.on,
            focus: () => this.getTreeOptions(item.field),
          };
        }
        if (item.type === 'DatePicker') {
          item.props = {
            ...item.props,
            pickerOptions: {
              // 禁止选中时间
              disabledDate: (val) => {
                // 判断是否有关联配置
                if (item.props.relation) {
                  const {
                    beginField, endField, gtNow, isEq,
                  } = item.props.relation;
                  // 当前关联时间是开始时间，并且有选择另一个时间情况下
                  if (gtNow) {
                    if (item.field === beginField && this.getFieldValue(endField)) {
                      if (isEq) {
                        const result = val > new Date(this.getFieldValue(endField)).getTime() || val < (new Date().getTime() - 24 * 60 * 60 * 1000);
                        return result;
                      }
                      const result = val > new Date(this.getFieldValue(endField)).getTime() || val < (new Date().getTime() - 24 * 60 * 60 * 1000);
                      return result;
                    }
                    if (item.field === endField && this.getFieldValue(beginField)) {
                      if (isEq) {
                        const result = val < new Date(this.getFieldValue(beginField)).getTime() - 24 * 60 * 60 * 1000 || val < (new Date().getTime() - 24 * 60 * 60 * 1000);
                        return result;
                      }
                      const result = val < new Date(this.getFieldValue(beginField)).getTime() || val < (new Date().getTime() - 24 * 60 * 60 * 1000);
                      return result;
                    }
                    // 没有选择另一个时间
                    const result = val < (new Date().getTime() - 24 * 60 * 60 * 1000);
                    return result;
                  }
                  if (item.field === beginField && this.getFieldValue(endField)) {
                    if (isEq) {
                      const result = val > new Date(this.getFieldValue(endField)).getTime();
                      return result;
                    }
                    const result = val > new Date(this.getFieldValue(endField)).getTime();
                    return result;
                  }
                  if (item.field === endField && this.getFieldValue(beginField)) {
                    if (isEq) {
                      const result = val < new Date(this.getFieldValue(beginField)).getTime() - 24 * 60 * 60 * 1000;
                      return result;
                    }
                    const result = val < new Date(this.getFieldValue(beginField)).getTime();
                    return result;
                  }
                  // 没有选择另一个时间
                  return false;
                }
                // 没有配置关联
                return false;
              },
            },
          };
        }
        if (item.required) {
          item.validate = [
            {
              trigger: validateType[item.type],
              required: true,
              message: `${item.title}不能为空`,
            },
            ...item.validate,
          ];
        }
        if (item.type === 'upload') {
          item.props = {
            type: 'select',
            name: 'file',
            multiple: true,
            previewMask: false,
            uploadType: 'image',
            ...item.props,
            action: '/upload/uploadController/uploadOssList',
            headers: {
              loginUserToken: Storage.l.get('TokenKey'),
            },
          };
        }
        if (buttonCode === 'view' || code === 'view') {
          item.props = {
            ...item.props,
            disabled: true,
            placeholder: '',
          };
        }
        if ((buttonCode === 'add' || code === 'add') && item.editableInCreate) {
          item.props = {
            ...item.props,
            disabled: item.editableInCreate === 'false',
          };
        }
        if ((buttonCode === 'edit' || code === 'edit') && item.editableInEdit) {
          item.props = {
            ...item.props,
            disabled: item.editableInEdit === 'false',
          };
        }
        rule.push(item);
        return true;
      });
      // console.log(rule);
      return rule;
    },
    // 初始化mountedRule数据
    initMountedRuleData(rules) {
      const mountedRule = [];
      const code = this.formConfig.code || this.formConfig.type;
      const buttonCode = (this.formConfig && this.formConfig.buttonCode) || (this.modalConfig && this.modalConfig.config && this.modalConfig.config.buttonCode);

      rules.forEach((v) => {
        const item = v;
        if (item.type === 'radio' && (item.dictCode || item.restful) && (!item.options || item.options.length === 0)) {
          this.getOptins(item.field);
        }
        if (item.type === 'checkbox' && (item.dictCode || item.restful) && (!item.options || item.options.length === 0)) {
          this.getOptins(item.field);
        }
        if (item.type === 'select') {
          item.on['visible-change'] = (e) => {
            if (!e) {
              const rule = this.getRule(item.field);
              const ruleValue = this.getFieldValue(item.field);
              const selected = rule.options.find((k) => k.value === ruleValue);
              if (item.on.getLabel) {
                item.on.getLabel((selected && selected.label) || '');
              }
              if (item.on.getItem) {
                item.on.getItem(selected || {});
              }
            }
          };
          item.on.clear = () => {
            if (item.on.getLabel) {
              item.on.getLabel('');
            }
            if (item.on.getItem) {
              item.on.getItem({});
            }
          };
        }
        if (
          item.type === 'select'
            && !isEmpty(item.value)
            && (item.restful || item.dictCode)
        ) {
          this.getOptins(item.field);
        }
        if (item.type === 'select' && item.props.remote) {
          item.props.remoteMethod = (query) => this.remoteMethod(query, item.field);
        }
        if (item.type === 'cascader' && item.value && item.restful) {
          this.getCascaderOptions(item.field);
        }
        if (item.type === 'FormSelectTree' && item.restful) {
          this.getTreeOptions(item.field);
        }
        if (item.type === 'upload') {
          const rule = this.getRule(item.field);
          if (rule.props.limitSize || rule.props.limitAccept) {
            const params = ['upload_limit'];
            request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
              if (res.success) {
                const uploadLimit = res.result.upload_limit || [];
                const limit = uploadLimit.find((a) => a.dictCode === rule.props.limitSize);
                const accept = uploadLimit.find((a) => a.dictCode === rule.props.limitAccept);

                const file = this.getRule(item.field);
                if (accept.dictValue) {
                  accept.dictValue = `.${accept.dictValue.split(',').join(',.')}`;
                }
                file.props = {
                  ...file.props,
                  fileSize: limit ? limit.dictValue : '2',
                  accept: accept ? accept.dictValue : '.jpg,.png',
                };
              }
            });
          }
          rule.props = {
            fileSize: '2',
            accept: '.jpg,.png',
            ...rule.props,
            beforeUpload: (res) => {
              console.log(rule.props);
              const suffix = res.name.split('.')[res.name.split('.').length - 1];
              if (!rule.props.accept.includes(suffix) && !rule.props.accept.includes('*')) {
                this.$message.error(`请上传${rule.props.accept}格式文件`);
                return false;
              }
              this.beforeFormCallback(item, res);
              const file = this.getRule(item.field);
              if (file.props.fileSize) {
                if ((res.size / (1024 * 1024)) > file.props.fileSize) {
                  this.$message.error(`文件大小超过${file.props.fileSize}M`);
                }
                return (res.size / (1024 * 1024)) <= file.props.fileSize;
              }
              return true;
            },
            onSuccess: (res) => {
              if (rule.props.limit === 1) {
                if (rule.props.uploadType === 'image') {
                  rule.value = res.result[0].url;
                }
                rule.props.fileList = res.result;
              } else {
                if (rule.props.uploadType === 'image') {
                  rule.value.push(res.result[0].url);
                }
                const tempFile = res.result.map((k) => ({
                  name: k.fileName,
                  ...k,
                }));
                if (rule.props.fileList && rule.props.fileList.length > 0) {
                  rule.props.fileList = rule.props.fileList.concat(tempFile);
                } else {
                  rule.props.fileList = tempFile;
                }
              }
              this.formCallback(item, res);
            },
          };
        }
        // if (item.type === 'group' && item.props.rules.length > 0) {
        //   this.initMountedRuleData(item.props.rules);
        // }
        if (buttonCode === 'edit' || code === 'edit') {
          if (item.visibleInEdit === 'false') {
            this.hiddenFields(true, item.field);
          }
        }
        if (buttonCode === 'view' || code === 'view') {
          if (item.visibleInLook === 'false') {
            this.hiddenFields(true, item.field);
          }
        }
        mountedRule.push(item);
        return true;
      });
      // console.log(mountedRule);
      return mountedRule;
    },
    // 初始化rule
    initrule() {
      this.rule = this.initRuleData(this.rule);
    },
    // 初始化Rule数据
    initMountedRule() {
      this.rule = this.initMountedRuleData(this.rule);
      if (this.formConfig.buttonCode === 'view' || this.formConfig.code === 'view') {
        setTimeout(() => {
          document.getElementsByClassName('el-icon-delete').forEach((d) => {
            const deleteItem = d;
            if (deleteItem.parentElement.className === 'fc-upload-cover') {
              deleteItem.style.display = 'none';
            }
          });
          document.getElementsByClassName('fc-upload-btn').forEach((f) => {
            const uploadBtn = f;
            uploadBtn.style.display = 'none';
          });
        }, 100);
      }
    },
    // 提交
    async submit() {
      this.$emit('submit', await this.getFormData());
    },
    // 在某个字段之前插入新的字段
    prepend(rules, field) {
      rules.forEach((item) => {
        this.fApi.prepend(item, field, false);
      });
      this.initrule();
      this.$nextTick(() => {
        this.initMountedRule();
      });
    },
    // 在某个字段之后插入新的字段
    append(rules, field) {
      rules.forEach((item) => {
        this.fApi.append(item, field, false);
      });
      this.initrule();
      this.$nextTick(() => {
        this.initMountedRule();
      });
    },
    // rule变化后更改rules
    changeRules() {
      if (this.rules && this.rules.length > 0) {
        this.ruless = this.rule;
      }
    },
    // 修改rule后重新加载rule
    reload(newRule) {
      this.fApi.reload(newRule);
      this.initrule();
      this.$nextTick(() => {
        this.initMountedRule();
      });
    },
    // 控件值变化回调，当前只有上传有设置该回调
    formCallback(item, res) {

    },
    // 控件值变化之前的回调，当前只有上传有设置该回调
    beforeFormCallback(item, res) {

    },
  },

  watch: {
    formConfig: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v && (v.code === 'view')) {
          this.buttons.submit = false;
          this.buttons.submitAudit = false;
        }
      },
    },
    modalConfig: {
      deep: true,
      immediate: true,
      handler(v) {
        // console.log(v);
        if (v && v.config && v.config.buttonCode === 'view') {
          this.buttons.submit = false;
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item__content > div {
  width: 100%;
}

/deep/ .el-dialog__wrapper {
  z-index: 999999 !important;
}

/deep/ .el-input--small .el-input__inner {
  line-height: 40px !important;
}

.dialog-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
  background-color: white;
  border-top: 1px solid rgb(228, 228, 228);
}

.footer {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}
</style>
