var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showView
      ? _c("div", {
          staticClass: "edit-view",
          domProps: { innerHTML: _vm._s(_vm.value) },
        })
      : _c("div", { ref: "editor", staticStyle: { "text-align": "left" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }