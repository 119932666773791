<template>
  <!-- 弹框 -->
  <Modal :modalConfig="modalConfig" ref="modalForm" @onClose="closeModal">
    <component
      :is="formName"
      @onClose="closeModal"
      @onGetSelect="onGetSelect"
      :formConfig="formConfig"
    />
  </Modal>
</template>

<script>
import Modal from '../modal';
import Form from './form_select.vue';

export default {
  name: 'SelectConfig',
  components: {
    Modal,
    Form,
  },
  props: {
    value: Object,
    beforeClose: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      modalConfig: {
        title: '选择',
      }, // 弹框信息
      formConfig: {}, // 弹框数据
      formName: 'Form',
    };
  },
  created() {},
  methods: {
    onGetSelect(val) {
      let list = [];

      if (!this.formConfig.noReset) {
        list = this.getReset(val, this.formConfig.data, this.formConfig.idKey);
      } else {
        list = val;
      }

      if (!this.beforeClose(list)) return false;

      this.$emit('onGetSelect', list);
      this.closeModal();
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = JSON.parse(JSON.stringify(list2));
      list1.forEach((v1) => {
        let notRepeat = true;
        const rowData = v1;
        for (const v2 in list2) {
          if (v1[key] === list2[v2][key]) {
            notRepeat = true;
            return false;
          }
        }
        if (notRepeat) {
          list.push(rowData);
        }
        return rowData;
      });
      return list;
    },
    // 打开弹框
    openSelectModal(params) {
      const data = params;
      data.selectionList = this.getSelection(params.data, params.idKey);

      this.formConfig = data;
      this.openModal();
    },
    // 获取选择项数据
    getSelection(list) {
      const selectionList = [];
      if (Array.isArray(list)) {
        list.forEach((v) => {
          selectionList.push(v);
        });
      }
      console.log('数据', selectionList);
      return selectionList;
    },
    // 打开弹窗
    openModal() {
      this.modalConfig = {
        ...this.modalConfig,
        ...{ visible: true, type: 'Modal' },
      };
    },
    // 关闭弹窗
    closeModal() {
      this.modalConfig = { ...this.modalConfig, visible: false };
    },
  },
};
</script>

<style lang="less" scoped></style>
