var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-upload",
            {
              staticClass: "upload-demo",
              attrs: {
                action: "/upload/uploadController/uploadOssList",
                headers: {
                  loginUserToken: _vm.Storage.l.get("TokenKey"),
                },
                "on-progress": _vm.changeProgress,
                "show-file-list": false,
                "before-upload": _vm.beforeUpload,
                "on-preview": _vm.handlePreview,
                "before-remove": _vm.beforeRemove,
                "on-success": _vm.handleSuccess,
                limit: _vm.limit,
                accept: _vm.accept || "",
                "on-exceed": _vm.handleExceed,
                "file-list": _vm.fileList,
              },
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传"),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.fileMsg
                          ? _vm.fileMsg
                          : "只能上传jpg/png文件，且不超过100M"
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "div",
                { attrs: { slot: "tip" }, slot: "tip" },
                [
                  _vm._l(_vm.fileList, function (item, index) {
                    return _c("div", { key: index, staticClass: "file-list" }, [
                      _c("i", { staticClass: "el-icon-tickets" }),
                      _c("div", { staticClass: "file-name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "/upload/downloadController/download?objectName=" +
                              item.objectName,
                            download: item.name,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-download" })]
                      ),
                      _c("i", {
                        staticClass: "el-icon-close",
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(item)
                          },
                        },
                      }),
                    ])
                  }),
                  _vm.onUpload
                    ? _c("el-progress", { attrs: { percentage: _vm.progress } })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "file-list-detail" },
            _vm._l(_vm.fileList, function (item, index) {
              return _c("div", { key: index, staticClass: "file-list" }, [
                _c("i", { staticClass: "el-icon-tickets" }),
                _c("div", { staticClass: "file-name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/upload/downloadController/download?objectName=" +
                        item.objectName,
                      download: item.name,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-download" })]
                ),
              ])
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }