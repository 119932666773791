var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.modalConfig.type,
    {
      tag: "component",
      attrs: { modalConfig: _vm.modalConfig },
      on: { onClose: _vm.closeModal, onOk: _vm.onOk },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }