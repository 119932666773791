// import request from '../../utils/request';
import _ from 'xe-utils';
import request from '@/found/utils/request';
import TableTools from '@/found/plugins/TableTools';
import tablePage from '../table_page';
import LogTable from '../log_table';
// import FormCofing from '../../plugins/formCofing';
import Form from '../form';
import Modal from '../modal';
import configs from './data';
// let self
export default {
  name: 'BasePage',
  extends: tablePage,
  data() {
    const config = configs.bind(this);
    return {
      beforeList: false,
      configs: config(),
      listCode: '',
      layout: {
        noTop: false, noCell: false,
      },
      isInit: false,
      parentCode: undefined,
    };
  },
  provide() {
    return {
      parentCode: this.parentCode || this.propsParentCode,
    };
  },
  props: {
    selectedRows: Array,
    valueKey: String,
    code: String,
    propsParentCode: String,
    value: Array,
    paramsProps: Object,
    noTop: Boolean,
    noCell: Boolean,
    noSearch: Boolean,
    showSubmit: {
      type: Boolean,
      default: true,
    },
    setToolsBtnProps: Function,
    setRowBtnProps: Function,
    setSearchFieldProps: Function,
    listTemplateCode: String,
    FormTemplateCode: String,
    checkboxType: String,
    useLocalData: Boolean,
    isAllowCheckboxAll: {
      type: Boolean,
      default: true,
    },
    dataSource: Array,
    columns: {
      default: null,
    },
    search: {
      default: null,
    },
    disabledRow: {
      default: null,
    },
    fetchData: {
      default: null,
    },
    getSelectionChange: {
      default: null,
    },
  },
  //
  // computed: {
  //   dataSource: {
  //     get() {
  //       return this.value;
  //     },
  //     set(v) {
  //       this.$emit('value:update', v);
  //     },
  //   },
  // },
  watch: {
    dataSource: {
      deep: true,
      immediate: true,
      handler(v) {
        if (_.isArray(v)) {
          this.$emit('value', v);
          this.getData();
        }
      },
    },
    paramsProps: {
      deep: true,
      immediate: true,
      handler(v) {
        if (!_.isEmpty(v)) {
          this.getData();
        }
      },
    },
    // selectRow:{
    //   deep:true,
    //   immediate:true,
    //   handler(v){
    //     console.log(v)
    //     this.$emit('input',v)
    //     this.$emit('change',v)
    //   }
    // }
    // tableLoading: {
    //   immediate: true,
    //   handler(v) {
    //     if (v) {
    //
    //     }
    //   }
    // }
    search: {
      deep: true,
      immediate: true,
      handler(v) {
        if (v) {
          Object.assign(this.configs.formConfig, { item: [...this.search] });
        }
      },
    },
  },
  components: {
    Form, Modal, LogTable,
  },

  created() {
    this.getBasePageConfigList(this.listCode || this.listTemplateCode || this.$route.query.functionCode || this.$route.query.function_code);
    this.checkStrictly = !this.isAllowCheckboxAll;
    if (this.valueKey) {
      this.configs.tableConfig.idKey = this.valueKey;
    }
  },
  methods: {

    // 选择分页数量
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.currentPage = 1;
      this.$emit('page-change', this.tablePage);
      this.getData();
    },
    // 选择页数
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
      this.$emit('page-change', this.tablePage);
      this.getData();
    },

    handleSelectionChange(selection) {
      this.selectRow = selection;
      if (this.getSelectionChange) this.getSelectionChange(selection);
    },
    checkboxAll(val) {
      if (!this.isAllowCheckboxAll) return;
      // 获取选择依据
      const { idKey } = this.configs.tableConfig;
      this.selection = val.records.map((v) => v[idKey]);
      this.selectRow = val.records;
      this.afterCheckAllChange(val);
    },
    afterCheckboxChange() {
      this.$emit('input', this.selectRow);
      this.$emit('change', this.selectRow);
    },
    afterCheckAllChange() {
      this.$emit('input', this.selectRow);
      this.$emit('change', this.selectRow);
    },
    afterRadioChange() {
      this.$emit('input', this.selectRow);
      this.$emit('change', this.selectRow);
    },
    closeModal() {
      this.modalConfig = {};
      this.modalFormData = {};
      this.formConfig = {};
      this.modalFormData = {}; // 清空弹窗中表单的数据
      this.modalConfig = { ...this.modalConfig, visible: false, type: undefined };
    },
    /**
     * 根据页面规则设置表单
     */
    resetFormFormat() {

    },

    getTableData() {
      if (this.beforeGetList()) {
        const params = {
          ...this.params,
          ...this.formData,
          pageNum: this.tablePage.currentPage,
          pageSize: this.tablePage.pageSize,
        };
        // 过滤选中数据
        if (this.valueKey && this.selectedRows) {
          Object.assign(params, {
            notInCodeList: this.selectedRows.map((row) => row[this.valueKey]),
          });
        }
        Object.assign(params, this.paramsProps ? this.paramsProps : {});
        if (!this.requestUrl) return Promise.resolve();
        this.selection = [];
        this.selectRow = [];
        if (this.dataSource && this.dataSource instanceof Array) {
          this.tableData = this.dataSource;
        } else {
          this.tableLoading = true;
          return request.post(this.requestUrl, { ...params }, {
            headers: {
              functionCode: this.headerFunctionCode,
            },
          }).then((res) => {
            if (res.success) {
              this.tableLoading = false;
              if (res.result.data) {
                this.tableData = res.result.data;
                this.tablePage.totalResult = res.result.count;
              } else if (res.result.length >= 0) {
                this.tableData = res.result;
              }
              this.afterGetList();
            }
          });
        }
      }
    },
    async getLocalTableData() {
      if (this.columns && this.columns.length && (!this.configs.columns.length)) {
        this.configs.columns = this.columns;
      }
      const res = await this.fetchData(this.formData);
      if (res.success) {
        this.tableLoading = false;
        if (res.result.data) {
          this.tableData = res.result.data;
          this.tablePage.totalResult = res.result.count;
        } else if (res.result.length >= 0) {
          this.tableData = res.result;
        }
      }
    },
    getList() {
      if (this.fetchData && typeof this.fetchData === 'function') {
        return this.getLocalTableData();
      }
      return this.getTableData();
    },
    async getData() {
      this.tableLoading = true;

      if (this.useLocalData) {
        this.tableData = this.dataSource || [];
        this.beforeList = false;
      } else if (this.paramsProps) {
        for (const val in Object.values(this.paramsProps)) {
          if ([undefined, '', false, null].includes(val)) {
            this.beforeList = false;
          } else {
            this.beforeList = true;
          }
        }
      } else {
        this.beforeList = true;
      }
      await this.getList();
      this.tableLoading = false;
    },
    afterGetList() {
      this.$nextTick(() => {
        if (this.$refs.xTable) {
          const selection = [];
          this.$refs.xTable.setAllCheckboxRow(false);
          if (this.selectedRows && this.valueKey) {
            const data = this.value ? [...this.value, ...this.selectedRows] : this.selectedRows;
            this.tableData.forEach((row) => {
              if (data.find((i) => i[this.valueKey] === row[this.valueKey])) {
                selection.push(row);
              }
            });
          } else if (this.value && !this.selectedRows) {
            this.value.forEach((r) => {
              const row = this.tableData.find((w) => w.id === r.id);
              if (row) {
                selection.push(row);
              }
            });
          }
          // this.selection = selection.map(i=>i[this.valueKey])
          this.$refs.xTable.setCheckboxRow(selection, true);
        }
      });
    },
    /**
     * 获取tablePage的配置
     */

    async getBasePageConfigList(functionCode) {
      if (this.fetchData && typeof this.fetchData === 'function') {
        this.getData();
        return;
      }
      this.tableLoading = true;
      if (this.noCell !== false) {
        this.layout.noCell = this.noCell;
      }
      if (this.noTop !== false) {
        this.layout.noTop = this.noTop;
      }
      const parentCode = this.code || this.propsParentCode || this.parentCode;
      await this.getConfigList(functionCode, this.layout.noCell, this.layout.noTop, parentCode);
      this.setLocalColumn();
      this.beforeList = true;
      this.getData();
    },
    setLocalColumn: Function,
    /**
     * @override
     * @param arg
     * @returns {boolean}
     */
    beforeButtonClick() {
      // formFn = new FormCofing(self.FormTemplateCode || 'tpm_budget_year_form');
      // formFn.getFormRule().then((rule) => {
      //   self.configs.rule = rule.map((field) => this.setFormField(field));
      // });
      return true;
    },
    /**
     * 设置列
     * @param col
     */
    setColumn(col) {
      const json = {};
      if (this.checkboxType !== undefined && col.field === 'checkTypecheckbox') {
        json.field = this.checkboxType;
        json.type = this.checkboxType.replace('checkType', '');
      }
      return { ...col, ...json };
    },
    /**
     * 设置表单字段
     * @param field
     */
    setFormField(field) {
      return { ...field };
    },
    defaultFiedProps(field) {
      const json = this.setFormField(field);
      return {
        ...json,
        props: { ...json.props, clearable: true },
      };
    },
    /**
     * 设置工具栏按钮
     * @param btn
     */
    setToolsBtn(btn) {
      if (this.setToolsBtnProps) return this.setToolsBtnProps();
      return { ...btn };
    },
    /**
     * 设置数据表格行按钮
     * @param btn
     */
    setRowBtn(btn) {
      if (this.setRowBtnProps) return this.setRowBtnProps();
      return { ...btn };
    },

    /**
     * 设置搜索条件
     * @param btn
     */
    setSearchField(btn) {
      if (this.setSearchFieldProps) return this.setSearchFieldProps();
      return { ...btn };
    },

    setFormFieldDisabled(bool, fields, cover) {
      if (fields instanceof Array) {
        if (this.configs.modalConfig.visible) {
          this.$refs.modalForm.fApi.disabled(true, fields, cover);
          this.$refs.modalForm.fApi.reload();
        }
      } else {
        console.error('fields类型错误！');
      }
    },

    /**
     * @override
     * @returns {boolean}
     */
    beforeSearchEvent() {
      if (this.fetchData && typeof this.fetchData === 'function') {
        this.getData();
        return false;
      }
      return true;
    },
    /**
     * @override
     * @returns {boolean}
     */
    beforeModalClick({ val, row }, rule) {
      return { val, row, rule };
    },
    /**
     * @override
     * @returns {boolean}
     */
    beforeGetList() {
      return this.beforeList;
    },
    /**
     * @override
     * @returns {boolean}
     */
    beforeDeleteClick() {
      return true;
    },
    beforeClickEvent() {
      return true;
    },
    beforeCellClick(val, row) {
      return { val, row };
    },
    // 单元格查看点击
    cellClick({ row: rowData, column }) {
      const config = this.cellClickConfig;
      if (config && column.className === 'formatter-view') {
        const res = this.beforeCellClick({
          code: 'view',
          buttonCode: 'view',
          name: '查看',
          buttonName: '查看',
          doCode: config ? config.functionCode : '',
          queryUrl: config ? config.queryUrl : '',
          buttonOperationType: 3,
          params: { id: rowData.id },
          parentCode: this.$store.state.menus.selectMenu,
        }, rowData);
        const { val, row } = res;
        for (const key of Object.keys(this.modalFormData)) {
          delete this.modalFormData[key];
        }
        if (val) {
          const Tools = new TableTools(val);
          this.formConfig = this.formConfig ? ({ ...this.formConfig, ...val }) : val;
          this.modalConfig = this.modalConfig ? ({ ...this.modalConfig, ...val }) : val;
          Tools.triggerClick(row, (rule, detail) => {
            if (detail) this.modalFormData = { ...row, ...this.formatData({ ...detail }) };
            this.modalClick(
              {
                val,
                row: { ...row, ...this.formatData({ ...detail }) },
              },
              rule,
            );
          });
        }
      } else if (column.className === 'formatter-view') {
        const res = this.beforeCellClick({
          code: 'view',
        }, rowData);
        const { val, row } = res;
        this.formConfig = this.formConfig ? ({ ...this.formConfig, code: 'view' }) : { code: 'view' };
        this.modalConfig = this.modalConfig ? ({ ...this.modalConfig, title: '查看' }) : { title: '查看' };
        this.modalClick({ val, row }, []);
      }
    },
    // 操作按钮事件
    buttonClick({ val }) {
      if (!this.beforeClickEvent({ val, row: this.selectRow })) return;
      for (const key of Object.keys(this.modalFormData)) {
        delete this.modalFormData[key];
      }
      if (val.code === 'export') {
        this.exportClick({ val });
        return;
      }
      const Tools = new TableTools(val);
      Tools.triggerClick(
        this.selectRow,
        (rule, detail) => {
          // if (detail) this.modalFormData = this.formatData({...detail})

          // Object.assign(this.modalFormData, this.formatData({ ...detail}));
          this.modalClick({ val, row: { ...this.selectRow, ...detail } }, rule);
        },
        this.getList,
      );
      return { ...val };
    },
    beforeClickCell({ val, row }) {
      const btn = { ...val };
      if (btn.code === 'process_log') {
        btn.params = {
          processNo: row.processCode,
        };
      }
      return { val: btn, row };
    },

    // 打开日志
    logClick({ val, row }) {
      this.modalConfig.title = '日志';
      this.originalName = this.formName;
      this.formName = 'LogTable';
      this.propsObjInData.selection = [row.id];
      this.openFull();
    },
    // 行操作事件
    clickCell({ val, row }) {
      if (!this.beforeClickEvent({ val, row })) return;
      for (const key of Object.keys(this.modalFormData)) {
        delete this.modalFormData[key];
      }
      this.formName = this.originalName || this.formName;
      if (val.code === 'log') {
        this.logClick({ val, row });
        return false;
      }
      this.originalName = '';
      const result = this.beforeClickCell({ val, row });
      if (!result) return;
      const Tools = new TableTools(result.val);

      Tools.triggerClick(
        result.row,
        (rule, detail) => {
          // if (detail) this.modalFormData = this.formatData({...detail})
          // Object.assign(this.modalFormData, this.formatData({ ...detail}));

          // console.log(this.formatData({...detail}));
          let newRule = [];
          if (!_.isEmpty(result.val.params)) {
            newRule = rule.map((i) => ({
              ...i,
              props: {
                ...i.props,
                params: result.val.params,
              },
            }));
          }
          this.modalClick({ val: result.val, row: { ...result.row, ...detail } }, newRule.length ? newRule : rule);
        },
        this.getList,
      );

      return { val, row };
    },

    // 弹框操作
    async modalClick({ val, row }, rule) {
      const self = this;
      // this.formConfig = {};
      const result = await this.beforeModalClick({ val, row }, rule);
      if (!result || !rule) return false;
      if (result.rule) {
        if (val.code !== 'add') {
          this.modalFormData = {
            ...(_.isEmpty(result.row)
              ? {}
              : (Array.isArray(result.row) && result.row[0]) || result.row || {}),
          };
        }
        self.modalConfig.config = result.val;
        self.modalConfig.title = result.val.name;
        self.configs.rule = result.rule.map((field) => this.defaultFiedProps(field));
        //
        if (val.code === 'process_log') {
          this.modalConfig.width = '60%';
          this.modalConfig.type = 'Drawer';
        //   this.formName = 'custom-process-log';
        }
      }
      this.openModal();
      return { val, row };
    },
    clickVisible({ row, val: { code } }) {
      // // 审批通过 approved
      // // 审批中 approving
      // // 活动关闭 closed
      // // 流程追回 interrupt
      // // 驳回 rejected
      // 审批状态
      const approveStatusArr = ['approved', 'approving', 'closed', 'interrupt', 'rejected'];
      const { approveStatus, processCode } = row;
      if (approveStatus !== undefined && approveStatusArr.includes(approveStatus)) {
        if (approveStatus && code === 'edit' && (approveStatus === 'interrupt' || approveStatus === 'closed' || approveStatus === 'approving' || approveStatus === 'approved')) {
          return false;
        }
        if (approveStatus && code === 'submit_approval' && (approveStatus === 'closed' || approveStatus === 'approving' || approveStatus === 'approved')) {
          return false;
        }
      }
      if (code === 'process_log' && !processCode) {
        return false;
      }
      return true;
    },
  },
};
