const routers = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Login" */ '../views/login'),
    meta: { title: '登录' },
  },
  {
    path: '/',
    redirect: 'home',
    component: () => import(/* webpackChunkName: "Layout" */ '../components/layout'),
    children: [
      {
        path: '/form-design',
        name: 'form-design',
        component: () => import(/* webpackChunkName: "Home" */ '../components/formDesign'),
        meta: { title: '表单设计' },
      },
      {
        path: '/404',
        component: () => import(/* webpackChunkName: "404" */ '../views/error_page/page404'),
        meta: { title: '404' },
      },
      {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/homeNew/index'),
        meta: { title: '首页' },
      },
      {
        path: '/journal',
        name: 'journal',
        component: () => import(/* webpackChunkName: "journal" */ '../views/journal'),
        meta: { title: '查看日志' },
      },
    ],
  },
];

export default routers;
