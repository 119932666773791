<script>
import Form, { formCreate } from '../form';
import OrgSelect from './org_select.vue';

formCreate.component('OrgSelect', OrgSelect);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      selectRow: [],
      rule: [
        {
          type: 'OrgSelect',
          field: 'menu',
          title: '',
          props: {
            formSelectConfig: {},
          },
          on: {
            change: (val) => this.checkTable(val),
          },
          value: {
            tableData: [],
          },
        },
      ],
    };
  },
  mounted() {
    this.updateRules({
      menu: {
        props: {
          formSelectConfig: { ...this.formConfig },
        },
      },
    });
  },
  methods: {
    submit() {
      this.$emit('onGetSelect', this.selectRow);
    },
    checkTable(val) {
      this.selectRow = val;
    },
  },
};
</script>
