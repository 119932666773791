<template>
  <!-- 这里使用了elemntUI的类名，如果没有安装elmentUI则自己自定义样式 -->
  <div class="pw_input_cp el-input el-input--small" :class="disabled&&'is-disabled'">
    <input
      class="el-input__inner"
      :placeholder="placeholder"
      ref="input"
      :disabled="disabled"
      @input="handleInput"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
      @compositionstart="handleCompositionStart"
      @compositionend="handleCompositionEnd"
    />
    <span
      class="el-input__suffix"
      v-show="showClear"
      @mouseover="mouseClearOver"
      @mouseout="mouseClearOut"
      @click="clearClick"
    >
      <span class="el-input__suffix-inner">
        <i class="el-input__icon el-icon-circle-close el-input__clear"></i>
      </span>
      <i class="el-input__icon el-input__validateIcon el-icon-circle-check"></i>
    </span>
  </div>
</template>

<script>
// 自定义密码输入框
// input元素光标操作
class CursorPosition {
  constructor(_inputEl) {
    this._inputEl = _inputEl;
  }

  // 获取光标的位置 前，后，以及中间字符
  get() {
    const rangeData = { text: '', start: 0, end: 0 };
    if (this._inputEl.setSelectionRange) {
      // W3C
      this._inputEl.focus();
      rangeData.start = this._inputEl.selectionStart;
      rangeData.end = this._inputEl.selectionEnd;
      rangeData.text = rangeData.start !== rangeData.end
        ? this._inputEl.value.substring(rangeData.start, rangeData.end)
        : '';
    } else if (document.selection) {
      // IE
      this._inputEl.focus();
      let i;
      const oS = document.selection.createRange();
      const oR = document.body.createTextRange();
      oR.moveToElementText(this._inputEl);

      rangeData.text = oS.text;
      rangeData.bookmark = oS.getBookmark();
      for (
        i = 0;
        oR.compareEndPoints('StartToStart', oS) < 0 && oS.moveStart('character', -1) !== 0;
        // eslint-disable-next-line no-plusplus
        i++
      ) {
        if (this._inputEl.value.charAt(i) === '\r') {
          // eslint-disable-next-line no-plusplus
          i++;
        }
      }
      rangeData.start = i;
      rangeData.end = rangeData.text.length + rangeData.start;
    }

    return rangeData;
  }

  // 写入光标的位置
  set(rangeData) {
    let oR;
    if (!rangeData) {
      console.log('You must get cursor position first.');
    }
    this._inputEl.focus();
    if (this._inputEl.setSelectionRange) {
      // W3C
      this._inputEl.setSelectionRange(rangeData.start, rangeData.end);
    } else if (this._inputEl.createTextRange) {
      // IE
      oR = this._inputEl.createTextRange();
      if (this._inputEl.value.length === rangeData.start) {
        oR.collapse(false);
        oR.select();
      } else {
        oR.moveToBookmark(rangeData.bookmark);
        oR.select();
      }
    }
  }
}
export default {
  name: 'Pw_input_cp',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    placeholder: String,
  },
  data() {
    return {
      symbol: '✳', // 自定义的密码符号
      pwd: '', // 密码明文数据
      inputEl: null, // input元素
      isComposing: false, // 输入框是否还在输入（记录输入框输入的是虚拟文本还是已确定文本）
      showClear: false,
      onClear: false,
    };
  },
  mounted() {
    this.inputEl = this.$refs.input;
  },
  watch: {
    value() {
      this.pwd = this.value;
      this.inputDataConversion(this.pwd);
    },
  },
  methods: {
    mouseOver(e) {
      this.showClear = true;
    },
    mouseOut(e) {
      // if (this.onClear) { this.showClear = true; } else {
      this.showClear = false;
      // }
    },
    mouseClearOver() {
      this.showClear = true;
    },
    mouseClearOut() {
      this.onClear = false;
      this.showClear = false;
    },
    clearClick() {
      this.$emit('input', '');
    },
    inputDataConversion(value) {
      // 输入框里的数据转换，将123转为***
      if (!value) {
        this.inputEl.value = '';
        return;
      }
      let data = '';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value.length; i++) {
        data += this.symbol;
      }
      this.inputEl.value = data;
    },
    pwdSetData(positionIndex, value) {
      // 写入原始数据
      const _pwd = value.split(this.symbol).join('');
      if (_pwd) {
        const index = this.pwd.length - (value.length - positionIndex.end);
        this.pwd = this.pwd.slice(0, positionIndex.end - _pwd.length) + _pwd + this.pwd.slice(index);
      } else {
        this.pwd = this.pwd.slice(0, positionIndex.end)
          + this.pwd.slice(positionIndex.end + this.pwd.length - value.length);
      }
    },
    handleInput(e) {
      // 输入值变化后执行
      // 撰写期间不应发出输入
      if (this.isComposing) return;
      const cursorPosition = new CursorPosition(this.inputEl);
      const positionIndex = cursorPosition.get();
      const { value } = e.target; // 整个输入框的值
      this.pwdSetData(positionIndex, value);
      this.inputDataConversion(value);
      cursorPosition.set(positionIndex, this.inputEl);
      this.$emit('input', this.pwd);
    },
    handleCompositionStart() {
      // 表示正在写
      this.isComposing = true;
    },
    handleCompositionEnd(e) {
      if (this.isComposing) {
        this.isComposing = false;
        // handleCompositionEnd比handleInput后执行，避免isComposing还为true时handleInput无法执行正确逻辑
        this.handleInput(e);
      }
    },
  },
};
</script>

<style scoped lang="less">
.pw_input_cp {
  width: 100%;
}
</style>
