var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "process_log" }, [
    _c("div", { staticClass: "process_log-box" }, [
      _c(
        "div",
        {
          staticStyle: {
            "background-color": "#ffffff",
            height: "auto",
            "padding-top": "10px",
          },
        },
        _vm._l(_vm.processLogDataArr, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "approval row",
              staticStyle: { "margin-top": "0", "margin-bottom": "10px" },
            },
            [
              _c("div", { staticClass: "approval-title" }, [
                _c("span", [_vm._v(" " + _vm._s(item.logDate) + " "), _c("i")]),
              ]),
              _c(
                "div",
                { staticClass: "approval-box row" },
                _vm._l(item.optRecordRspVOList, function (item2, index2) {
                  return _c("div", { key: index2 }, [
                    _c("div", { staticClass: "title" }, [
                      _c("div", { staticClass: "time" }, [
                        _vm._v(" " + _vm._s(item2.createTime)),
                      ]),
                      _c("span", { staticClass: "fl" }, [
                        _vm._v("操作人员: "),
                        _c("span", { staticClass: "c" }, [
                          _vm._v(_vm._s(item2.userName)),
                        ]),
                      ]),
                      _c("span", { staticClass: "fr" }, [
                        _vm._v(
                          _vm._s(item.logDate) + " " + _vm._s(item2.createTime)
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ph10 bom" },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 10 } },
                          [
                            _c("el-col", _vm._b({}, "el-col", _vm.col, false), [
                              _c("span", { staticClass: "bn" }, [
                                _vm._v("处理岗位："),
                                _c("span", { staticClass: "c" }, [
                                  _vm._v(_vm._s(item2.positionName)),
                                ]),
                              ]),
                            ]),
                            _c("el-col", _vm._b({}, "el-col", _vm.col, false), [
                              _c("span", { staticClass: "bn" }, [
                                _vm._v("操作动作："),
                                _c("span", { staticClass: "c" }, [
                                  _c("span", {}, [
                                    _vm._v(_vm._s(item2.operationStr)),
                                  ]),
                                ]),
                              ]),
                            ]),
                            _c("el-col", _vm._b({}, "el-col", _vm.col, false), [
                              _c("span", { staticClass: "bn" }, [
                                _vm._v("节点名称："),
                                _c("span", { staticClass: "c" }, [
                                  _vm._v(_vm._s(item2.taskName)),
                                ]),
                              ]),
                            ]),
                            _c("el-col", _vm._b({}, "el-col", _vm.col, false), [
                              _c("span", { staticClass: "bn" }, [
                                _vm._v("流程名称："),
                                _c("span", { staticClass: "c" }, [
                                  _vm._v(_vm._s(item2.processName)),
                                ]),
                              ]),
                            ]),
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c("span", { staticClass: "bn" }, [
                                _vm._v("操作描述："),
                                _c("span", { staticClass: "c" }, [
                                  _vm._v(_vm._s(item2.content)),
                                ]),
                              ]),
                            ]),
                            item2.fileList && item2.fileList.length
                              ? _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "white-space": "nowrap",
                                          },
                                        },
                                        [_vm._v("审批文件：")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "c",
                                          staticStyle: {
                                            flex: "1",
                                            width: "0",
                                          },
                                        },
                                        [
                                          _c("FileList", {
                                            attrs: {
                                              "file-list": _vm._f("getParams")(
                                                item2.fileList
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }