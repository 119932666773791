<template>
  <div id="app">
    <!-- <keep-alive v-if="isRouterAlive"> -->
      <router-view v-if="isRouterAlive" />
    <!-- </keep-alive> -->
  </div>
</template>
<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    this.$store.dispatch('SET_SELECT_CONFIG');
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less">
.el-input.is-disabled .el-input__inner {
    color: #666 !important;
}
.el-textarea.is-disabled .el-textarea__inner {
   color: #666 !important;
}
.el-range-editor.is-disabled input{
   color: #666 !important;
}
.vxe-form--item-trigger-node{
  min-width: 90px !important;
  color: #333 !important;
  font-weight: bold !important;
}
.vxe-form--item-trigger-text{
  font-size: 13px;
}
.vxe-form--item-trigger-icon{
  font-size: 13px;
}
</style>
