import routers from './routers';

const routerSubContext = require.context('../views', true, /router\/index\.js$/);

const router = [...routers];

/**
 * 路由数组处理
 */
const flatRouter = (ruleList, functionRouter, basePath = '') => {
  functionRouter.forEach((v) => {
    const item = v;
    const ruleItem = {
      component: item.component,
      meta: item.meta,
      name: item.name,
      path: item.path.startsWith('/') ? item.path : `${basePath}/${item.path}`,
      redirect: item.redirect,
    };
    ruleList.push(ruleItem);
    if (item.children && item.children.length > 0) {
      flatRouter(ruleList, item.children, item.path);
    }
  });
};

routerSubContext.keys().forEach((route) => {
  const routerModule = routerSubContext(route);
  router[1].children.push(...(routerModule.default || routerModule));
});
const functionRouter = router[1].children;
const ruleList = [];
flatRouter(ruleList, functionRouter);
router[1].children = ruleList;
const FoundRouter = router;
export default FoundRouter;
