<script>
import request from '../../../../utils/request';
import tablePageListBase from '../../components/table_page_list_base';
import TablePage from './table';

export default {
  extends: tablePageListBase,
  components: {
    TablePage,
  },
  data() {
    return {
      placeholder: '请输入搜索的直营体系',
      title: '直营体系',
      restful: '/kms/kmstenantrydirectcustomerorg/list',
      params: {
        pageSize: 100,
      },
      defaultProps: {
        label: 'bsDirectSystemName',
        value: 'bsDirectSystemCode',
      },
      headers: { functionCode: 'direct_marketing_system_list' },
      isAdd: true,
      searchName: 'bsDirectSystemName',
      operationBtns: [
        // {
        //   command: 'enable',
        //   btntext: '启用',
        // },
        // {
        //   command: 'disabled',
        //   btntext: '禁用',
        // },
        // {
        //   command: 'remove',
        //   btntext: '移除',
        // },
      ],
    };
  },
  methods: {
    /**
     * 操作按钮
     * @params command 操作按钮的command
     * @params node 操作行的node
     */
    handleCommand({ command, node }) {
      console.log(command);
      if (command === 'enable') {
        request.post('/kms/kmstenantrydirectcustomerorg/enable', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
          }
        });
      }
      if (command === 'disabled') {
        request.post('/kms/kmstenantrydirectcustomerorg/disable', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
          }
        });
      }
      if (command === 'remove') {
        request.post('/kms/kmstenantrydirectcustomerorg/delete', [node.data.id]).then((res) => {
          if (res.success) {
            this.$message.success('操作成功！');
          }
        });
      }
    },
  },
};
</script>
