<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [
        {
          type: 'select',
          field: 'directId',
          title: '直营体系名称',
          restful: '/kms/kmsAdmin/tenantry/direct/list',
          props: {
            loading: false,
            clearable: true,
            filterable: true,
            remote: true,
            remoteParams: 'directName',
          },
          on: {
            change: (e) => this.bsDirectIdChange(e),
          },
          optionsKey: { label: 'directName', value: 'directId' },
        },
        {
          type: 'hidden',
          title: '直营体系名称',
          field: 'bsDirectSystemName',
        },
        {
          type: 'select',
          field: 'customerOrgCode',
          title: '客户组织名称',
          restful: '/mdm/mdmCusOrgController/select',
          props: {
            loading: false,
            clearable: true,
            filterable: true,
            remote: true,
            remoteParams: 'customerOrgName',
          },
          on: {
            change: (e) => this.customerOrgChange(e),
          },
          optionsKey: { label: 'customerOrgName', value: 'customerOrgCode' },
        },
        {
          type: 'hidden',
          title: '客户组织Id',
          field: 'customerOrgId',
        },
        {
          type: 'hidden',
          title: '客户组织名称',
          field: 'customerOrgName',
        },
        {
          type: 'input',
          title: '直营体系编码',
          field: 'bsDirectSystemCode',
          props: {
            clearable: true,
          },
          validate: [
            {
              trigger: 'blur',
              pattern: '^[a-zA-Z0-9]*$',
              message: '仅能输入英文和数字的组合',
            },
          ],
        },
      ],
    };
  },
  mounted() {

  },
  methods: {
    // 直营体系change事件
    bsDirectIdChange(e) {
      const directId = this.getRule('directId');
      const selectedData = directId.options.find((item) => item.directId === e);
      this.setValue({
        bsDirectSystemName: selectedData.directName,
      });
    },
    customerOrgChange(e) {
      const customerOrgCode = this.getRule('customerOrgCode');
      const selectedData = customerOrgCode.options.find((item) => item.customerOrgCode === e);
      console.log(selectedData);
      this.setValue({
        customerOrgId: selectedData.id,
        customerOrgName: selectedData.customerOrgName,
      });
    },
    submit() {
      const formData = this.getFormData();
      request.post('/kms/kmstenantrydirectcustomerorg/save', formData).then((res) => {
        if (res.success) {
          this.$message.success('操作成功');
          this.$emit('onClose');
          this.$emit('reset');
        }
      });
    },
  },
};
</script>

<style>

</style>
