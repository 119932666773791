/*
 * @Author: sungang
 * @Date: 2020-11-24 10:16:36
 * @LastEditors: chenlei
 * @LastEditTime: 2020-11-25 09:46:12
 * @Description:
 */
export default [
  {
    path: '/kms_new_center',
    name: 'kms_new',
    meta: {
      title: 'kms_new',
    },
    redirect: '/kms_new_center/sold_to_party',
    component: { render: (e) => e('router-view') },
    children: [

      {
        path: 'sold_to_party',
        name: 'kms_sold_to_party',
        component: () => import(/* webpackChunkName: "kms_new_center/sold_to_party" */ '../views/sold_to_party'),
        meta: { title: '售达方' },
      },
      {
        path: 'new_customer_account',
        name: 'new_customer_account',
        component: () => import(/* webpackChunkName: "kms_new_center/new_customer_account" */ '../views/customer_account'),
        meta: { title: '客户账号管理' },
      },
      {
        path: 'new_on_product',
        name: 'new_on_product',
        component: () => import(/* webpackChunkName: "kms_new_center/new_on_product" */ '../views/new_on_product'),
        meta: { title: '上架产品' },
      },
      {
        path: 'new_grasping_rules',
        name: 'new_grasping_rules',
        component: () => import(/* webpackChunkName: "kms_new_center/new_grasping_rules" */ '../views/grasping_rules'),
        meta: { title: '单据抓取规则' },
      },
      {
        path: 'direct_stores',
        name: 'direct_stores',
        component: () => import(/* webpackChunkName: "kms_new_center/direct_stores" */ '../views/direct_stores'),
        meta: { title: '直营门店' },
      },
      {
        path: 'document_display',
        name: 'document_display',
        component: () => import(/* webpackChunkName: "kms_new_center/document_display" */ '../views/order_display/document_display'),
        meta: { title: '订单' },
      },
      {
        path: 'acceptance_order',
        name: 'acceptance_order',
        component: () => import(/* webpackChunkName: "kms_new_center/acceptance_order" */ '../views/order_display/acceptance_order'),
        meta: { title: '验收单' },
      },
      {
        path: 'return_order',
        name: 'return_order',
        component: () => import(/* webpackChunkName: "kms_new_center/return_order" */ '../views/order_display/return_order'),
        meta: { title: '退货单' },
      },
      {
        path: 'KA_supermarket',
        name: 'KA_supermarket',
        component: () => import(/* webpackChunkName: "kms_new_center/KA_supermarket" */ '../views/backstage_manage/KA_supermarket'),
        meta: { title: '商超管理' },
      },
      {
        path: 'supermarket_deport',
        name: 'supermarket_deport',
        component: () => import(/* webpackChunkName: "kms_new_center/supermarket_deport" */ '../views/backstage_manage/supermarket_deport'),
        meta: { title: '商超门店' },
      },
      {
        path: 'supermarket_area',
        name: 'supermarket_area',
        component: () => import(/* webpackChunkName: "kms_new_center/supermarket_area" */ '../views/backstage_manage/supermarket_area'),
        meta: { title: '商超区域' },
      },
      {
        path: 'order_download',
        name: 'order_download',
        component: () => import(/* webpackChunkName: "kms_new_center/order_download" */ '../views/backstage_manage/order_download'),
        meta: { title: '单据下载' },
      },
      {
        path: 'interface_parameters',
        name: 'interface_parameters',
        component: () => import(/* webpackChunkName: "kms_new_center/interface_parameters" */ '../views/backstage_manage/interface_parameters'),
        meta: { title: '接口参数' },
      },
      {
        path: 'direct_marketing_system',
        name: 'direct_marketing_system',
        component: () => import(/* webpackChunkName: "kms_new_center/direct_marketing_system" */ '../views/direct_marketing_system'),
        meta: { title: '直营体系' },
      },
      {
        path: 'financial_audit_template',
        name: 'financial_audit_template',
        component: () => import(/* webpackChunkName: "kms_new_center/financial_audit_template" */ '../views/finance/financial_auditing/financial_audit_template'),
        meta: { title: '财务稽核模板' },
      },
      {
        path: 'audit_matching',
        name: 'audit_matching',
        component: () => import(/* webpackChunkName: "kms_new_center/audit_matching" */ '../views/finance/financial_auditing/audit_matching'),
        meta: { title: '稽核匹配' },
      },
      {
        path: 'unmatched_acceptance',
        name: 'unmatched_acceptance',
        component: () => import(/* webpackChunkName: "kms_new_center/unmatched_acceptance" */ '../views/finance/financial_auditing/unmatched_acceptance'),
        meta: { title: '未匹配验收单' },
      },
      {
        path: 'audit_and_verification',
        name: 'audit_and_verification',
        component: () => import(/* webpackChunkName: "kms_new_center/audit_and_verification" */ '../views/finance/financial_auditing/audit_and_verification'),
        meta: { title: '稽核核定' },
      },
      {
        path: 'audit_summary',
        name: 'audit_summary',
        component: () => import(/* webpackChunkName: "kms_new_center/audit_summary" */ '../views/finance/financial_auditing/audit_summary'),
        meta: { title: '稽核匹配汇总表' },
      },
      {
        path: 'document_capture_log',
        name: 'document_capture_log',
        component: () => import(/* webpackChunkName: "kms_new_center/document_capture_log" */ '../views/document_capture_log'),
        meta: { title: '单据抓取日志' },
      },
      {
        path: 'warehouse_store',
        name: 'warehouse_store',
        component: () => import(/* webpackChunkName: "kms_new_center/warehouse_store" */ '../views/warehouse_store'),
        meta: { title: '总仓门店' },
      },
      {
        path: 'supplier',
        name: 'supplier',
        component: () => import(/* webpackChunkName: "kms_new_center/supplier" */ '../views/supplier'),
        meta: { title: '供应商' },
      },
    ],
  },
];
