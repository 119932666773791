<template>
  <el-button v-if="name" @click="click" :size="size" :type="type" :icon="icon" :disabled="disabled" :circle="circle">{{name}}</el-button>
  <el-button v-else @click="click" :size="size" :type="type" :icon="icon" :disabled="disabled" :circle="circle"></el-button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    size: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: '',
    },
    onlyCode: {
      type: Number,
      default: 0,
    },
    circle: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    click() {
      this.$emit('click', this.onlyCode);
    },
  },
};
</script>

<style>

</style>
