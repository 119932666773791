var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name
    ? _c(
        "el-button",
        {
          attrs: {
            size: _vm.size,
            type: _vm.type,
            icon: _vm.icon,
            disabled: _vm.disabled,
            circle: _vm.circle,
          },
          on: { click: _vm.click },
        },
        [_vm._v(_vm._s(_vm.name))]
      )
    : _c("el-button", {
        attrs: {
          size: _vm.size,
          type: _vm.type,
          icon: _vm.icon,
          disabled: _vm.disabled,
          circle: _vm.circle,
        },
        on: { click: _vm.click },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }