var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      ref: "modalForm",
      attrs: { modalConfig: _vm.modalConfig },
      on: { onClose: _vm.closeModal },
    },
    [
      _c(_vm.formName, {
        tag: "component",
        attrs: { formConfig: _vm.formConfig },
        on: { onClose: _vm.closeModal, onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }