var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.props
        ? _c(
            "el-popover",
            {
              ref: "popover",
              attrs: {
                disabled: _vm.disabled,
                placement: "bottom-start",
                trigger: "click",
              },
              on: { show: _vm.onShowPopover, hide: _vm.onHidePopover },
            },
            [
              _c("el-tree", {
                ref: "tree",
                staticClass: "form-select-tree",
                style: "min-width: " + _vm.treeWidth + ";",
                attrs: {
                  data: _vm.data,
                  props: _vm.props,
                  "expand-on-click-node": false,
                  "filter-node-method": _vm.filterNode,
                  "default-expand-all": false,
                },
                on: { "node-click": _vm.onClickNode },
              }),
              _c(
                "el-input",
                {
                  ref: "input",
                  class: { rotate: _vm.showStatus },
                  style: "width: " + _vm.width + "px;",
                  attrs: {
                    slot: "reference",
                    size: _vm.size,
                    disabled: _vm.disabled,
                    clearable: _vm.clearable,
                    placeholder: _vm.placeholder,
                  },
                  on: {
                    focus: _vm.focus,
                    clear: _vm.clearValue,
                    input: _vm.treeSearch,
                  },
                  slot: "reference",
                  model: {
                    value: _vm.labelModel,
                    callback: function ($$v) {
                      _vm.labelModel = $$v
                    },
                    expression: "labelModel",
                  },
                },
                [
                  !_vm.labelModel
                    ? _c("i", {
                        staticClass: "el-icon-arrow-down",
                        attrs: { slot: "suffix" },
                        slot: "suffix",
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }