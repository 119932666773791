export default [
  {
    path: '/config_center',
    name: 'config_center',
    meta: {
      title: '系统设置',
    },
    redirect: '/config_center/menu_manage',
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'request_listening',
        name: 'config_request_listening',
        component: () => import(/* webpackChunkName: "config_center/request_listening" */ '../view/request_listening'),
        meta: { title: '接口日志' },
      },
      {
        path: 'menu_manage',
        name: 'config_menu_manage',
        component: () => import(/* webpackChunkName: "config_center/menu" */ '../view/menu'),
        meta: { title: '菜单管理' },
      },
      {
        path: 'login_page_manage',
        name: 'login_page_manage',
        component: () => import(/* webpackChunkName: "config_center/login_page" */ '../view/login_page'),
        meta: { title: '登录页配置' },
      },
      // {
      //   path: 'login_page_manage',
      //   name: 'login_page_manage',
      //   component: { render: (e) => e('router-view') },
      //   meta: { title: '登录页配置' },
      //   children: [
      //     {
      //       path: 'add',
      //       name: 'login_page_manage_add',
      //       component: () => import(/* webpackChunkName: "config_center/login_page/add" */ '../view/login_page/form'),
      //       meta: { title: '新增' },
      //     },
      //   ],
      // },
      {
        path: 'list_manage',
        name: 'config_list_manage',
        component: () => import(/* webpackChunkName: "config_center/list" */ '../view/list'),
        meta: { title: '列表管理' },
      },
      {
        path: 'icon_manage',
        name: 'config_icon_manage',
        component: () => import(/* webpackChunkName: "config_center/icon_manage" */ '../view/icon_manage'),
        meta: { title: '图标管理' },
      },
      {
        path: 'button_manage',
        name: 'config_button_manage',
        component: () => import(/* webpackChunkName: "config_center/button_manage" */ '../view/button_manage'),
        meta: { title: '按钮管理' },
      },
      {
        path: 'sort_form',
        name: 'sort_form',
        component: () => import(/* webpackChunkName: "config_center/sort_form" */ '../view/sort_form'),
        meta: { title: '拖拽配置' },
      },
      {
        path: 'login_log',
        name: 'login_log',
        component: () => import(/* webpackChunkName: "config_center/login_log" */ '../view/login_log'),
        meta: { title: '登录日志' },
      },
      {
        path: 'theme_picker',
        name: 'theme_picker',
        component: () => import(/* webpackChunkName: "config_center/theme" */ '../view/theme'),
        meta: { title: '主题设置' },
      },
      {
        path: 'version_manage',
        name: 'version_manage',
        component: () => import(/* webpackChunkName: "config_center/version" */ '../view/version'),
        meta: { title: 'APP版本管理' },
      },
      {
        path: 'approval_config',
        name: 'approvalConfig',
        component: () => import(/* webpackChunkName: "config_center/approval_config" */ '../view/approval_config'),
        meta: { title: '审批配置' },
      },
    ],
  },
];
