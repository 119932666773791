export default [
  {
    path: '/process_center',
    name: 'process',
    meta: {
      title: '流程中心',
    },
    redirect: '/process_center/manage',
    component: { render: (e) => e('router-view') },
    children: [
      {
        path: 'manage',
        name: 'manage',
        component: () => import(/* webpackChunkName: "process/manage" */ '../views/process_map'),
        meta: { title: '流程绘制' },
      },
      {
        path: 'monitor',
        name: 'monitor',
        component: () => import(/* webpackChunkName: "process/monitor" */ '../views/monitor'),
        meta: { title: '流程监听' },
      },
      {
        path: 'process_log',
        name: 'process_log',
        component: () => import(/* webpackChunkName: "process/process_log" */ '../views/process_log'),
        meta: { title: '流程日志报表' },
      },
      {
        path: 'automatic_approval',
        name: 'automatic_approval',
        component: () => import(/* webpackChunkName: "process/automatic_approval" */ '../views/automatic_approval'),
        meta: { title: '自动审批报表' },
      },
      {
        path: 'backlog',
        name: 'backlog',
        component: () => import(/* webpackChunkName: "process/backlog" */ '../views/backlog'),
        meta: { title: '待办事项' },
      },
      {
        path: 'process_intervent',
        name: 'process_intervent',
        component: () => import(/* webpackChunkName: "process/process_intervent" */ '../views/process_intervent'),
        meta: { title: '流程干预' },
      },
      {
        path: 'dealt',
        name: 'dealt',
        component: () => import(/* webpackChunkName: "process/dealt" */ '../views/dealt'),
        meta: { title: '已办事项' },
      },
      {
        path: 'sended',
        name: 'sended',
        component: () => import(/* webpackChunkName: "process/sended" */ '../views/sended'),
        meta: { title: '已发事项' },
      },
      {
        path: 'process_instance',
        name: 'process_instance',
        component: () => import(/* webpackChunkName: "process/instance" */ '../views/instance'),
        meta: { title: '流程实例' },
      },
      {
        path: 'btns',
        name: 'btns',
        component: () => import(/* webpackChunkName: "process/btns" */ '../views/buttons'),
        meta: { title: '按钮权限' },
      },
      {
        path: 'copy_list',
        name: 'copy_list',
        component: () => import(/* webpackChunkName: "process/btns" */ '../views/copy_list'),
        meta: { title: '抄送列表' },
      },
    ],
  },
];
