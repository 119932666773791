import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  Drawer,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Link,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Loading,
  MessageBox,
  Message,
  Notification,
  Scrollbar,
  Divider,
  Popconfirm,
  Image,
  Timeline,
  TimelineItem,
  Skeleton,
  SkeletonItem,
} from 'element-ui';

Vue.prototype.$ELEMENT = { size: 'small' };
Link.props.underline = false;
Vue.use(Pagination);
Vue.use(Dialog, { zIndex: 2003, appendToBody: true });
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(Drawer, { zIndex: 2003, appendToBody: true });
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select, { 'popper-append-to-body': false });
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Link);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Scrollbar);
Vue.use(Divider);
Vue.use(Popconfirm);
Vue.use(Image);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Skeleton);
Vue.use(SkeletonItem);

Vue.use(Loading.directive);

const resetMessage = (options) => {
  if (typeof (options) === 'string') {
    return Message({
      showClose: true,
      duration: 2000,
      type: 'info',
      message: options,
    });
  }
  return Message({ ...options, showClose: true });
};
['error', 'success', 'info', 'warning'].forEach((item) => {
  resetMessage[item] = (msg) => Message({
    dangerouslyUseHTMLString: true,
    showClose: true,
    duration: 2000,
    type: item,
    message: msg,
  });
});
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = resetMessage;
