<template>
  <div style="height:800px">
    <vxe-table row-key
               row-id='id'
               ref="xTable1"
               height="800px"
               :data="tableData">
      <vxe-table-column v-if="beforObjIsShow==='Y'" label="更新之前的对象"
                        class-name="cell_stayle">
        <template v-slot="{row}">
          <json-viewer :value="row.oldObject || {}"
                       :expand-depth=5
                       copyable
                       boxed
                       sort></json-viewer>
        </template>
      </vxe-table-column>
      <vxe-table-column v-if="afterObjIsShow==='Y'" label="更新之后的对象"
                        class-name="cell_stayle">
        <template v-slot="{row}">
          <json-viewer :value="row.newObject || {}"
                       :expand-depth=5
                       copyable
                       boxed
                       sort></json-viewer>
        </template>
      </vxe-table-column>
      <vxe-table-column v-if="contrastObjIsShow==='Y'" label="对比的结果"
                        class-name="cell_stayle">
        <template v-slot="{row}">
          <json-viewer :value="row.compareResult || {}"
                       :expand-depth=5
                       copyable
                       boxed
                       sort></json-viewer>
        </template>
      </vxe-table-column>
      <vxe-table-column label=""
                        width="0">
      </vxe-table-column>
    </vxe-table>

  </div>
</template>

<script>
import JsonViewer from 'vue-json-viewer';
import request from '../../../utils/request';

export default {
  name: 'LogDetail',
  props: {
    logDetail: Object,
  },
  components: {
    JsonViewer,
  },
  data() {
    return {
      tableData: [],
      afterObjIsShow: 'Y',
      beforObjIsShow: 'Y',
      contrastObjIsShow: 'Y',
    };
  },
  created() {
    // this.jsonData = JSON.parse(this.logDetail.oldObject);
    this.tableData.push({
      newObject: JSON.parse(this.logDetail.newObject),
      oldObject: JSON.parse(this.logDetail.oldObject),
      compareResult: JSON.parse(this.logDetail.compareResult),
    });
    this.getAfterIsShow();
    this.getBeforIsShow();
    this.getContrastIsShow();
  },
  methods: {
    // 获取日志更后前的全局变量
    getAfterIsShow() {
      request.get('/mdm/mdmParameterController/getParameterValue', { parameterCode: 'log_after_obj_is_show' }).then((res) => {
        if (res.success) {
          this.afterObjIsShow = res.result;
        }
      });
    },
    // 获取日志更新前的全局变量
    getBeforIsShow() {
      request.get('/mdm/mdmParameterController/getParameterValue', { parameterCode: 'log_befor_obj_is_show' }).then((res) => {
        if (res.success) {
          this.beforObjIsShow = res.result;
        }
      });
    },
    // 获取日志更新前后对比
    getContrastIsShow() {
      request.get('/mdm/mdmParameterController/getParameterValue', { parameterCode: 'log_contrast_obj_is_show' }).then((res) => {
        if (res.success) {
          this.contrastObjIsShow = res.result;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.jv-container .jv-code.open{
  max-height: 700px !important;
  overflow: auto;
}
/deep/.cell_stayle {
  vertical-align:top;
}
</style>
