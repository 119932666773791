<!--
 * @Author: chenlei
 * @Date: 2020-12-08 19:21:21
 * @LastEditors: chenlei
 * @LastEditTime: 2020-12-21 16:42:27
 * @Description:
-->
<template>
  <div>
    <vxe-toolbar>
      <template v-slot:buttons>
        <el-upload
            class="upload-table"
            v-if="!disabled"
            action="/upload/uploadController/uploadOssList"
            :headers="{
                    loginUserToken: Storage.l.get('TokenKey')
                }"
            multiple
            :show-file-list="false"
            :on-exceed="handleExceed"
            :before-upload="beforeUpload"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="handleSuccess"
            :limit="limit"
            :accept="accept || ''"
            :file-list="fileList"
        >
          <vxe-button>上传附件</vxe-button>
        </el-upload>
      </template>
    </vxe-toolbar>
    <vxe-table
        ref="uploadTable"
        :data="tableList"
    >
      <vxe-table-column
          field="fileName"
          title="附件名称"
      >
      </vxe-table-column>
      <vxe-table-column title="操作" width="200" show-overflow>
        <template v-slot="{ row }">
          <!-- <a @click="downLoad(row)" href="javascript:;">下载</a> -->
          <el-button  type="text" size="mini" @click="downLoad(row)">下载</el-button>
          &nbsp;&nbsp;
          <!-- <a href="javascript:;" @click="handleRemove(row)" v-if="!disabled">删除</a> -->
          <el-button  type="text" size="mini" v-if="!disabled" @click="handleRemove(row)">删除</el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import Storage from '../../utils/storage';

export default {
  name: 'UploadModal',
  props: {
    disabled: Boolean,
    limit: Number,
    accept: String,
    value: Object,
  },
  watch: {
    value(n) {
      this.tableList = n.fileList;
    },
  },
  data() {
    return {
      Storage,
      modalConfig: {
        title: '选择',
      },
      fileList: [],
      tableList: [],
      isUpload: false,
    };
  },
  created() {
  },
  methods: {
    downLoad(row) {
      const file = new XMLHttpRequest();
      file.open('GET', row.url, true);
      file.responseType = 'blob';
      file.onload = () => {
        const content = file.response;
        const link = document.createElement('a');
        link.download = row.fileName;
        link.style.display = 'none';
        const blob = new Blob([content]);
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      };
      file.send();
    },
    setTableList(n) {
      this.tableList = n;
    },
    beforeUpload(files) {
      const { name } = files;
      let fileType = name.split('.')[name.split('.').length - 1];
      fileType = fileType.toLowerCase();
      if (this.accept && this.accept.indexOf(fileType) === -1) {
        this.isUpload = true;
        this.$message.warning(
          `当前限制选择 ${
            this.accept
          } 类型件，本次选择了错误文件`,
        );
        return false;
      }
      return true;
    },
    handleRemove(file) {
      this.tableList.forEach((v, k) => {
        if (v.url === file.url) {
          this.tableList.splice(k, 1);
        }
      });
      this.tableList = this.tableList.concat([]);
      this.value.fileList = this.tableList;
      this.$emit('onGetFileList', this.tableList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleSuccess(res) {
      this.isUpload = false;
      if (res.code === 200 && res.success) {
        res.result.forEach((v) => {
          this.tableList.push({
            fileName: v.fileName,
            url: v.url,
            urlPath: v.urlPath,
            urlPathPrefix: v.urlPathPrefix,
            objectName: v.objectName,
          });
          this.tableList = this.tableList.concat([]);
          this.$emit('onGetFileList', this.tableList);
        });
      }
    },
    handleExceed(files, fileList) {
      console.log(files, fileList);
    },
    beforeRemove(file) {
      if (!this.isUpload) {
        return this.$confirm(`确定移除 ${file.name}？`);
      }
      this.isUpload = false;

      return true;
    },
  },
};
</script>
