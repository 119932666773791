<template>
  <a-drawer
    :title="modalConfig.title"
    :visible="modalConfig.visible"
    :width="modalConfig.width || '80%'"
    :destroyOnClose="true"
    :footer="null"
    :maskClosable="false"
    @close="close"
    :bodyStyle="{padding: '0', height: '94vh',}"
  >
    <slot></slot>
  </a-drawer>
</template>

<script>
export default {
  props: {
    modalConfig: {
      type: Object,
      default: () => ({
        title: '标题',
        visible: false,
      }),
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    // modal弹窗确定事件
    onOk() {
      this.$emit('onOk');
    },
  },
};
</script>

<style lang="less" scoped></style>
