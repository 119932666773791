<script>
// 提交审批 -yc 2020-12-15 16:25
// formConfig  rowData  所选行数据对象
// formConfig  submitApproveConfigObj  流程提交配置对象 ， key用于查询详情回填  submitApproveApiUrl 提交流程的接口地址
// 示例
// if (val.code === 'submit_approve') {
//   // 提交审批测试
//   console.log(344444444, row);
//   this.formConfig = {
//     rowData: row,
//     submitApproveConfigObj: {
//       key: '669979',
//       submitApproveApiUrl: '/active/asdasjdjiyaid/asdhsah',
//     },
//
//   };
//   this.modalConfig.title = val.name;
//   this.formName = 'submitApprove'; // 已经在/components/table_page页面注册过了
//   this.openModal();
// }

import Form, { formCreate } from '../form';
import UpdateTable from '../upload_modal/upload_table.vue';
import request from '../../utils/request';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

formCreate.component('UpdateTable', UpdateTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'fullName',
          title: '申请人',
          value: userInfo && userInfo.fullName,
          props: {
            disabled: true,
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'orgName',
          title: '部门',
          value: '',
          props: {
            // disabled: true,
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'processCode',
          title: '流程编号',
          props: {
            placeholder: '请输入流程编号',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入流程名称',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'title',
          title: '主题',
          value: '',
          props: {
            placeholder: '请输入主题',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请输入主题',
            },
            {
              min: 2,
              max: 50,
              message: '类型名称范围2~50位字符,且不为空',
              trigger: 'blur',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          // type: 'textarea',
          type: 'input',
          textarea: 5,
          autosize: true,
          field: 'desc',
          title: '详情',
          value: '',
          props: {
            placeholder: '请输入描述',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 24 },
          },
        },
        {
          type: 'UpdateTable',
          field: 'upload',
          title: '附件',
          value: {
            fileList: [],
          },
          props: {},
          col: {
            md: { span: 24 },
          },
        },
      ],
    };
  },

  created() {
    // console.log(13666666, this.formConfig);

    if (this.formConfig && this.formConfig.submitApproveConfigObj && this.formConfig.submitApproveConfigObj.key) {
      this.getSubmitActiviti(this.formConfig.submitApproveConfigObj.key);
    }
  },

  mounted() {
  },
  methods: {

    // 提交流程获取信息

    getSubmitActiviti(key = this.formConfig.submitApproveConfigObj.key) {
      request.get('/activiti/taActBaseController/getSubmitActiviti', { key }).then((res) => {
        if (res.success) {
          let workFlowNameRuleObj = {};
          // 只有一个 就取
          if (res.result.details && res.result.details.length === 1) {
            workFlowNameRuleObj = {
              type: 'select',
              field: 'processCode',
              title: '流程名称',
              // value: this.formConfig && this.formConfig.workFlowName,
              value: res.result.details[0].processKey,
              props: {
                placeholder: '请输入流程名称',
                disabled: false,
                // clearable: true, // 是否显示清空按钮
              },
              options: (res.result.details || []).map((a) => ({ label: a.processName, value: a.processKey })),
              validate: [
                {
                  trigger: 'change',
                  required: true,
                  message: '请输入流程名称',
                },
              ],
              col: {
                md: { span: 12 },
              },
            };
          }
          // 有多个让其选
          if (res.result.details && res.result.details.length > 1) {
            workFlowNameRuleObj = {
              type: 'select',
              field: 'processCode',
              title: '流程名称',
              props: {
                placeholder: '请输入流程名称',
                disabled: false,
                // clearable: true, // 是否显示清空按钮
              },
              options: (res.result.details || []).map((a) => ({ label: a.processName, value: a.processKey })),
              validate: [
                {
                  trigger: 'change',
                  required: true,
                  message: '请输入流程名称',
                },
              ],
              col: {
                md: { span: 12 },
              },
            };
          }

          // 没有的话就输入
          if (!res.result.details) {
            workFlowNameRuleObj = {
              type: 'input',
              field: 'processCode',
              title: '流程编码',
              props: {
                placeholder: '请输入流程编码',
                disabled: false,
                clearable: true, // 是否显示清空按钮
              },
              validate: [
                {
                  trigger: 'change',
                  required: true,
                  message: '请输入流程名称',
                },
              ],
              col: {
                md: { span: 12 },
              },
            };
          }

          this.rule = [
            {
              type: 'input',
              field: 'fullName',
              title: '申请人',
              value: userInfo && userInfo.fullName,
              props: {
                disabled: true,
              },
              col: {
                md: { span: 12 },
              },
            },
            {
              type: 'input',
              field: 'orgName',
              title: '部门',
              // value: this.formConfig && this.formConfig.fullName,
              value: res.result.orgName,
              props: {
                // disabled: true,
              },
              col: {
                md: { span: 12 },
              },
            },
            workFlowNameRuleObj,
            {
              type: 'input',
              field: 'title',
              title: '主题',
              value: res.result.title || '',
              props: {
                placeholder: '请输入主题',
                disabled: false,
                clearable: true, // 是否显示清空按钮
              },
              validate: [
                {
                  trigger: 'change',
                  required: true,
                  message: '请输入主题',
                },
                {
                  min: 2,
                  max: 50,
                  message: '类型名称范围2~50位字符,且不为空',
                  trigger: 'blur',
                },
              ],
              col: {
                md: { span: 12 },
              },
            },
            {
              type: 'input',
              textarea: 5,
              autosize: true,
              field: 'desc',
              title: '详情',
              value: this.formConfig && this.formConfig.desc,
              props: {
                placeholder: '请输入详情',
                disabled: false,
                clearable: true, // 是否显示清空按钮
              },
              col: {
                md: { span: 24 },
              },
            },
            {
              type: 'UpdateTable',
              field: 'upload',
              title: '附件',
              value: {
                fileList: [],
              },
              props: {},
              col: {
                md: { span: 24 },
              },
            },
          ];
        }
      });
    },
    // 提交
    submit() {
      if (!(this.formConfig.submitApproveConfigObj && this.formConfig.submitApproveConfigObj.submitApproveApiUrl)) {
        console.error('请配置提交接口地址，submitApproveApiUrl');
        return;
      }
      const formData = this.getFormData();
      const { rowData } = this.formConfig;
      if (formData) {
        const url = this.formConfig.submitApproveConfigObj.submitApproveApiUrl || '';
        const { upload: { fileList } } = formData;
        // 附件处理
        if (fileList && fileList.length) {
          formData.activityFileList = [];
          fileList.forEach((item) => {
            formData.activityFileList.push({
              urlAddress: item.url,
              objectName: item.fileName,
            });
          });
        }

        delete formData.upload;
        const params = { ...rowData, saveType: '5', ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
